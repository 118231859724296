import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { RootState } from '../root';

export interface DialogState {
  visible: boolean;
  title: string;
  MuiSvgIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  MuiSvgIconStyle?: any;
  description: string;
  secondaryBtnName?: string;
  primaryBtnName: string;
  onSecondaryBtnClick?: () => void;
  onPrimaryBtnClick?: () => void;
  hideCloseBtn?: boolean;
  onClose?: () => void;
  disablePrimaryBtnAutoClose?: boolean;
}

const initialState: DialogState = {
  visible: false,
  title: '',
  MuiSvgIcon: undefined,
  description: '',
  primaryBtnName: '',
  onClose: undefined
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    openDialog: (state, action: PayloadAction<Omit<DialogState, 'visible'>>) => {
      return { ...state, ...action.payload, visible: true };
    },
    closeDialog: state => {
      return { ...state, visible: false };
    }
  }
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectDialogVisible = (state: RootState): boolean => state.dialog.visible;
export const selectDialogTitle = (state: RootState): string => state.dialog.title;
export const selectDialogMuiSvgIcon = (
  state: RootState
): OverridableComponent<SvgIconTypeMap<any, 'svg'>> | undefined => state.dialog.MuiSvgIcon;
export const selectDialogMuiSvgIconStyle = (state: RootState): any => state.dialog.MuiSvgIconStyle;
export const selectDialogDescription = (state: RootState): string => state.dialog.description;
export const selectDialogSecondaryBtnName = (state: RootState): string | undefined => state.dialog.secondaryBtnName;
export const selectDialogPrimaryBtnName = (state: RootState): string => state.dialog.primaryBtnName;
export const selectDialogOnSecondaryBtnClick = (state: RootState): (() => void) | undefined =>
  state.dialog.onSecondaryBtnClick;
export const selectDialogOnPrimaryBtnClick = (state: RootState): (() => void) | undefined =>
  state.dialog.onPrimaryBtnClick;
export const selectDialogHideCloseBtn = (state: RootState): boolean | undefined => state.dialog.hideCloseBtn;
export const selectDialogDisablePrimaryBtnAutoClose = (state: RootState): boolean | undefined =>
  state.dialog.disablePrimaryBtnAutoClose;
export default dialogSlice.reducer;
