import React, { useCallback, useMemo } from 'react';
import { Header } from '@provider-components';
import { setSidePanelCollapsed, setSidePanelContent } from '@provider-reducers/esitterSlice';
import { SidePanelContent } from '../../SidePanel';
import { useDispatch } from 'react-redux';
import { makeThemedStyles } from '@provider-hooks';
import Box from '@mui/material/Box';

interface SidePanelContainerProps {
  children: React.ReactElement;
  sidePanelCollapsed: boolean;
  title: string;
}
export const SidePanelContainer = React.memo((props: SidePanelContainerProps): React.ReactElement | null => {
  const { title, children, sidePanelCollapsed } = props;
  const dispatch = useDispatch();

  const handleArrowBackClick = useCallback(() => {
    dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
  }, [dispatch]);

  const handleCloseClick = useCallback(() => {
    dispatch(setSidePanelCollapsed(true));

    dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
  }, [dispatch]);

  const { styles } = useStyles();

  //TODO: refactor this later to use sx instead of styles in Header
  const headerStyle = useMemo(() => ({ padding: '8px 8px 8px 16px', fontSize: '20px' }), []);

  if (sidePanelCollapsed) {
    return null;
  }
  return (
    <Box sx={styles.container}>
      <Header
        style={headerStyle}
        title={title}
        backgroundColor={'#232A30'}
        borderColor={'#141414'}
        onArrowBackClick={handleArrowBackClick}
        onCloseClick={handleCloseClick}
      />
      {children}
    </Box>
  );
});

const useStyles = makeThemedStyles()(() => ({
  container: {
    width: '240px',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #000000',
    maxHeight: '100vh'
  }
}));
