import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@provider-reducers/root';
import { SettingsInitialState } from '@provider-types/reducer';

export const selectSettings = (state: RootState): SettingsInitialState => state.settings;
export const selectSelectedAudioInputId = createSelector([selectSettings], settings => settings.selectedAudioInputId);
export const selectSelectedVideoInputId = createSelector([selectSettings], settings => settings.selectedVideoInputId);
export const selectAudioInputId = createSelector([selectSettings], settings => settings.audioInputId);
export const selectAudioOutputId = createSelector([selectSettings], settings => settings.audioOutputId);
export const selectVideoInputId = createSelector([selectSettings], settings => settings.videoInputId);
export const selectAudioInputInitialized = createSelector([selectSettings], settings => settings.audioInputInitialized);
export const selectAudioOutputInitialized = createSelector(
  [selectSettings],
  settings => settings.audioOutputInitialized
);
export const selectVideoInputInitialized = createSelector([selectSettings], settings => settings.videoInputInitialized);
