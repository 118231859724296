import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
// Images
import Box from '@mui/material/Box';
import { SmartRailsPanel } from './SmartRailsPanel/SmartRailsPanel';
import { makeThemedStyles } from '@provider-hooks';
import { Tags } from './Tags/Tags';
import { Notes } from './Notes/Notes';
import { InterventionModePanel } from './InterventionModePanel/InterventionModePanel';
import Stack from '@mui/material/Stack';
import { getSelectedDeviceId, selectIsDeviceInInteractiveMode } from '@provider-selectors/esitter';
import { PatientInfoPanel } from './PatientInfoPanel/PatientInfoPanel';
import { PatientDetailsNavigation } from './PatientDetailsNavigation/PatientDetailsNavigation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ScrollableContainer } from './ScrollableContainer/ScrollableContainer';
import { AdjustCameraPanel } from './AdjustCameraPanel/AdjustCameraPanel';
import Divider from '@mui/material/Divider';
import { AccordionContainer } from './AccordionContainer/AccordionContainer';
import { useTranslation } from 'react-i18next';
import LogsTab from './LogsTab/LogsTab';

export const PatientDetailsContent = React.memo((): React.ReactElement => {
  const [tabValue, setTabValue] = useState('overview');
  const { t } = useTranslation();

  const { styles } = useStyles();

  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isInteractiveMode = useSelector(state => selectIsDeviceInInteractiveMode(state, selectedDeviceId));

  const handleTabChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  }, []);

  return (
    <>
      <Box sx={{ pt: 1, px: 2 }}>
        <PatientDetailsNavigation />
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ px: 3 }}>
          <TabList onChange={handleTabChange}>
            <Tab sx={styles.tab} label='Overview' value='overview' />
            <Tab sx={styles.tab} label='Notes' value='details' />
            <Tab sx={styles.tab} label={t('global.logs')} value='logs' />
          </TabList>
        </Box>
        <TabPanel value='overview' sx={tabValue === 'overview' ? styles.overviewTab : styles.hidden}>
          <ScrollableContainer
            mainContainer={
              <Stack spacing={4} sx={{ mt: 4, mb: 4, px: 4 }}>
                <PatientInfoPanel />
                {!isInteractiveMode && (
                  <>
                    <Divider />
                    <SmartRailsPanel />
                    <Divider />
                    <AdjustCameraPanel data-testid='adjustCameraPanel' />
                  </>
                )}

                <AccordionContainer deviceId={selectedDeviceId} />

                {isInteractiveMode && <InterventionModePanel />}
              </Stack>
            }
            bottomContainer={!isInteractiveMode && <InterventionModePanel />}
          />
        </TabPanel>
        <TabPanel value='details'>
          <Stack spacing={6}>
            <Tags />
            <Notes />
          </Stack>
        </TabPanel>
        <TabPanel value='logs' sx={tabValue === 'logs' ? styles.overviewTab : styles.hidden}>
          <ScrollableContainer mainContainer={<LogsTab />} bottomContainer={null} />
        </TabPanel>
      </TabContext>
    </>
  );
});

const useStyles = makeThemedStyles()(() => ({
  hidden: {
    display: 'none'
  },
  overviewTab: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    p: 0,
    height: '100vh'
  },
  tab: {
    minWidth: '48px',
    px: '14px'
  }
}));
