import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import { TextButton } from '@provider-components';
import React, { useCallback } from 'react';
import { setIsAudioControlActive, setIsCameraControlActive } from '@provider-reducers/esitterSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSelectedDevice,
  getSelectedDeviceId,
  selectIsAudioControlActive,
  selectIsCameraControlActive,
  selectIsDeviceInInteractiveMode,
  selectSelectedDeviceInPrivacyMode,
  selectSelectedDevicePaused
} from '@provider-selectors/esitter';
import { useFocusedRoom } from '@provider-features/esitter/hooks';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material';

type Props = {
  labelSx?: SxProps<Theme>;
};

export const AdjustCameraButton = ({ labelSx }: Props): React.ReactElement => {
  const { focusedRoom, onFocusClick } = useFocusedRoom();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const isAudioControlActive = useSelector(selectIsAudioControlActive);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isCameraControlActive = useSelector(selectIsCameraControlActive);
  const selectedDevice = useSelector(getSelectedDevice);
  const selectedPaused = useSelector(selectSelectedDevicePaused);
  const selectedDevicePrivacyMode = useSelector(selectSelectedDeviceInPrivacyMode);
  const isInteractiveMode = useSelector(state => selectIsDeviceInInteractiveMode(state, selectedDeviceId));
  const onAdjustCameraButton = useCallback(() => {
    console.log('onAdjustCameraButton: ', focusedRoom, selectedDevice, isCameraControlActive);
    if (focusedRoom !== selectedDeviceId) {
      onFocusClick(selectedDeviceId || '');
    }
    if (isAudioControlActive) {
      dispatch(setIsAudioControlActive(false));
    }
    if (isCameraControlActive === selectedDeviceId) {
      dispatch(setIsCameraControlActive(false));
    } else {
      dispatch(setIsCameraControlActive(selectedDeviceId));
    }
  }, [
    dispatch,
    focusedRoom,
    isAudioControlActive,
    isCameraControlActive,
    onFocusClick,
    selectedDevice,
    selectedDeviceId
  ]);
  return (
    <TextButton
      data-testid='adjustCameraButton'
      color={isCameraControlActive ? 'primary' : isInteractiveMode ? 'secondary' : 'primary'}
      fullWidth
      title={t('esitter.components.SidePanel.AdjustCameraButton')}
      disabled={selectedDevicePrivacyMode || selectedPaused}
      icon={ControlCameraIcon}
      onClick={onAdjustCameraButton}
      labelSx={labelSx}
    />
  );
};
