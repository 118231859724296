import React, { forwardRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useFocusedRoom } from '../../../../hooks';
import { IDeviceRoomInfo } from '@provider-types/provider';
import { setSidePanelContent, setSelectedDevice, resetSelectedDevice } from '@provider-reducers/esitterSlice';
import { SidePanelContent } from '../../../SidePanel/SidePanel';

import { DeviceMenu } from './DeviceMenu';

interface DeviceMenuWrapperProps {
  onRemoveClick: (device: IDeviceRoomInfo) => void;
}

export const DeviceMenuWrapper = forwardRef<DeviceMenu, DeviceMenuWrapperProps>(({ onRemoveClick }, ref) => {
  const { focusedRoom, onFocusClick } = useFocusedRoom();
  const dispatch = useDispatch();

  const handleFocusClick = useCallback(
    (device?: IDeviceRoomInfo) => {
      dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
      dispatch(setSelectedDevice(device));

      if (device) {
        onFocusClick(device.deviceId);
      }
    },
    [dispatch, onFocusClick]
  );

  const handleUnfocusClick = useCallback(
    (device: IDeviceRoomInfo) => {
      dispatch(resetSelectedDevice());

      onFocusClick(device.deviceId);
    },
    [dispatch, onFocusClick]
  );

  return (
    <DeviceMenu
      ref={ref}
      focusedRoom={focusedRoom}
      onFocusClick={handleFocusClick}
      onUnfocusClick={handleUnfocusClick}
      onRemoveClick={onRemoveClick}
    />
  );
});
