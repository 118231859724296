import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@provider-reducers/root';
import { MediaPermissionsInitialState } from '@provider-types/reducer';

export const selectMediaPermissions = (state: RootState): MediaPermissionsInitialState => state.mediapermissions;
export const selectIsMicrophoneOk = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.isMicrophoneOk
);

export const selectShouldCheckMic = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.shouldCheckMic
);
export const selectShouldCheckCamera = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.shouldCheckCamera
);
export const selectCheckAttemptCount = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.checkAttemptCount
);
export const selectInitialCamCheckComplete = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.initialCamCheckComplete
);
export const selectInitialMicCheckComplete = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.initialMicCheckComplete
);
export const selectCheckInProgress = createSelector(
  [selectMediaPermissions],
  mediapermissions => mediapermissions.checkInProgress
);
