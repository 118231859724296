import WebSocket from 'isomorphic-ws';
import { Action, WebsocketsInitialState } from '@provider-types/reducer';
import WebSocketProxy from '@provider-services/websocket_proxy/WebSocketProxy';

export const initialState: WebsocketsInitialState = {
  shouldInitializeWs: false,
  wsUuid: undefined,
  wsInstanceCount: 0,
  reinitTimerId: undefined,
  retryCount: 2,
  wsReady: false
};

const websocketsReducer = (state = initialState, action: Action<any>): WebsocketsInitialState => {
  let globalWs: WebSocket | undefined = undefined;
  if (state.wsUuid) {
    globalWs = (window as any)[state.wsUuid];
  }

  switch (action.type) {
    case 'SHOULD_INIT_WS': {
      return {
        ...state,
        shouldInitializeWs: action.payload
      };
    }
    case 'INIT_WS': {
      return {
        ...state,
        wsUuid: action.payload.wsUuid,
        reinitTimerId: undefined,
        wsInstanceCount: state.wsInstanceCount + 1
      };
    }
    case 'REINIT_WS': {
      if (state.reinitTimerId) {
        // clear retry timer if set, we are initializing now
        clearInterval(state.reinitTimerId);
      }

      if (!state || !state.wsUuid || !globalWs) {
        console.warn('`REINIT_WS` dispatch called when WS was not initialized. Ignoring');
        return { ...state, reinitTimerId: undefined };
      }

      if (!(globalWs?.readyState === 2 || globalWs?.readyState === 3)) {
        // always ensure the existing instance is closed first if it is not already closing/closed ('opening' state)
        console.log('Closing websocket before instantiating new one');
        // XXX need setTimeout to avoid the websocket closure causing a
        // rerender that might cause a dispatch before this returns
        setTimeout(() => {
          globalWs?.close(1000, 'internal reset');
        }, 250);
      }
      if (globalWs) {
        // remove all handlers from the previous websocket instance before creating a new one
        globalWs.onmessage = null;
        globalWs.onerror = null;
        globalWs.onclose = null;
        globalWs.onopen = null;
      }
      (window as any)[state.wsUuid] = new WebSocketProxy({
        url: action.payload.uri as string,
        mockUrl: action.payload.mock_uri as string
      });
      // reduce retry count by 1 - it will be reset if connection succeeds
      return {
        ...state,
        reinitTimerId: undefined,
        wsInstanceCount: state.wsInstanceCount + 1,
        retryCount: state.retryCount - 1
      };
    }
    case 'SET_WS_READY': {
      return { ...state, wsReady: true };
    }
    case 'SET_WS_NOT_READY': {
      return { ...state, wsReady: false };
    }
    case 'SET_WS_CLOSE': {
      // close if websocket is currently open
      if (globalWs?.readyState === 1) {
        globalWs?.close();
      }
      return { ...state };
    }
    case 'WS_RESET_HEARTBEAT_TIMER_ID': {
      if (state.heartbeatTimerId) {
        clearTimeout(state.heartbeatTimerId);
      }
      if (action.payload?.failure) {
        console.log('Open or opening websocket failed due to missing heartbeats - no recovery possible');
        return { ...state, retryCount: 0, heartbeatTimerId: action.payload.heartbeatTimerId };
      }
      return { ...state, heartbeatTimerId: action.payload.heartbeatTimerId };
    }
    case 'WS_RESET_RETRY_COUNT': {
      return { ...state, retryCount: initialState.retryCount };
    }
    case 'WS_SET_REINIT_TIMER_ID': {
      if (state.reinitTimerId) {
        // clear existing before setting a new timer ID
        clearInterval(state.reinitTimerId);
      }
      return { ...state, reinitTimerId: action.payload };
    }
    case 'CLEAR_WEBSOCKETS': {
      console.log('Clearing global websocket');
      if (state.reinitTimerId) {
        clearInterval(state.reinitTimerId);
      }
      if (state.heartbeatTimerId) {
        clearTimeout(state.heartbeatTimerId);
      }
      if (globalWs?.readyState === 0 || globalWs?.readyState === 1) {
        // XXX need setTimeout to avoid the websocket closure causing a rerender
        // that might cause a dispatch before this returns
        setTimeout(() => {
          globalWs?.close(1000, 'internal reset');
        }, 250);
      }
      if (state.wsUuid) {
        delete (window as any)[state.wsUuid];
      }
      return { ...initialState };
    }
    default:
      return { ...state };
  }
};

export default websocketsReducer;
