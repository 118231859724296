import React, { useState, useCallback } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

type OverflowTipProps = TooltipProps;

export const OverflowTip: React.FC<OverflowTipProps> = props => {
  const [hoverStatus, setHoverStatus] = useState(false);
  const childElement = React.Children.only(props.children);

  const { ref: textElementRef } = useResizeDetector({
    onResize: () => {
      compareSize();
    },
    refreshMode: 'debounce',
    refreshRate: 0
  });
  const child = React.cloneElement(childElement, { ref: textElementRef });

  const compareSize = useCallback(() => {
    const isOverflowing = Boolean(
      textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth ||
        textElementRef?.current?.scrollHeight > textElementRef?.current?.clientHeight
    );
    setHoverStatus(isOverflowing);
  }, [textElementRef]);

  return (
    <Tooltip disableHoverListener={!hoverStatus} {...props} title={props.title}>
      {child}
    </Tooltip>
  );
};
