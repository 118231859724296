import { useEffect, useState } from 'react';

export const useZoomIndicator = (): { currentZoomValue: number } => {
  const [currentZoomValue, setCurrentZoomValue] = useState(100);

  useEffect(() => {
    const checkZoom = () => {
      setCurrentZoomValue(Math.round((window.outerWidth / window.innerWidth) * 100));
    };

    window.addEventListener('resize', checkZoom);

    return () => {
      window.removeEventListener('resize', checkZoom);
    };
  }, []);

  return { currentZoomValue };
};
