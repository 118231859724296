import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface SaveButtonProps {
  disabled: boolean;
  loading: boolean;
  loaded: boolean;
  onClick: () => void;
}

export const SaveButton = React.memo((props: SaveButtonProps): React.ReactElement => {
  const { loaded, loading, onClick, disabled } = props;
  return (
    <LoadingButton
      onClick={onClick}
      startIcon={loaded ? <CheckCircleIcon /> : undefined}
      fullWidth
      variant={'contained'}
      loading={loading}
      loadingPosition='start'
      disabled={disabled}
    >
      {loading ? 'Saving' : loaded ? 'Saved' : 'Save'}
    </LoadingButton>
  );
});
