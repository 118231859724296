import { useCallback, useEffect, useRef } from 'react';
import { useCommonWSMessages } from '../useCommonWsMessages/useCommonWsMessages';

interface UseAudioCaptioning {
  startCaptioningAudio: (mediaStreamSourceTrack: MediaStreamTrack) => void;
  stopCaptioningAudio: () => void;
}

export const useAudioCaptioning = (callId: string): UseAudioCaptioning => {
  const { sendPcmAudioToTranscribe } = useCommonWSMessages(callId);

  const audioContextRef = useRef<AudioContext | null>(null);
  const processorNodeRef = useRef<AudioWorkletNode | null>(null);
  const isTranscriptionActiveRef = useRef(false);
  const mediaStreamTrackRef = useRef<MediaStreamTrack | undefined>(undefined);

  const startCaptioningAudio = useCallback(
    async (mediaStreamSourceTrack: MediaStreamTrack) => {
      if (!isTranscriptionActiveRef.current) {
        isTranscriptionActiveRef.current = true;
        mediaStreamTrackRef.current = mediaStreamSourceTrack.clone();

        const audioContext = new AudioContext({ sampleRate: 16000 });
        const mediaStream = new MediaStream([mediaStreamTrackRef.current]);
        const sourceNode = audioContext.createMediaStreamSource(mediaStream);

        try {
          await audioContext.audioWorklet.addModule('/captionAudioWorklet.js');

          const processorNode = new AudioWorkletNode(audioContext, 'CaptionsPcmProcessor', {
            parameterData: { chunkSize: 4096 }
          });

          processorNode.port.onmessage = (event: MessageEvent) => {
            if (event.data.error) {
              console.log(`Error processing PCM audio: ${event.data.error}`);
            } else {
              sendPcmAudioToTranscribe(event.data.chunk);
            }
          };

          sourceNode.connect(processorNode);
          processorNode.connect(audioContext.destination);
          processorNodeRef.current = processorNode;
          audioContextRef.current = audioContext;

          audioContextRef.current.resume().then(() => {
            console.log('AudioContext for captioning resumed');
          });
        } catch (error) {
          console.log(`Error setting up audio worklet: ${error}`);
          return;
        }
      }
    },
    [sendPcmAudioToTranscribe]
  );

  const stopCaptioningAudio = useCallback(() => {
    if (isTranscriptionActiveRef.current && audioContextRef.current && processorNodeRef.current) {
      isTranscriptionActiveRef.current = false;
      processorNodeRef.current.disconnect();
      processorNodeRef.current = null;
      audioContextRef.current.suspend().then(() => {
        console.log('AudioContext for captioning suspended');
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      mediaStreamTrackRef.current?.stop();
      processorNodeRef.current?.disconnect();
      processorNodeRef.current = null;
      if (audioContextRef.current) {
        audioContextRef.current.close().then(() => {
          console.log('AudioContext for caption transcription has been closed');
        });
        audioContextRef.current = null;
      }
    };
  }, []);

  return {
    startCaptioningAudio,
    stopCaptioningAudio
  };
};
