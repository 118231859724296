import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import { removeDevice } from '@provider-api/esitter';
import { isOk } from '@provider-errors/BackendError';
import { setToastData, setToastOpen } from '@provider-reducers/toastSlice';
import { EToastStatus } from '@provider-types/enums';
import {
  removeRoomByDeviceId,
  resetSelectedDevice,
  setFocusedRoom,
  setPendingRemoval,
  setSidePanelContent
} from '@provider-reducers/esitterSlice';
import { removeBoundary } from '@provider-reducers/smartRailsSlice';
import { SidePanelContent } from '../../SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { getCallId, getSelectedDeviceId } from '@provider-selectors/esitter';
import { makeThemedStyles } from '@provider-hooks';
import { useRemoveDevice, useFocusedRoom } from '../../../../hooks';
import { Trans } from 'react-i18next';
import { Divider } from '@mui/material';
import DeviceInfo from '../DeviceInfo/DeviceInfo';

export const RemovePatientPanel = (): React.ReactElement => {
  const { focusedRoom } = useFocusedRoom();
  const dispatch = useDispatch();
  const callId = useSelector(getCallId);
  const selectedDeviceId = useSelector(getSelectedDeviceId);

  const handleRemove = async () => {
    if (callId && selectedDeviceId) {
      //selectedDevice.seen = false;
      // TODO: Make unfocused and then unseen
      const response = await removeDevice(callId, selectedDeviceId);

      if (isOk(response)) {
        dispatch(
          setToastData({
            status: EToastStatus.SUCCESS,
            message: 'Patient has been removed.',
            autoHideDuration: 4000
          })
        );
        dispatch(setToastOpen(true));
        dispatch(setPendingRemoval(false));

        if (focusedRoom === selectedDeviceId) {
          dispatch(setFocusedRoom(undefined));
        }

        dispatch(removeRoomByDeviceId(selectedDeviceId));
        dispatch(removeBoundary({ deviceId: selectedDeviceId }));
        dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
        dispatch(resetSelectedDevice());
      }
    } else {
      //setisPendingRemoval to false so I don't see it again next time I open Side Panel
      dispatch(setPendingRemoval(false));
      dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
      dispatch(resetSelectedDevice());
    }
  };

  const { onCancelRemoveClick } = useRemoveDevice();

  const { styles } = useStyles();

  return (
    <Stack sx={{ p: 4 }} spacing={4} divider={<Divider />}>
      <DeviceInfo />
      <Stack>
        <Typography>
          <Trans i18nKey={'esitter.components.SidePanel.RemovePatientPanel.modalPropmpt'} />
        </Typography>
      </Stack>
      <Stack spacing={2}>
        <Button
          variant='contained'
          startIcon={<RemoveIcon sx={styles.startIcon} />}
          color='error'
          fullWidth
          onClick={handleRemove}
        >
          <Trans i18nKey='esitter.components.SidePanel.RemovePatientPanel.modalConfirm' />
        </Button>
        <Button variant='outlined' color='primary' fullWidth onClick={onCancelRemoveClick}>
          <Trans i18nKey='global.cancel' />
        </Button>
      </Stack>
    </Stack>
  );
};

const useStyles = makeThemedStyles()(() => ({
  startIcon: {
    background: '#ffffff',
    fill: '#D93B3D',
    borderRadius: '50%'
  }
}));
