import React from 'react';
import { AddPatientListItem } from './AddPatientListItem';

export interface AddPatientHospitalListItemProps {
  label: string;
  description?: string;
  hospital: string;
  onHospitalClick: (deviceUuid: string) => void;
}
export const AddPatientHospitalListItem = React.memo((props: AddPatientHospitalListItemProps): React.ReactElement => {
  const { label, description, hospital, onHospitalClick } = props;

  const handleHospitalClick = (): void => {
    onHospitalClick(hospital);
  };

  return (
    <AddPatientListItem
      label={label}
      description={description}
      showCircleIcon={false}
      onItemClick={handleHospitalClick}
    />
  );
});
