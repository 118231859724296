import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EPrerecordedMessageGender } from '@provider-types/enums';
import { setRoomMessageGender } from '@provider-reducers/esitterSlice';
import { selectAvailableRoomMessageGenderByLang, selectRoomMessageGender } from '@provider-selectors/esitter';

type Props = {
  deviceId: string;
  disabled?: boolean;
};

export const GenderSelector = ({ deviceId, disabled = false }: Props): React.ReactElement => {
  const gender = useSelector(state => selectRoomMessageGender(state, deviceId));
  const availableGenders = useSelector(state => selectAvailableRoomMessageGenderByLang(state, deviceId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMessageGenderChange = (event: React.MouseEvent<HTMLElement>, newGender: EPrerecordedMessageGender) => {
    if (!newGender) return;

    dispatch(setRoomMessageGender({ id: deviceId, gender: newGender }));
  };

  return (
    <ToggleButtonGroup
      value={gender}
      sx={{
        border: ({ palette }) => `1px solid ${palette.grey['700']}`
      }}
      exclusive
      onChange={handleMessageGenderChange}
      aria-label='Gender'
      disabled={disabled}
    >
      {Object.values(EPrerecordedMessageGender).map(item => (
        <ToggleButton
          key={item}
          value={`${item}`}
          disabled={!availableGenders?.includes(item)}
          sx={{
            fontSize: '12px',
            padding: '2px 6px',
            lineHeight: '1',
            height: '25px',
            aspectRatio: 1,
            color: ({ palette }) => palette.common.white,
            '&.Mui-selected': {
              backgroundColor: ({ palette }) => palette.primary.main,
              ':hover': {
                backgroundColor: ({ palette }) => palette.primary.main
              }
            }
          }}
        >
          {t(`global.gender.abbr.${item}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
