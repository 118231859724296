import { Modal, SvgIconTypeMap } from '@mui/material';
import React, { useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import styles from './InfoModal.module.css';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import IconButton from '@mui/material/IconButton';
import DOMPurify from 'dompurify';

export interface InfoModalProps {
  visible: boolean;
  title: string;
  MuiSvgIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  MuiSvgIconStyle?: any;
  description: string;
  secondaryBtnName?: string;
  primaryBtnName: string;
  onSecondaryBtnClick?: () => void;
  onPrimaryBtnClick?: () => void;
  hideCloseBtn?: boolean;
  // Fires after *any* button is pressed, should be a function to 'close' the Modal
  onClose: () => void;
  disablePrimaryBtnAutoClose?: boolean;
}

export const InfoModal = React.memo((props: InfoModalProps): React.ReactElement => {
  const {
    disablePrimaryBtnAutoClose = false,
    visible,
    title,
    MuiSvgIcon,
    MuiSvgIconStyle,
    description,
    secondaryBtnName,
    primaryBtnName,
    onSecondaryBtnClick,
    onPrimaryBtnClick,
    hideCloseBtn,
    onClose
  } = props;

  const secondaryButtonClick = useCallback(() => {
    onSecondaryBtnClick && onSecondaryBtnClick();
    onClose();
  }, [onClose, onSecondaryBtnClick]);

  const primaryButtonClick = useCallback(() => {
    onPrimaryBtnClick && onPrimaryBtnClick();
    !disablePrimaryBtnAutoClose && onClose();
  }, [onClose, onPrimaryBtnClick, disablePrimaryBtnAutoClose]);

  return (
    <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={visible} onClose={onClose}>
      <div className={styles.container} data-testid='modalContainer'>
        <div className={styles.titleContainer}>
          <p className={styles.title} data-testid='title'>
            {title}
          </p>
          {!hideCloseBtn && (
            <IconButton onClick={onClose} data-testid='closeBtn'>
              <CloseIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          )}
        </div>
        <div className={styles.descriptionContainer}>
          {MuiSvgIcon && (
            <div className={styles.icon}>
              <MuiSvgIcon sx={{ width: '32px', height: '32px', color: '#46AFF2', ...MuiSvgIconStyle }} />
            </div>
          )}
          <p
            className={styles.description}
            data-testid='description'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description)
            }}
          />
        </div>
        <div className={styles.buttons}>
          {secondaryBtnName && (
            <button
              className={`${styles.btn} ${styles.secondaryBtn}`}
              data-testid='secondaryBtn'
              onClick={secondaryButtonClick}
            >
              {secondaryBtnName}
            </button>
          )}
          <button
            className={`${styles.btn} ${styles.primaryBtn}`}
            data-testid='primaryBtn'
            onClick={primaryButtonClick}
          >
            {primaryBtnName}
          </button>
        </div>
      </div>
    </Modal>
  );
});
