import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParticipantMedia, makeThemedStyles } from '@provider-hooks';
import { Participant } from 'twilio-video';
import { ListItem, ListItemAvatar } from '@mui/material';
import { ParticipantRowIndicators } from './ParticipantRowIndicators/ParticipantRowIndicators';
import ParticipantAvatar from './ParticipantAvatar/ParticipantAvatar';
import ParticipantRowText from './ParticipantRowText/ParticipantRowText';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { RootState } from '@provider-reducers/root';

interface ParticipantRowProps {
  identity: string;
  uuid: string;
  name: string;
  initials: string;
  thumbnailUrl?: string;
  color: string;
  isHost: boolean;
  twilioParticipant: Participant;
  amIHost: boolean;
  onMenuClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    uuid: string,
    amIHost: boolean,
    isHost: boolean,
    isAudioAvailable: boolean,
    isDevice: boolean,
    isBrowserTypeMobile: boolean
  ) => void;
  isMenuOpened: boolean;
}

export const ParticipantRow: React.FC<ParticipantRowProps> = (props: ParticipantRowProps): React.ReactElement => {
  const {
    identity,
    uuid,
    name,
    initials,
    thumbnailUrl,
    color,
    isHost,
    twilioParticipant,
    amIHost,
    onMenuClick,
    isMenuOpened
  } = props;
  const { isAudioAvailable = false, isVideoAvailable = false } = useParticipantMedia(twilioParticipant) || {};

  const [hover, setHover] = useState(false);
  const privacyMode = useSelector((state: RootState) => state.vrcall.privacyMode) || false;

  const isDevice = identity.split(':')[0] === 'device';
  const isBrowserTypeMobile = identity.split(':')[5] === 'mobile';
  const labels = isHost ? 'Host' : isDevice ? 'Patient' : '';
  const initialsStr = isDevice ? 'PA' : initials;

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const { styles } = useStyles({ isMenuOpened });

  return (
    <ListItem
      disablePadding
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      sx={styles.listItem}
      data-testid={'participant-row-list-item'}
    >
      <ListItemAvatar sx={styles.avatar}>
        <ParticipantAvatar thumbnailUrl={thumbnailUrl} initials={initialsStr} color={color} isDevice={isDevice} />
      </ListItemAvatar>
      <Stack
        display={'flex'}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={1}
        sx={{ width: '100%', pl: 4 }}
      >
        <ParticipantRowText name={name} labels={labels} />
        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} sx={{ minWidth: '64px' }}>
          <ParticipantRowIndicators
            isHovered={hover}
            isDevice={isDevice}
            isHost={isHost}
            isBrowserTypeMobile={isBrowserTypeMobile}
            amIHost={amIHost}
            privacyMode={privacyMode}
            isAudioAvailable={isAudioAvailable}
            isVideoAvailable={isVideoAvailable}
            onMenuClick={onMenuClick}
            uuid={uuid}
            isMenuOpened={isMenuOpened}
          />
        </Box>
      </Stack>
    </ListItem>
  );
};

type StyleProps = {
  isMenuOpened: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { isMenuOpened }) => ({
  avatar: {
    width: '32px',
    minWidth: 'unset'
  },
  listItem: {
    '& > .MuiListItemSecondaryAction-root': {
      right: '24px'
    },
    width: '100%',
    padding: '8px 24px',
    justifyContent: 'start',
    color: '#ffffff',
    backgroundColor: isMenuOpened ? '#313A42' : 'inherit',
    '&:hover': {
      backgroundColor: '#313A42',
      cursor: 'pointer'
    }
  }
}));
