import React, { ElementRef, useRef } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Dropdown.module.css';

export interface Props {
  label: string;
  items: Array<{ value: string; name: string }>;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}

export const Dropdown: React.FC<Props> = ({ label, items, value, setValue, disabled }) => {
  const selectRef = useRef<ElementRef<'div'>>(null);
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <Box sx={{ minWidth: '154px' }}>
      <FormControl fullWidth>
        <InputLabel
          id={`select-${label}`}
          classes={{
            focused: styles.focusedLabel
          }}
          sx={{
            color: disabled ? '#5B6B7B' : '#BEC3CC',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: 1,
            top: '20px'
          }}
        >
          Invite via
        </InputLabel>
        <Select
          ref={selectRef}
          /** remove focused styles once dropdown is closed, otherwise it looks strange */
          onOpen={() => {
            selectRef.current?.classList.add('Mui-focused');
          }}
          onClose={() => {
            selectRef.current?.classList.remove('Mui-focused');
          }}
          className={styles.dropdown}
          sx={{
            backgroundColor: '#232A30',
            color: disabled ? '#5B6B7B' : '#ffffff',
            fontFamily: 'Open Sans',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',

            borderRadius: '0',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',

            '& .MuiInputBase-input.Mui-disabled': {
              color: '#5B6B7B',
              WebkitTextFillColor: '#5b6b7b'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
              borderBottom: ({ palette }) => `1px solid ${palette.text.secondary}`
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderBottomColor: ({ palette }) => palette.button.primary.hover
            },
            '& > [role="button"]': {
              paddingTop: '24px',
              paddingBottom: '9px'
            }
          }}
          classes={{
            icon: styles.selectIcon
          }}
          labelId={`select-${label}`}
          IconComponent={ExpandMoreIcon}
          value={value}
          label='Invite via'
          onChange={handleChange}
          disabled={disabled}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: '#232A30',
                '& .MuiMenuItem-root:hover': {
                  color: '#46AFF2',
                  backgroundColor: '#313A42'
                }
              }
            }
          }}
        >
          {items.map(item => (
            <MenuItem
              key={`key-${item.value}`}
              sx={{
                color: '#ffffff'
              }}
              value={item.value}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
