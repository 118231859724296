import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unstable_useBlocker as useBlocker, useNavigate } from 'react-router-dom';
import { useBeforeUnload } from 'react-router-dom';
import { selectIsRedirectDisabled } from '@provider-selectors/esitter';

const DEFAULT_MESSAGE = 'Are you sure you want to leave eSitter? Any data that you entered in this app will be lost.';

export const useCustomPrompt = (message = DEFAULT_MESSAGE, preventLeave = true): void => {
  const isRedirectDisabled = useSelector(selectIsRedirectDisabled);
  const navigate = useNavigate();

  const [isBlocked, setIsBlocked] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  /** Handles reload, manual address update, tab and window close */
  const beforeunloadCallback = useCallback(
    (evt: BeforeUnloadEvent) => {
      if (!preventLeave) return;

      evt.preventDefault();
      evt.returnValue = message;
      return message;
    },
    [message, preventLeave]
  );

  useBeforeUnload(beforeunloadCallback, { capture: true });

  /** Handles back button click */
  const backButtonHandler = useCallback(
    (evt: PopStateEvent) => {
      if (!evt.state?.usr?.blocked) return;

      if (window.confirm(message)) {
        setIsBlocked(false);
        setIsExitConfirmed(true);
      }
    },
    [message]
  );

  useEffect(() => {
    if (isRedirectDisabled) {
      /**
       * There are 2 problems with rendering via Converge mode:
       * 1. The app initializes with one local history record, so browser just goes -1 on outer history stack
       * 2. useBlocker from react-router doesn't work with cross-origin iframes
       * In our case it is necessary to have history with at least 2 records to be able to fire confirmation window
       */
      navigate('.', {
        state: {
          blocked: true
        }
      });

      setIsBlocked(true);

      window.addEventListener('popstate', backButtonHandler);

      return () => {
        window.removeEventListener('popstate', backButtonHandler);
      };
    } else {
      setIsBlocked(false);
      window.removeEventListener('popstate', backButtonHandler);
    }
  }, [isRedirectDisabled, navigate, backButtonHandler]);

  useBlocker(isBlocked);

  useEffect(() => {
    if (isExitConfirmed) {
      /** After history is unblocked move 2 records backwards (our injected state + original record) */
      navigate(-2);
    }
  }, [isExitConfirmed, navigate]);
};
