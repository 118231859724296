import React from 'react';
import { Spinner } from '@provider-components';
import { makeThemedStyles } from '@provider-hooks';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { ResponsiveStyleValue } from '@mui/system';
import { mergeSx } from '@provider-utils/styles';
import { SxProps, Theme } from '@mui/material';

interface LoadingWrapperProps {
  isLoading: boolean;
  loadingText?: string;
  loadingTextVariant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  loadingStackDirection?: ResponsiveStyleValue<'row' | 'row-reverse' | 'column' | 'column-reverse'>;
  children?: React.ReactNode;
  spinnerSize?: number;
  sx?: SxProps<Theme>;
}

export const LoadingWrapper = React.forwardRef<HTMLDivElement, LoadingWrapperProps>((props, ref) => {
  const {
    isLoading,
    loadingText,
    loadingTextVariant = 'caption',
    loadingStackDirection = 'row',
    children,
    spinnerSize = 22,
    sx = {}
  } = props;
  const { styles } = useStyles();
  if (isLoading) {
    if (loadingText) {
      return (
        <Stack
          ref={ref}
          display={'flex'}
          direction={loadingStackDirection}
          alignItems={'center'}
          spacing={2}
          sx={mergeSx(styles.wrapper, sx)}
        >
          <Spinner size={spinnerSize} />
          <Typography variant={loadingTextVariant}>{loadingText}</Typography>
        </Stack>
      );
    }
    return <Spinner size={spinnerSize} />;
  }
  return <>{children}</>;
});

const useStyles = makeThemedStyles()(() => ({
  wrapper: {
    padding: '0 13px',
    minHeight: 32
  }
}));
