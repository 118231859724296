import React, { RefObject, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceRoomInfo } from '@provider-types/provider';

// Images
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  setSidePanelContent,
  setSelectedDevice,
  setPendingRemoval,
  setRefererRemovalTab
} from '@provider-reducers/esitterSlice';
import MUIButton from '@mui/material/Button';
import { MyPatientsDevicesList } from './MyPatientsDevicesList/MyPatientsDevicesList';
import { NoPatientsMessage } from './MyPatientsDevicesList/NoPatientsMessage';
import { DeviceMenu } from './MyPatientsDevicesList/DeviceMenu';
import { makeThemedStyles } from '@provider-hooks';
import Box from '@mui/material/Box';
import { SidePanelContent } from '../SidePanel';
import { selectRoomsArray } from '@provider-selectors/esitter';
import { DeviceMenuWrapper } from './MyPatientsDevicesList/DeviceMenuWrapper';

export const MyPatientsContent = (): React.ReactElement => {
  const dispatch = useDispatch();

  const roomsArray = useSelector(selectRoomsArray);

  const onAddPatientClick = useCallback(() => {
    dispatch(setSidePanelContent(SidePanelContent.ADD_PATIENT));
  }, [dispatch]);

  const { styles } = useStyles();

  const menuRef: RefObject<DeviceMenu> = React.createRef<DeviceMenu>();
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, device: IDeviceRoomInfo) => {
    menuRef.current!.setDevice(device);
    menuRef.current!.setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  const handleRemoveDeviceClick = (device: IDeviceRoomInfo): void => {
    dispatch(setSelectedDevice(device));
    dispatch(setPendingRemoval(true));
    dispatch(setRefererRemovalTab(SidePanelContent.MY_PATIENTS));
  };

  return (
    <>
      <Box sx={styles.container} data-testid='myPatientsSideBar'>
        <Box sx={styles.removeScrollbar}>
          {!roomsArray.length ? (
            <NoPatientsMessage />
          ) : (
            <>
              <DeviceMenuWrapper ref={menuRef} onRemoveClick={handleRemoveDeviceClick} />
              <MyPatientsDevicesList onMenuClick={handleMenuClick} />
            </>
          )}
        </Box>
      </Box>
      <Box sx={styles.addPatientButtonContainer}>
        <MUIButton
          fullWidth
          disabled={roomsArray.length >= 16}
          variant='contained'
          endIcon={<AddCircleIcon />}
          color='primary'
          onClick={onAddPatientClick}
          data-testid='addPatientSideBar'
        >
          Add Patient
        </MUIButton>
      </Box>
    </>
  );
};

const useStyles = makeThemedStyles()(() => ({
  container: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  removeScrollbar: {
    display: 'flex',
    width: '100%',
    marginTop: '8px',
    marginBottom: '12px',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto'
  },
  addPatientButtonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    boxSizing: 'border-box',
    padding: '8px 8px 11px 8px',
    borderTop: '1px solid #000000'
  }
}));
