import { Action, VRParticipantsState } from '@provider-types/reducer';

export const initialState: VRParticipantsState = {
  participants: [],
  participantStatus: []
};

const vrParticipantsReducer = (state = initialState, action: Action<any>): VRParticipantsState => {
  switch (action.type) {
    case 'SET_VR_PARTICIPANTS': {
      return { ...state, participants: action.payload };
    }
    case 'SET_ALL_PARTICIPANT_STATUS': {
      return { ...state, participantStatus: action.payload || [] };
    }
    case 'UPDATE_PARTICIPANT_STATUS': {
      const newParticipantStatuses = state.participantStatus.filter(p => p.uuid !== action.payload.uuid);
      const existingParticipantStatus = state.participantStatus.find(p => p.uuid === action.payload.uuid);
      // handle status with existing thumbnails
      newParticipantStatuses.push({ ...action.payload, metadata: existingParticipantStatus?.metadata });
      return { ...state, participantStatus: newParticipantStatuses };
    }
    case 'DELETE_PARTICIPANT_STATUS': {
      const uuidParticipantDisconnected = state.participants.find(p => p.twilioParticipant === action.payload)?.uuid;
      const newParticipantsStatus = state.participantStatus.filter(pS => pS.uuid !== uuidParticipantDisconnected);
      return { ...state, participantStatus: newParticipantsStatus };
    }

    case 'SET_STATE_BACK_TO_DEFAULT': {
      return {
        ...initialState
      };
    }
    default:
      return { ...state };
  }
};

export default vrParticipantsReducer;
