import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EToastStatus } from '@provider-types/enums';
import { ToastData } from '@provider-types/reducer';

export interface ToastState {
  toastOpen: boolean;
  isNewPatientAddedShowedFlag: boolean;
  toastData: ToastData;
}

const initialState: ToastState = {
  toastOpen: false,
  isNewPatientAddedShowedFlag: false,
  toastData: {
    status: EToastStatus.INFO,
    message: '',
    actionText: 'ACTION',
    onActionClick: undefined,
    autoHideDuration: 4000,
    highPosition: false
  }
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastOpen: (state: ToastState, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.toastOpen = true;
      } else {
        state.toastOpen = false;
        state.toastData = initialState.toastData;
      }
    },
    setToastData: (state: ToastState, action: PayloadAction<ToastData>) => {
      state.toastData = {
        status: action.payload?.status || '',
        message: action.payload?.message || '',
        actionText: action.payload?.actionText || 'ACTION',
        onActionClick: action.payload?.onActionClick,
        autoHideDuration: action.payload?.autoHideDuration,
        highPosition: action.payload?.highPosition
      };
    },
    setNewPatientAddedShowedFlag: (state: ToastState, action: PayloadAction<boolean>) => {
      state.isNewPatientAddedShowedFlag = action.payload;
    }
  }
});

export const { setToastOpen, setToastData, setNewPatientAddedShowedFlag } = toastSlice.actions;
export default toastSlice.reducer;
