import { RootState } from '@provider-reducers/root';
import { WebsocketsInitialState } from '@provider-types/reducer';
import { createSelector } from '@reduxjs/toolkit';

export const selectWebsockets = (state: RootState): WebsocketsInitialState => state.websockets;

export const selectWsUuid = createSelector([selectWebsockets], ws => ws.wsUuid || '');
export const selectWsReady = createSelector([selectWebsockets], ws => ws.wsReady);
export const selectWsInstanceCount = createSelector([selectWebsockets], ws => ws.wsInstanceCount);
export const selectRetryCount = createSelector([selectWebsockets], ws => ws.retryCount);
export const selectShouldInitializeWs = createSelector([selectWebsockets], ws => ws.shouldInitializeWs);
