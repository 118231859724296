import { MouseEventHandler, useRef, useState, useEffect } from 'react';

const DEFAULT_DELAY_AMOUNT = 250;

export function useCheckForDoubleClick<T = Element>(
  onClick: MouseEventHandler<T>,

  onDoubleClick: MouseEventHandler<T>,

  delay = DEFAULT_DELAY_AMOUNT
): MouseEventHandler<T> {
  const eventRef = useRef(null);

  const [clicks, setClicks] = useState(0);

  useEffect(() => {
    let singleClickTimer: ReturnType<typeof setTimeout>;

    if (eventRef.current) {
      if (clicks === 1) {
        singleClickTimer = setTimeout(() => {
          eventRef.current && onClick?.(eventRef.current);

          setClicks(0);

          eventRef.current = null;
        }, delay);
      } else if (clicks === 2) {
        onDoubleClick?.(eventRef.current);

        eventRef.current = null;

        setClicks(0);
      }
    }
    return () => clearTimeout(singleClickTimer);
  }, [clicks, delay, onClick, onDoubleClick]);

  return (e: any) => {
    eventRef.current = e;

    setClicks(clicks + 1);
  };
}
