/* eslint-disable no-duplicate-case */
import { Server as MockServer, Client as MockClient } from 'mock-socket';

type ResponseType = {
  event: string;
  data: {
    stateType: string;
    [key: string]: any;
  };
  senderType: string;
  senderUuid: string;
  callUuid: string;
};

class MockWebSocketServer {
  private static existingMockWebSocketServer: MockWebSocketServer | null = null;
  private mockServer: MockServer | undefined = undefined;
  private cameraUuid = '';

  constructor(mockUrl: string) {
    if (MockWebSocketServer.existingMockWebSocketServer) {
      MockWebSocketServer.existingMockWebSocketServer.close();
    }
    MockWebSocketServer.existingMockWebSocketServer = this;

    // mock: false to disable stubbing out the global websocket object
    this.mockServer = new MockServer(mockUrl, { mock: false });
    this.setupMockServerHandlers();
  }

  private handleMockServerMessage(socket: MockClient, wsMessage: string | ArrayBuffer | ArrayBufferView | Blob): void {
    try {
      const payload = JSON.parse(wsMessage as string);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { event, recipientType, recipientUuid, callUuid, data } = payload;
      if (recipientType !== 'device') {
        // only mock messages that are intended for devices. Could just as easily mock Provider as well
        // though
        return;
      }

      // This part fills in top-level basic values for response *back* from the device.
      // Probably no need to modify this `response` initialization here - see below to the switch statement.
      const response: ResponseType = {
        event,
        data: {
          MOCK_RESPONSE: 'THIS_IS_FROM_LOCALHOST, NOT DEVICE: @provider-serviceswebsocket_proxy/MockWebSocketServer',
          stateType: 'reported'
        },
        senderType: 'device',
        senderUuid: recipientUuid,
        callUuid
      };

      let shouldSendResponse = false;
      // Update to add responses from the mock server to Websocket messages sent by the Provider.
      // Use `Math.round(Math.random(0, 1))` to generate a 0 or 1 value randomly to test binary fields like 'paused'
      // Handle the websocket message event *from* the provider.
      switch (event) {
        // case 'deviceFeccState':
        //   response.event = 'deviceVirtualBedRailsState';
        //   response.data.cameraUuid = this.cameraUuid;
        //   response.data.enabled = false;
        //   response.data.motionDetected = false;
        //   shouldSendResponse = true;
        //   break;

        case 'deviceFeccState':
          response.event = 'captionTranscript';
          response.data.transcript = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sit amet mattis massa. Ut non ligula sodales, bibendum lorem sit amet, rhoncus odio. Nullam sed erat quis massa condimentum rutrum. Vivamus gravida nisl id nisi dignissim rhoncus. Mauris volutpat sit amet elit ut rutrum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eleifend, urna ac dignissim ullamcorper, neque metus pretium nunc, sodales ullamcorper quam ante in sem. Praesent ac risus aliquam, vestibulum enim et, dictum ligula. Proin fermentum ex mauris, porttitor rutrum quam ultrices at. Suspendisse nec feugiat ligula. Maecenas quis eros eu velit accumsan tristique. Vestibulum eu condimentum urna. Aenean felis enim, fermentum quis lacinia eget, bibendum in risus.

          Vivamus maximus ipsum at urna congue, vitae tincidunt neque aliquet. Donec risus enim, fermentum nec quam nec, hendrerit finibus mauris. Ut enim massa, fermentum vel enim at, volutpat ultricies neque. Pellentesque vitae aliquet nisl, at euismod lacus. Nulla porta tempus nisl, id ultricies diam posuere id. Donec facilisis blandit pharetra. Duis vehicula massa id metus mollis, a congue dui facilisis. Morbi non finibus nibh.

          Quisque efficitur consequat magna at imperdiet. In metus ipsum, aliquet nec lobortis sed, laoreet vitae dolor. Duis pellentesque rhoncus sem, nec sollicitudin dolor ornare eget. Suspendisse pharetra nec massa in facilisis. Suspendisse euismod vulputate risus in hendrerit. Suspendisse sit amet bibendum quam. Etiam tempor libero non hendrerit pellentesque.

          Curabitur et arcu eu massa vulputate vehicula. Sed dignissim massa felis, vitae commodo enim convallis quis. Phasellus laoreet euismod suscipit. Suspendisse nec tristique turpis, vitae elementum massa. Fusce nec imperdiet metus. Integer eget risus at est elementum hendrerit quis pellentesque dolor. Nullam consequat, risus id porta accumsan, lectus ipsum elementum leo, sed finibus dolor purus sit amet turpis. Duis eu fermentum purus. Nunc sollicitudin imperdiet risus, eget rhoncus enim pretium ac. Aliquam sit amet eros ut nulla facilisis hendrerit at quis neque. Etiam vehicula odio consequat semper pellentesque.

          Phasellus at porta ex, quis tempor ante. Donec ultrices risus elit, ac condimentum nisl vulputate a. Pellentesque eu imperdiet ex. Etiam nec urna vitae tellus scelerisque semper ac sit amet nisl. Suspendisse arcu dolor, feugiat at rutrum ut, eleifend sit amet ipsum. Nam posuere leo sed venenatis cursus. Donec volutpat lobortis metus, sed tincidunt nunc tincidunt ut. Ut eu eleifend justo. Aenean gravida luctus tortor, non tempor lorem feugiat a. Integer malesuada bibendum vulputate. Ut et libero venenatis, bibendum orci id, convallis felis. Nam diam lacus, vestibulum in mi a, auctor rhoncus sapien. Phasellus eu velit elementum, maximus turpis eget, scelerisque eros. Nullam molestie ligula quis lorem pellentesque blandit. Nunc elit est, ullamcorper tristique consectetur ac, placerat sit amet libero.

          Aliquam ut sapien volutpat, scelerisque ante nec, vehicula mi. Donec metus nisl, ultricies et nisl id, ultrices eleifend lorem. Maecenas luctus dui non eros cursus, sed tristique orci faucibus. Integer vitae nisi sed elit lobortis auctor ut sed magna. Sed scelerisque mattis sapien vel luctus. Aenean lobortis, tortor id pretium fermentum, tortor tortor finibus nulla, ut efficitur felis est consequat lorem. Pellentesque ornare tortor neque, sit amet hendrerit purus viverra at. Phasellus imperdiet sodales nisl, eu fringilla ligula molestie a. Nullam at lorem pellentesque, luctus urna in, hendrerit nunc. In lobortis posuere elementum. Integer urna orci, interdum a ex non, dapibus gravida ante. Ut magna est, porttitor id pharetra vitae, hendrerit eu lorem. Cras et accumsan urna.

          `;
          response.data.stateType = 'reported';
          response.data.senderUuid = '501731cb-919e-4290-85b2-255242c11532';
          response.data.callUuid = '01731cb-919e-4290-85b2-255242c11532';
          shouldSendResponse = true;
          break;

        case 'deviceFeccState':
          response.event = 'captionTranscript';
          response.data.transcript = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sit amet mattis massa. Ut non ligula sodales, bibendum lorem sit amet, rhoncus odio. Nullam sed erat quis massa condimentum rutrum. Vivamus gravida nisl id nisi dignissim rhoncus. Mauris volutpat sit amet elit ut rutrum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras eleifend, urna ac dignissim ullamcorper, neque metus pretium nunc, sodales ullamcorper quam ante in sem. Praesent ac risus aliquam, vestibulum enim et, dictum ligula. Proin fermentum ex mauris, porttitor rutrum quam ultrices at. Suspendisse nec feugiat ligula. Maecenas quis eros eu velit accumsan tristique. Vestibulum eu condimentum urna. Aenean felis enim, fermentum quis lacinia eget, bibendum in risus.

            Vivamus maximus ipsum at urna congue, vitae tincidunt neque aliquet. Donec risus enim, fermentum nec quam nec, hendrerit finibus mauris. Ut enim massa, fermentum vel enim at, volutpat ultricies neque. Pellentesque vitae aliquet nisl, at euismod lacus. Nulla porta tempus nisl, id ultricies diam posuere id. Donec facilisis blandit pharetra. Duis vehicula massa id metus mollis, a congue dui facilisis. Morbi non finibus nibh.

            Quisque efficitur consequat magna at imperdiet. In metus ipsum, aliquet nec lobortis sed, laoreet vitae dolor. Duis pellentesque rhoncus sem, nec sollicitudin dolor ornare eget. Suspendisse pharetra nec massa in facilisis. Suspendisse euismod vulputate risus in hendrerit. Suspendisse sit amet bibendum quam. Etiam tempor libero non hendrerit pellentesque.

            Curabitur et arcu eu massa vulputate vehicula. Sed dignissim massa felis, vitae commodo enim convallis quis. Phasellus laoreet euismod suscipit. Suspendisse nec tristique turpis, vitae elementum massa. Fusce nec imperdiet metus. Integer eget risus at est elementum hendrerit quis pellentesque dolor. Nullam consequat, risus id porta accumsan, lectus ipsum elementum leo, sed finibus dolor purus sit amet turpis. Duis eu fermentum purus. Nunc sollicitudin imperdiet risus, eget rhoncus enim pretium ac. Aliquam sit amet eros ut nulla facilisis hendrerit at quis neque. Etiam vehicula odio consequat semper pellentesque.

            Phasellus at porta ex, quis tempor ante. Donec ultrices risus elit, ac condimentum nisl vulputate a. Pellentesque eu imperdiet ex. Etiam nec urna vitae tellus scelerisque semper ac sit amet nisl. Suspendisse arcu dolor, feugiat at rutrum ut, eleifend sit amet ipsum. Nam posuere leo sed venenatis cursus. Donec volutpat lobortis metus, sed tincidunt nunc tincidunt ut. Ut eu eleifend justo. Aenean gravida luctus tortor, non tempor lorem feugiat a. Integer malesuada bibendum vulputate. Ut et libero venenatis, bibendum orci id, convallis felis. Nam diam lacus, vestibulum in mi a, auctor rhoncus sapien. Phasellus eu velit elementum, maximus turpis eget, scelerisque eros. Nullam molestie ligula quis lorem pellentesque blandit. Nunc elit est, ullamcorper tristique consectetur ac, placerat sit amet libero.

            Aliquam ut sapien volutpat, scelerisque ante nec, vehicula mi. Donec metus nisl, ultricies et nisl id, ultrices eleifend lorem. Maecenas luctus dui non eros cursus, sed tristique orci faucibus. Integer vitae nisi sed elit lobortis auctor ut sed magna. Sed scelerisque mattis sapien vel luctus. Aenean lobortis, tortor id pretium fermentum, tortor tortor finibus nulla, ut efficitur felis est consequat lorem. Pellentesque ornare tortor neque, sit amet hendrerit purus viverra at. Phasellus imperdiet sodales nisl, eu fringilla ligula molestie a. Nullam at lorem pellentesque, luctus urna in, hendrerit nunc. In lobortis posuere elementum. Integer urna orci, interdum a ex non, dapibus gravida ante. Ut magna est, porttitor id pharetra vitae, hendrerit eu lorem. Cras et accumsan urna.

            `;
          response.data.stateType = 'reported';
          response.data.senderUuid = '0e9b4906-a584-4918-af2e-05c1c368929f';
          response.data.callUuid = '01731cb-919e-4290-85b2-255242c11532';
          shouldSendResponse = true;
          break;

        case 'deviceVirtualBedRailsState':
          response.event = 'deviceVirtualBedRailsState';
          response.data.cameraUuid = data.cameraUuid;
          response.data.enabled = data.enabled;
          response.data.motionDetected = true;
          shouldSendResponse = true;
          this.cameraUuid = data.cameraUuid;
          break;
        default:
          break;
      }
      if (shouldSendResponse) {
        socket.send(JSON.stringify(response));
      }
    } catch (err) {
      console.error('Error handling message at mock WS server:', err);
    }
  }

  private setupMockServerHandlers = () => {
    // Server event handlers
    this.mockServer?.on('connection', (socket: MockClient) => {
      console.log('MockServer received a new client connection');
      socket.on('message', (data: string | ArrayBuffer | ArrayBufferView | Blob) => {
        this.handleMockServerMessage(socket, data);
      });
    });
  };

  public close = (): void => {
    if (this.mockServer) {
      this.mockServer.close();
    }
  };
}

export default MockWebSocketServer;
