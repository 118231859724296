import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MediaPermissionsInitialState } from '@provider-types/reducer';

const initialState: MediaPermissionsInitialState = {
  isCameraOk: false,
  isMicrophoneOk: false,
  shouldCheckMic: false,
  shouldCheckCamera: false,
  checkAttemptCount: 0,
  initialCamCheckComplete: false,
  initialMicCheckComplete: false,
  checkInProgress: false
};

const mediaPermissionsSlice = createSlice({
  name: 'mediaPermissions',
  initialState,
  reducers: {
    setIsCameraPermOk: (state, action: PayloadAction<boolean>) => {
      state.isCameraOk = action.payload;
    },

    setIsMicPermOk: (state, action: PayloadAction<boolean>) => {
      state.isMicrophoneOk = action.payload;
    },

    shouldCheckMicPerm: state => {
      state.shouldCheckMic = true;
      state.checkAttemptCount = state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1;
    },
    shouldCheckCameraPerm: state => {
      state.shouldCheckCamera = true;
      state.checkAttemptCount = state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1;
    },
    shouldCheckMicAndCameraPerm: state => {
      state.shouldCheckCamera = true;
      state.shouldCheckMic = true;
      state.checkAttemptCount = state.checkInProgress ? state.checkAttemptCount : state.checkAttemptCount + 1;
    },
    cameraOrMicCheckBegun: state => {
      state.checkInProgress = true;
    },
    cameraOrMicCheckCompleted: state => {
      state.checkInProgress = false;
    },
    initialCameraPermCheckComplete: state => {
      state.initialCamCheckComplete = true;
    },
    initialMicPermCheckComplete: state => {
      state.initialMicCheckComplete = true;
    }
  }
});

export const {
  setIsCameraPermOk,
  setIsMicPermOk,
  shouldCheckMicPerm,
  shouldCheckCameraPerm,
  shouldCheckMicAndCameraPerm,
  cameraOrMicCheckBegun,
  cameraOrMicCheckCompleted,
  initialCameraPermCheckComplete,
  initialMicPermCheckComplete
} = mediaPermissionsSlice.actions;

export default mediaPermissionsSlice.reducer;
