import React from 'react';
import { Stack } from '@mui/material';
import RemovePatientButton from '../RemovePatientButton/RemovePatientButton';
import DeviceInfo from '../DeviceInfo/DeviceInfo';

export const DeviceInfoPanel = (): React.ReactElement => {
  return (
    <Stack direction={'column'} spacing={4}>
      <DeviceInfo />
      <RemovePatientButton />
    </Stack>
  );
};
