import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { selectCloseCaptionsMessages } from '@provider-selectors/closeCaptions';

import styles from './CloseCaptions.module.css';
import { selectCurrentUser } from '@provider-selectors/app';

export type ICloseCaptionsParticipants = {
  participants: {
    uuid: string;
    name: string;
    imageUrl?: string;
    initials?: string;
  }[];
};

export const CloseCaptions = ({ participants }: ICloseCaptionsParticipants): React.ReactElement => {
  const currentUser = useSelector(selectCurrentUser);
  const scrollToBottomRef = useRef<HTMLInputElement>(null);
  const messages = useSelector(selectCloseCaptionsMessages);

  const senderInfo = useCallback(
    (senderUuid: string) => {
      const patient = participants?.find((participant: { uuid: string }) => participant.uuid === senderUuid);
      if (patient) {
        return {
          name: patient.name,
          initials: patient.initials?.trim(),
          imageUrl: patient.imageUrl
        };
      } else if (currentUser && currentUser.host) {
        const {
          metadata: { displayName, imageUrl, specialty }
        } = currentUser;
        return {
          name: displayName,
          imageUrl,
          specialty
        };
      }
    },
    [currentUser, participants]
  );

  const messagesWithSenderName = useMemo(() => {
    return messages.map(message => {
      return {
        ...message,
        senderInfo: senderInfo(message.senderUuid)
      };
    });
  }, [messages, senderInfo]);

  useEffect(() => {
    scrollToBottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }, [messages.length]);

  return (
    <div className={styles.ccWrapper} data-testid='CloseCaptions-container'>
      <div className={styles.cc}>
        {messagesWithSenderName?.map((message, index) => (
          <div
            className={styles.messageWrapper}
            key={uuidv4()}
            ref={index === messagesWithSenderName.length - 1 ? scrollToBottomRef : null}
          >
            <div className={styles.messageSenderWrapper}>
              <div className={styles.avatarWrapper}>
                {message.senderInfo?.imageUrl ? (
                  <img src={message.senderInfo?.imageUrl} alt='' />
                ) : (
                  `${message.senderInfo?.initials ? message.senderInfo?.initials : message.senderInfo?.name[0]}`
                )}
              </div>
              <p className={styles.senderName}>{`${message.senderInfo?.name} ${
                message.senderInfo?.specialty ? message.senderInfo.specialty : ''
              }`}</p>
            </div>

            <p className={styles.message}>{message.data.transcript}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
