import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ListItem, ListItemButton, ListItemText, SxProps, Theme } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { makeThemedStyles } from '@provider-hooks';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { mergeSx } from '@provider-utils/styles';

export enum DeviceAvailability {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BUSY = 'busy'
}

export interface AddPatientListItemProps {
  label: string;
  description?: string;
  onItemClick: () => void;
  showCircleIcon: boolean;
  circleIconSx?: SxProps<Theme>;
  arrowIconSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  dataTestId?: string;
}
export const AddPatientListItem = React.memo((props: AddPatientListItemProps): React.ReactElement => {
  const { label, description, onItemClick, circleIconSx, arrowIconSx, showCircleIcon, labelSx, dataTestId } = props;

  const { styles } = useStyles();

  return (
    <Tooltip title={label} placement='right'>
      <ListItem disablePadding>
        <ListItemButton onClick={onItemClick} sx={styles.button} data-testid={dataTestId}>
          {showCircleIcon && (
            <ListItemIcon sx={{ width: '20px', minWidth: 0 }}>
              <CircleIcon sx={mergeSx(styles.circleIcon, circleIconSx)} />
            </ListItemIcon>
          )}
          <ListItemText primary={label} secondary={description} sx={mergeSx(styles.listItemText, labelSx)} />
          <ArrowForwardIosIcon sx={mergeSx(styles.arrowIcon, arrowIconSx)} />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
});

const useStyles = makeThemedStyles()(() => ({
  arrowIcon: {
    color: '#ffffff',
    width: '16px',
    height: '16px'
  },
  circleIcon: {
    color: '#D93B3D',
    width: '8px',
    height: '8px',
    mt: '5px',
    mr: '8px'
  },
  listItemText: {
    wordWrap: 'break-word'
  },
  button: {
    justifyContent: 'start',
    paddingRight: 4,
    '&:hover': {
      backgroundColor: '#313A42',
      cursor: 'pointer'
    }
  }
}));
