import React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import Box from '@mui/material/Box';
import { invisibleScrollbar, mergeSx, styledScrollbar } from '@provider-utils/styles';
import { SxProps, Theme } from '@mui/material';

export interface ScrollableContainerProps {
  mainContainer: React.ReactNode;
  bottomContainer: React.ReactNode;
  hideScrollBar?: boolean;
  mainSectionSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
}

export const ScrollableContainer = React.forwardRef<HTMLDivElement, ScrollableContainerProps>(
  (props, ref): React.ReactElement => {
    const { mainContainer, bottomContainer, mainSectionSx, containerSx, hideScrollBar = true } = props;
    const { styles } = useStyles({ hideScrollBar });

    return (
      <Box sx={mergeSx(styles.scrollContainer, containerSx)}>
        <Box ref={ref} sx={mergeSx(styles.main, mainSectionSx)}>
          {mainContainer}
        </Box>
        {!!bottomContainer && (
          <Box sx={styles.bottom} data-testid='bottomContainer'>
            {bottomContainer}
          </Box>
        )}
      </Box>
    );
  }
);

type StyleProps = {
  hideScrollBar: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { hideScrollBar }) => ({
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    ...(hideScrollBar ? invisibleScrollbar() : styledScrollbar())
  },
  bottom: {
    borderTop: '1px solid #000000',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    bottom: 0
  }
}));
