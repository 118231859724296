import React from 'react';
import { useNavigate } from 'react-router';
import { DeviceRouteNames } from '@provider-features/devices/router';
import styles from '../../styles/Participant.module.css';

const Participant: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.emptyBox}>
        <p className={styles.emptyTitle}>No invitation for you!</p>
        <button className={styles.joinMeeting} onClick={() => navigate(DeviceRouteNames.AdminDevices)}>
          Navigate to Devices page
        </button>
      </div>
    </div>
  );
};

export default Participant;
