import { Checkbox, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React, { ElementRef, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ELogEvent } from '@provider-types/enums';
import { CheckmarkFilled } from '@provider-components/Icons';

type Props = {
  onChange?: (selected: ELogEvent[]) => void;
};

export default function LogFilter({ onChange }: Props): React.ReactElement {
  const [selected, setSelected] = useState<ELogEvent[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const selectRef = useRef<ElementRef<'div'>>(null);
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value }
    } = event;
    // On autofill we get a stringified value.
    const updatedSelected = (typeof value === 'string' ? value.split(',') : value) as ELogEvent[];

    setSelected(updatedSelected);

    if (onChange && typeof onChange === 'function') {
      onChange(updatedSelected);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Select
      ref={selectRef}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableUnderline
      multiple
      variant='standard'
      sx={{
        '& .MuiSvgIcon-root': { color: '#ffffff' },
        '& .MuiSelect-select': { padding: '0px 24px 0px 0px' },
        color: '#ffffff',
        fontSize: '14px',
        letterSpacing: '0.5px',
        borderRadius: '8px',
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        border: ({ palette }) => `1px solid ${palette.grey['700']}`,
        py: 1,
        px: 2,
        width: '100%'
      }}
      MenuProps={{
        MenuListProps: {
          onMouseLeave: handleClose
        },
        sx: {
          '& .MuiModal-root': {
            zIndex: '-1 !important'
          },
          '& .MuiPaper-root': {
            backgroundColor: '#232A30',
            color: '#ffffff',
            fontSize: '14px',
            letterSpacing: '0.25px',
            fontFamily: 'Open Sans'
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: '#313A42'
          },
          '& .Mui-selected': {
            backgroundColor: '#313A42'
          }
        }
      }}
      value={selected}
      onChange={handleChange}
      displayEmpty
      renderValue={selected => {
        if (selected.length === 0) {
          return t('esitter.logEvent.allEvents');
        }

        return selected.map(event => t(`esitter.logEvent.${event}.name`)).join(', ');
      }}
    >
      {Object.values(ELogEvent).map(event => (
        <MenuItem key={event} value={event}>
          <Checkbox
            checked={selected.includes(event)}
            sx={{ p: 0, pr: 1 }}
            checkedIcon={<CheckmarkFilled color={'primary'} />}
          />
          <Trans i18nKey={`esitter.logEvent.${event}.name`} />
        </MenuItem>
      ))}
    </Select>
  );
}
