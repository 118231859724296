import { RootState } from '@provider-reducers/root';
import { SmartRailsState } from '@provider-reducers/smartRailsSlice';
import { createSelector } from 'reselect';
import { selectSelectedDeviceSelectedCameraUuid, selectSidePanelContentName } from '../esitter';
import _ from 'lodash';
import { SmartRailsStatus } from '@provider-features/esitter/components/SidePanel/content/SmartRailsPanel/SmartRailsPanel';
import { SmartRailsSensitivityLevel } from '@provider-features/esitter/components/SidePanel/content/SmartRailsContent/SmartRailsContent';
import { SidePanelContent } from '@provider-features/esitter/components/SidePanel/SidePanel';

export const getSmartRails = (state: RootState): SmartRailsState => state.smartRails;
export const selectBoundariesArray = createSelector([getSmartRails], smartRails => smartRails.boundariesArray || []);
export const isBoundariesSetUpForSelectedCamera = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, selectedCameraUuid) => {
    return smartRails.boundariesArray.findIndex(value => value.id === selectedCameraUuid) >= 0;
  }
);

export const isBoundariesSetUpForCameraUuid = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    return smartRails.boundariesArray.findIndex(value => value.id === cameraUuid) >= 0;
  }
);

export const selectIsSaving = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, cameraUuid) => {
    const { isSaving } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || { isSaving: false };
    return isSaving;
  }
);

export const selectIsSaved = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, cameraUuid) => {
    const { isSaved } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || { isSaved: false };
    return isSaved;
  }
);

export const selectSaveDisabled = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { saveDisabled } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      saveDisabled: true
    };
    return saveDisabled;
  }
);
export const getLastBoundaryWithMotionDetected = createSelector([getSmartRails], smartRails => {
  const sortedBoundaries = _.sortBy(smartRails.boundariesArray, b => -b.motionDetectedTimestamp);
  return sortedBoundaries.find(b => b.motionDetected && b.mutedTimestamp < Date.now());
});

export const getReactivatedBoundaries = createSelector([getSmartRails], smartRails => {
  return smartRails.boundariesArray.find(value => value.reactivated);
});

export const selectEditMode = createSelector(
  [getSmartRails, selectSidePanelContentName, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, contentName, cameraUuid) => {
    const { editMode } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      editMode: false
    };
    return editMode && Boolean(contentName === SidePanelContent.SMART_RAILS);
  }
);
export const selectSelectedCameraEditMode = createSelector(
  [getSmartRails, selectSidePanelContentName, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, contentName, cameraUuid) => {
    const { editMode } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      editMode: false
    };
    return editMode && Boolean(contentName === SidePanelContent.SMART_RAILS);
  }
);

export const selectEnabled = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { enabled } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      enabled: false
    };
    return enabled;
  }
);

export const selectIsPausedForIntervention = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { isPausedForIntervention } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      enabled: false
    };
    return isPausedForIntervention;
  }
);

export const selectIsPtzChanged = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { ptzChanged } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      enabled: false
    };
    return ptzChanged;
  }
);

export const selectSensitivityLevel = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { sensitivityLevel } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      sensitivityLevel: SmartRailsSensitivityLevel.MEDIUM
    };
    return sensitivityLevel;
  }
);

export const selectMotionDetected = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { motionDetected } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      motionDetected: false
    };
    return motionDetected;
  }
);

export const getCameraSmartRailsStatus = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    const { enabled, motionDetected, mutedTimestamp } = smartRails.boundariesArray.find(
      value => value.id === cameraUuid
    ) || {
      enabled: false,
      motionDetected: false,
      mutedTimestamp: 0
    };
    return mutedTimestamp >= Date.now()
      ? SmartRailsStatus.MUTED
      : enabled && motionDetected
      ? SmartRailsStatus.ALARM
      : enabled
      ? SmartRailsStatus.ON
      : SmartRailsStatus.OFF;
  }
);

export const getSelectedCameraSmartRailsStatus = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, cameraUuid) => {
    const { enabled, motionDetected, mutedTimestamp } = smartRails.boundariesArray.find(
      value => value.id === cameraUuid
    ) || {
      enabled: false,
      motionDetected: false,
      mutedTimestamp: 0
    };
    return mutedTimestamp >= Date.now()
      ? SmartRailsStatus.MUTED
      : enabled && motionDetected
      ? SmartRailsStatus.ALARM
      : enabled
      ? SmartRailsStatus.ON
      : SmartRailsStatus.OFF;
  }
);

export const getSelectedCameraSmartRailsPolygon = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, cameraUuid) => {
    const { points } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      points: []
    };
    return points;
  }
);

export const getSelectedCameraSmartRailsFirstTime = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, cameraUuid) => {
    const { firstTime } = smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
      firstTime: false
    };
    return firstTime;
  }
);

export const getSelectedCameraBoundaryMutedTimestamp = createSelector(
  [getSmartRails, selectSelectedDeviceSelectedCameraUuid],
  (smartRails, selectedCameraUuid) => {
    const { mutedTimestamp } = smartRails.boundariesArray.find(value => value.id === selectedCameraUuid) || {
      mutedTimestamp: 0
    };
    return mutedTimestamp;
  }
);

export const getBoundaryByCameraUuid = createSelector(
  [getSmartRails, (smartRails, cameraUuid) => cameraUuid],
  (smartRails, cameraUuid) => {
    return (
      smartRails.boundariesArray.find(value => value.id === cameraUuid) || {
        id: '',
        points: [],
        firstTime: true,
        mutedTimestamp: 0,
        motionDetectedTimestamp: 0,
        reactivated: false,
        status: SmartRailsStatus.OFF
      }
    );
  }
);
