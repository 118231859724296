import { useDispatch } from 'react-redux';

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoModal } from '@provider-components/InfoModal/InfoModal';

type VersionInfo = {
  version: string;
};

const VersionCheckDialog = (): React.ReactElement | null => {
  const [userNotified, setUserNotified] = useState(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkVersion = async () => {
      try {
        const response = await fetch('/version.json', {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Cache-Control': 'no-cache'
          }
        });
        const { version: newVersion }: VersionInfo = await response.json();
        const currentVersion = process.env.REACT_APP_VERSION;
        const shouldShowDialog = currentVersion && newVersion && newVersion !== currentVersion;

        if (shouldShowDialog) {
          setOpen(true);
        }
      } catch (error) {
        console.error('Failed to check for updates:', error);
      }
    };

    if (process.env.NODE_ENV === 'production') {
      checkVersion();

      intervalRef.current = setInterval(checkVersion, 1 * 60 * 1000);

      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      };
    }
  }, [dispatch, t]);

  return (
    <InfoModal
      visible={open && !userNotified}
      title={t('appVersionChecker.title')}
      description={t('appVersionChecker.description')}
      primaryBtnName={t('appVersionChecker.refresh')}
      onPrimaryBtnClick={() => {
        window.location.reload();
      }}
      hideCloseBtn={true}
      onClose={() => {
        setOpen(false);
        setUserNotified(true);
      }}
    />
  );
};

export default VersionCheckDialog;
