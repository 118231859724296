import Box from '@mui/material/Box';
import { TagButton } from '@provider-components';
import { ETagColor, ETagColorName } from '@provider-types/enums';
import React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import { getSelectedDevice } from '@provider-selectors/esitter';

export const Tags = (): JSX.Element => {
  const { styles } = useStyles();
  const { tagColor } = useSelector(getSelectedDevice) || { tagColor: null };

  const colors = Object.values(ETagColor);
  const colorNames = Object.values(ETagColorName);

  return (
    <div>
      <Typography variant={'caption'} color={'text.secondary'}>
        Tag Patient
      </Typography>
      <Box sx={styles.tagsContainer} data-testid='tagsContainer'>
        {colors.map((color, index) => (
          <TagButton key={color} color={color} activeColor={tagColor} colorName={colorNames[index]} />
        ))}
      </Box>
    </div>
  );
};

const useStyles = makeThemedStyles()(() => ({
  tagsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    paddingTop: '8px'
  }
}));
