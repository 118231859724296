import WestIcon from '@mui/icons-material/West';
import React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import MUIButton from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';

interface SidebarBackNavigationProps extends ButtonProps {
  title: string;
}
export const SidebarBackNavigation = (props: SidebarBackNavigationProps): JSX.Element => {
  const { title, ...otherProps } = props;
  const { styles } = useStyles();
  return (
    <MUIButton disableRipple fullWidth variant='text' sx={styles.button} startIcon={<WestIcon />} {...otherProps}>
      {title}
    </MUIButton>
  );
};

const useStyles = makeThemedStyles()(_ => ({
  button: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    '&:hover, &:active, & .Mui-focusVisible': {
      backgroundColor: 'transparent'
    },
    '& > .MuiButton-startIcon': {
      ml: 0,
      mr: 1
    },
    '& > .MuiButton-startIcon>*:first-of-type': {
      backgroundColor: 'transparent',
      color: '#46aff2'
    },
    '&:hover': {
      '& > .MuiButton-startIcon>*:first-of-type': {
        backgroundColor: 'transparent'
      }
    },
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    color: '#46aff2',
    justifyContent: 'start'
  }
}));
