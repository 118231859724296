import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export const CheckmarkFilled = createSvgIcon(
  <>
    <path d='M 19 3 H 5 C 3.89 3 3 3.9 3 5 V 19 C 3 20.1 3.89 21 5 21 H 19 C 20.11 21 21 20.1 21 19 V 5 C 21 3.9 20.11 3 19 3 Z' />
    <path d='M 10 17 L 5 12 L 6.41 10.59 L 10 14.17 L 17.59 6.58 L 19 8 L 10 17 Z' fill={'white'} />
  </>,
  'CheckmarkFilled'
);
