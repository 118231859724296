import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RoomBoundary, RoomBoundaryPoint } from '@provider-types/reducer';
import _ from 'lodash';
import { AppDispatch, AppThunk } from '../root';
import { getDefaultCoordinates } from '@provider-features/esitter/functions';
import { EToastStatus } from '@provider-types/enums';
import { setToastData, setToastOpen } from '../toastSlice';
// eslint-disable-next-line max-len
import { SmartRailsSensitivityLevel } from '@provider-features/esitter/components/SidePanel/content/SmartRailsContent/SmartRailsContent';

interface SetBoundaryPayload {
  boundary: RoomBoundary;
}

interface AddBoundaryPayload {
  boundary: RoomBoundary;
}

interface RemoveBoundaryPayload {
  deviceId: string;
}
interface RemoveBoundaryByIdPayload {
  cameraUuid: string;
}

interface SetBoundaryToDefaultPayload {
  cameraUuid: string;
  deviceId: string;
}

export interface SmartRailsState {
  boundariesArray: RoomBoundary[];
}

const initialState: SmartRailsState = {
  boundariesArray: []
};

export const checkSaveFailed = (cameraUuid: string): AppThunk => {
  return (dispatch: AppDispatch, getState): void => {
    const state = getState();
    const { isSaved, cancelTimer } = state.smartRails.boundariesArray.find(el => el.id === cameraUuid) || {
      isSaved: false,
      cancelTimer: false
    };

    if (state.smartRails.boundariesArray.length && !isSaved && !cancelTimer) {
      dispatch(resetSaveBoundaries({ cameraUuid }));
      dispatch(
        setToastData({
          status: EToastStatus.WARNING,
          message: 'Settings were not saved. Try again or ask your IT administrator for help.',
          actionText: 'Dismiss',
          onActionClick: () => {
            dispatch(setToastOpen(false));
          },
          autoHideDuration: 4000
        })
      );
      dispatch(setToastOpen(true));
      dispatch(setSaveDisabled({ cameraUuid, disabled: false }));
    }
  };
};

export const setSavingWithTimeout = (cameraUuid: string): AppThunk => {
  return (dispatch: AppDispatch): void => {
    dispatch(setIsSaving({ cameraUuid }));
    setTimeout(() => {
      dispatch(checkSaveFailed(cameraUuid));
    }, 10000);
  };
};

const smartRailsSlice = createSlice({
  name: 'smartRails',
  initialState,
  reducers: {
    resetSmartRailsState: () => initialState,

    // TODO: not used nowhere except tests
    setBoundary: (state, action: PayloadAction<SetBoundaryPayload>) => {
      const { boundary } = action.payload;
      const indexToUpdate = state.boundariesArray.findIndex(b => b.id === boundary.id);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate] = boundary;
      } else {
        state.boundariesArray.push(boundary);
      }
    },

    setBoundaryToDefault: (state, action: PayloadAction<SetBoundaryToDefaultPayload>) => {
      const { cameraUuid, deviceId } = action.payload;

      const boundary: RoomBoundary = {
        id: cameraUuid,
        deviceId: deviceId,
        firstTime: true,
        ptzChanged: false,
        reactivated: false,
        points: getDefaultCoordinates(),
        mutedTimestamp: 0,
        enabled: false,
        sensitivityLevel: SmartRailsSensitivityLevel.MEDIUM,
        motionDetected: false,
        motionDetectedTimestamp: 0,
        editMode: true,
        isSaving: false,
        isSaved: false,
        saveDisabled: true,
        cancelTimer: false,
        isPausedForIntervention: false
      };

      const indexToUpdate = state.boundariesArray.findIndex(b => b.id === cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate] = boundary;
      } else {
        state.boundariesArray.push(boundary);
      }
    },
    addBoundary: (state, action: PayloadAction<AddBoundaryPayload>) => {
      const { boundary } = action.payload;
      state.boundariesArray.push(boundary);
    },
    removeBoundary: (state, action: PayloadAction<RemoveBoundaryPayload>) => {
      const { deviceId } = action.payload;

      state.boundariesArray = state.boundariesArray.filter((d: RoomBoundary) => d.deviceId !== deviceId);
    },
    removeBoundaryById: (state, action: PayloadAction<RemoveBoundaryByIdPayload>) => {
      const { cameraUuid } = action.payload;
      const index = state.boundariesArray.findIndex(b => b.id === cameraUuid);

      if (index >= 0) {
        state.boundariesArray.splice(index, 1);
      }
    },

    setEnabled: (state, action: PayloadAction<{ cameraUuid: string; enabled: boolean }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].enabled = action.payload.enabled;
      }
    },

    setSensitivityLevel: (
      state,
      action: PayloadAction<{ cameraUuid: string; sensitivityLevel: SmartRailsSensitivityLevel }>
    ) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].sensitivityLevel = action.payload.sensitivityLevel;
      }
    },
    setMotionDetected: (state, action: PayloadAction<{ cameraUuid: string; motionDetected: boolean }>) => {
      const { motionDetected } = action.payload;
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].motionDetected = action.payload.motionDetected;
        state.boundariesArray[indexToUpdate].motionDetectedTimestamp = motionDetected ? Date.now() : 0;
      }
    },

    setBoundaryFirstTime: (state, action: PayloadAction<{ cameraUuid: string }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].firstTime = false;
      }
    },
    setBoundaryPoints: (state, action: PayloadAction<{ cameraUuid: string; points: RoomBoundaryPoint[] }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].points = _.cloneDeep(action.payload.points);
      }
    },
    setBoundaryMuted: (state, action: PayloadAction<{ cameraUuid: string; mutedTimestamp: number }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].mutedTimestamp = action.payload.mutedTimestamp;
      }
    },
    setBoundaryReactivated: (state, action: PayloadAction<{ cameraUuid: string; reactivated: boolean }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].reactivated = action.payload.reactivated;
      }
    },
    setPtzChanged: (state, action: PayloadAction<{ cameraUuid: string; ptzChanged: boolean }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].ptzChanged = action.payload.ptzChanged;
      }
    },
    setEditMode: (state, action: PayloadAction<{ cameraUuid: string; editMode: boolean }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].editMode = action.payload.editMode;
      }
    },
    resetEditMode: state => {
      state.boundariesArray.forEach(b => (b.editMode = false));
    },
    resetSaveBoundaries: (state, action: PayloadAction<{ cameraUuid: string }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].isSaving = false;
        state.boundariesArray[indexToUpdate].isSaved = false;
      }
    },
    setIsSaving: (state, action: PayloadAction<{ cameraUuid: string }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].isSaving = true;
        state.boundariesArray[indexToUpdate].cancelTimer = false;
      }
    },
    setIsPausedForIntervention: (
      state,
      action: PayloadAction<{ cameraUuid: string; isPausedForIntervention: boolean }>
    ) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].isPausedForIntervention = action.payload.isPausedForIntervention;
      }
    },
    setIsSaved: (state, action: PayloadAction<{ cameraUuid: string; cancelTimer?: boolean }>) => {
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === action.payload.cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].isSaved = true;
        state.boundariesArray[indexToUpdate].isSaving = false;
        state.boundariesArray[indexToUpdate].cancelTimer = action.payload.cancelTimer || false;
      }
    },
    setSaveDisabled: (state, action: PayloadAction<{ cameraUuid: string; disabled: boolean }>) => {
      const { cameraUuid, disabled } = action.payload;
      const indexToUpdate = state.boundariesArray.findIndex(boundary => boundary.id === cameraUuid);
      if (indexToUpdate !== -1) {
        state.boundariesArray[indexToUpdate].saveDisabled = disabled;
        if (!disabled) {
          state.boundariesArray[indexToUpdate].isSaved = false;
        }
      }
    }
  }
});

export const setRoomBoundaryMuted = (cameraUuid: string, mutedTimestamp: number): AppThunk => {
  return (dispatch: AppDispatch, getState): void => {
    const state = getState();

    const { motionDetectedTimestamp, enabled } = state.smartRails.boundariesArray.find(el => el.id === cameraUuid) || {
      motionDetectedTimestamp: 0,
      enabled: true
    };

    dispatch(setBoundaryMuted({ cameraUuid, mutedTimestamp: mutedTimestamp }));

    //show reactivated notification
    if (mutedTimestamp === 0 && motionDetectedTimestamp === 0 && enabled) {
      dispatch(setBoundaryReactivated({ cameraUuid, reactivated: true }));
    }
  };
};

export const setRoomBoundaryMutedFor = (cameraUuid: string, delayInMs: number): AppThunk => {
  return (dispatch: AppDispatch): void => {
    const timeToMute = Date.now() + delayInMs;
    dispatch(setRoomBoundaryMuted(cameraUuid, timeToMute));

    setTimeout(() => {
      dispatch(setRoomBoundaryMuted(cameraUuid, 0));
    }, delayInMs);
  };
};

export const receiveSmartRailsStateMessage = (
  cameraUuid: string,
  reportedMotionDetected: boolean,
  reportedEnabled: boolean,
  reportedSensitivityLevel: SmartRailsSensitivityLevel
): AppThunk => {
  return (dispatch: AppDispatch, getState): void => {
    const state = getState();

    const { enabled, ptzChanged } = state.smartRails.boundariesArray.find(el => el.id === cameraUuid) || {
      ptzChanged: false,
      enabled: false
    };

    if (!enabled && ptzChanged && reportedEnabled) {
      dispatch(setBoundaryReactivated({ cameraUuid, reactivated: true }));
      dispatch(setPtzChanged({ cameraUuid, ptzChanged: false }));
    }

    dispatch(setEnabled({ cameraUuid, enabled: reportedEnabled }));
    // eslint-disable-next-line max-len
    // Protect against ever setting the sensitivity level to null (in case the Hub provides a null value here, fall back to previously set value)
    if (reportedSensitivityLevel) {
      dispatch(setSensitivityLevel({ cameraUuid, sensitivityLevel: reportedSensitivityLevel }));
    }
    dispatch(setMotionDetected({ cameraUuid, motionDetected: reportedMotionDetected }));
  };
};

export const {
  addBoundary,
  removeBoundary,
  removeBoundaryById,
  setEnabled,
  setSensitivityLevel,
  setMotionDetected,
  setBoundary,
  setBoundaryFirstTime,
  setBoundaryPoints,
  setBoundaryMuted,
  setPtzChanged,
  setBoundaryReactivated,
  setBoundaryToDefault,
  setEditMode,
  resetEditMode,
  resetSaveBoundaries,
  setIsSaving,
  setIsSaved,
  setSaveDisabled,
  setIsPausedForIntervention,
  resetSmartRailsState
} = smartRailsSlice.actions;

export default smartRailsSlice.reducer;
