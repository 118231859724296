import { PaletteOptions } from '@mui/material';

export const initialPalette: PaletteOptions = {
  primary: {
    main: '#1774CC',
    light: '#46AFF2'
  },
  secondary: {
    main: '#232A30'
  },
  error: {
    main: '#D93B3D'
  },
  success: {
    main: '#15DD4A'
  },
  background: {
    default: '#232A30',
    paper: '#1A1F24',
    secondary: '#313A42'
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#BEC3CC'
  },
  button: {
    primary: {
      default: '#1774CC',
      active: '#07539A',
      hover: '#46AFF2',
      focus: '#07539A',
      disabled: '#5B6B7B'
    },
    secondary: {
      default: '#313A42',
      active: '#232A30',
      hover: '#5B6B7B',
      focus: '#232A30',
      disabled: '#5B6B7B'
    }
  },
  label: {
    primary: {
      default: '#BEC3CC'
    }
  },
  icon: {
    primary: {
      default: '#FFFFFF'
    }
  },
  grey: {
    '700': '#5B6B7B'
  }
};
