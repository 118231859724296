import React, { ElementRef, Suspense, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Graph } from './Graph/Graph';

import { useSize } from 'ahooks';
import { useSelector } from 'react-redux';

import { selectIsAudioLevelSupportedForDevice, selectRoomAudioLevel } from '@provider-selectors/esitter';

import { ColorRange } from '@provider-types/esitter.types';

const DescriptionModal = React.lazy(() => import('./DescriptionModal/DescriptionModal'));

type Props = {
  participantId: string | null | undefined;
  hidden?: boolean;
  ranges?: ColorRange;
  wrapperRef?: React.RefObject<HTMLLIElement>;
};

const DEFAULT_RANGES: ColorRange = new Map([
  [0, { color: '#BEC3CC', label: 'audioLevel.normal' }],
  [70, { color: '#FFA800', label: 'audioLevel.warning' }],
  [80, { color: '#E82628', label: 'audioLevel.danger' }]
]);

const GRAPH_Y_PADDINGS = 4;

export const AudioVisualizer = ({
  participantId,
  hidden,
  ranges = DEFAULT_RANGES,
  wrapperRef
}: Props): React.ReactElement | null => {
  const containerRef = useRef<ElementRef<'div'>>(null);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const level = useSelector(state => selectRoomAudioLevel(state, participantId));
  const isAudioLevelSupported = useSelector(state => selectIsAudioLevelSupportedForDevice(state, participantId));

  const size = useSize(containerRef);
  const width = size?.width ? size.width : 0;
  const barHeight = Math.floor(Math.max(width * 0.02, 8));

  useEffect(() => {
    wrapperRef?.current?.style.setProperty(
      '--audioBarHeight',
      isAudioLevelSupported ? `${barHeight + GRAPH_Y_PADDINGS * 2}px` : '0px'
    );
  }, [barHeight, wrapperRef, isAudioLevelSupported]);

  if (!isAudioLevelSupported) return null;

  return (
    <Box
      data-testid={'AudioVisualizer_Container'}
      sx={{
        padding: `${GRAPH_Y_PADDINGS}px 2px`,
        background: ({ palette }) => palette.common.black,
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
        position: 'relative'
      }}
    >
      <Box
        ref={containerRef}
        data-testid={'AudioVisualizer_Control'}
        onClick={() => {
          setIsDescriptionOpen(true);
        }}
        sx={{
          fontSize: '0px'
        }}
        style={{
          visibility: hidden ? 'hidden' : 'visible'
        }}
      >
        <Graph level={level} width={width} ranges={ranges} barHeight={barHeight} />
      </Box>
      {isDescriptionOpen && (
        <Suspense fallback={null}>
          <DescriptionModal level={level} ranges={ranges} handleOpen={setIsDescriptionOpen} />
        </Suspense>
      )}
    </Box>
  );
};
