import React, { useEffect, useState } from 'react';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { ListItemButton } from '@mui/material';

import {
  WsFileActionsParams,
  wsFileAction
} from '@provider-features/esitter/hooks/useEsitterWSMessages/useEsitterWSMessages';
import { useTranslation } from 'react-i18next';
import { useDebounceEffect } from 'ahooks';

type Props = {
  playing: boolean;
  text: string;
  lang: string;
  id: string;
  progress?: number;
  fileUri: string;
  onClick: (params: WsFileActionsParams) => void;
  disabled?: boolean;
};

const MessageListItem = ({
  playing,
  text,
  lang,
  id,
  onClick,
  fileUri,
  disabled = false
}: Props): React.ReactElement => {
  const Icon = PlayCircleOutlineOutlinedIcon;
  const { t } = useTranslation();
  const language = t(`langCode.${lang}`);

  const [isFinishingTransition, setIsFinishingTransition] = useState(false);
  const [debouncedPlaying, setDebouncedPlaying] = useState(false);

  /**
   *  needed for correct exit animation,
   *  otherwise exit animation plays with init speed of 300ms
   */
  useDebounceEffect(
    () => {
      setDebouncedPlaying(playing);
    },
    [playing],
    { wait: 50 }
  );

  useEffect(() => {
    if (!playing) {
      setIsFinishingTransition(true);

      const timeout = setTimeout(() => {
        setIsFinishingTransition(false);
      }, 1500);

      return () => {
        clearTimeout(timeout);
        setIsFinishingTransition(false);
      };
    }
  }, [playing]);

  const onPlayClick = () => {
    if (playing) return;

    onClick({
      actionType: wsFileAction.PLAY_AUDIO,
      fileActionId: id,
      fileUri,
      data: {
        text,
        lang: language
      }
    });
  };

  return (
    <ListItemButton
      onPointerEnter={() => {
        setIsFinishingTransition(false);
      }}
      data-testid={`messageListItem:${id}`}
      sx={{
        padding: '0 0 8px',
        fontSize: '12px',
        transitionDuration: isFinishingTransition ? '1500ms' : '300ms',
        transitionProperty: 'color',
        color: ({ palette }) => (debouncedPlaying || playing ? palette.success.main : 'currentColor'),

        '&.MuiListItemButton-root .MuiTouchRipple-child': {
          backgroundColor: 'transparent'
        },

        '&:hover': {
          cursor: 'pointer',
          transitionDuration: '300ms',
          backgroundColor: 'transparent',
          color: ({ palette }) => (!playing ? palette.primary.light : palette.success.main)
        }
      }}
      onClick={onPlayClick}
      disabled={disabled}
    >
      <Icon
        sx={{
          fontSize: '16px',
          marginRight: '8px'
        }}
      />
      <span>{text}</span>
    </ListItemButton>
  );
};

export default React.memo(MessageListItem);
