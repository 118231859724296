import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionMode, ELogEvent, ELogEventPhase, LogEventLevel } from '@provider-types/enums';
import { ELogEntry, ELogInput } from '@provider-types/esitter.types';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch, AppThunk } from '../root';

export interface LogState {
  esitterLog: ELogEntry[];
}

type FormattedLogEntry<T> = T & { id: string; timestamp: number; level: unknown };

const initialState: LogState = {
  esitterLog: []
};

export const formatLogEntry = <T extends object>(log: T): FormattedLogEntry<T> => {
  return {
    ...log,
    id: uuidv4(),
    timestamp: Date.now(),
    level: 'level' in log && log.level ? log.level : LogEventLevel.INFO
  };
};

const logSlice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    resetLog: () => initialState,
    addEsitterLogEntry: {
      reducer: (state, action: PayloadAction<ELogEntry>) => {
        state.esitterLog.unshift(action.payload);
      },
      prepare: (entry: ELogInput) => {
        return { payload: formatLogEntry(entry) };
      }
    }
  }
});

export const esitterDeviceModeChange = ({
  prevState,
  nextState,
  deviceId
}: {
  prevState?: ConnectionMode | string;
  nextState?: ConnectionMode | string;
  deviceId: string;
}): AppThunk => {
  return async (dispatch: AppDispatch, _): Promise<void> => {
    const trackedMode = new Map<string, ELogEvent>([
      [ConnectionMode.INTERACTIVE, ELogEvent.INTERVENTION],
      [ConnectionMode.LISTENING, ELogEvent.LISTEN_IN]
    ]);

    if (prevState === nextState) return;

    if (prevState && trackedMode.get(prevState)) {
      dispatch(
        addEsitterLogEntry({
          type: trackedMode.get(prevState) as ELogEvent.INTERVENTION | ELogEvent.LISTEN_IN,
          phase: ELogEventPhase.ENDED,
          deviceId
        })
      );
    }

    if (nextState && trackedMode.get(nextState)) {
      dispatch(
        addEsitterLogEntry({
          type: trackedMode.get(nextState) as ELogEvent.INTERVENTION | ELogEvent.LISTEN_IN,
          phase: ELogEventPhase.STARTED,
          deviceId
        })
      );
    }
  };
};

export const { addEsitterLogEntry, resetLog } = logSlice.actions;

export default logSlice.reducer;
