import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { CameraListItem } from '@provider-types/provider';
import { makeThemedStyles } from '@provider-hooks';
import { SxProps, Theme } from '@mui/material';
import { mergeSx } from '@provider-utils/styles';
import { useLocation } from 'react-router-dom';

export interface SelectCameraDropdownProps {
  onCameraChange: (cameraUuid: string) => void;
  selectedCameraUuid: string;
  cameraList: CameraListItem[];
  selectSx?: SxProps<Theme>;
  menuSx?: SxProps<Theme>;
  zoomTextSx?: SxProps<Theme>;
  disabled?: boolean;
}

export const SelectCameraDropdown = ({
  onCameraChange,
  selectedCameraUuid,
  cameraList = [],
  selectSx,
  menuSx,
  disabled = false
}: SelectCameraDropdownProps): React.ReactElement => {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { styles } = useStyles({ isOpen });

  const handleChangeCamera = (event: SelectChangeEvent) => {
    onCameraChange(event.target.value as string);
  };

  const isVRCall = location.pathname.includes('vr');

  return (
    <Select
      id='select-small'
      data-testid='cameraDropDown'
      disableUnderline
      variant='standard'
      sx={mergeSx(styles.select, selectSx)}
      MenuProps={{
        sx: mergeSx(styles.menu, menuSx),
        MenuListProps: {
          'aria-label': 'camera-list'
        },
        anchorOrigin: {
          vertical: isVRCall ? 'top' : 'bottom',
          horizontal: 'center'
        }
      }}
      value={selectedCameraUuid}
      onChange={handleChangeCamera}
      disabled={disabled}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
    >
      {cameraList.map(camera => (
        <MenuItem key={camera.uuid} sx={styles.menuItem} value={camera.uuid}>
          {`${camera.name} ${camera.zoomLevel}x Zoom`}
        </MenuItem>
      ))}
    </Select>
  );
};

const useStyles = makeThemedStyles<{ isOpen: boolean }>()((_, { isOpen }) => ({
  select: {
    '& .MuiSvgIcon-root': { color: '#ffffff', border: 'none' },
    '& .MuiSelect-select': {
      border: '1px solid #5B6B7B',
      borderColor: isOpen ? '#46AFF2' : '#5B6B7B',
      borderRadius: '4px',
      padding: '4px 8px 4px 8px',
      width: '100%'
    },
    color: '#ffffff',
    fontSize: '12px',
    letterSpacing: '0.5px',
    fontFamily: 'Open Sans',

    width: '100%',

    boxShadow: 'none',
    '.MuiOutlinedInput-notchedOutline': { border: 0 }
  },
  menu: {
    '& .MuiModal-root': {
      zIndex: '-1 !important'
    },
    '& .MuiPaper-root': {
      backgroundColor: '#232A30',
      color: '#ffffff',
      fontSize: '12px',
      letterSpacing: '0.25px',
      fontFamily: 'Open Sans'
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#313A42'
    },
    '& .Mui-selected': {
      backgroundColor: '#313A42',
      borderColor: '#46AFF2'
    }
  },
  menuItem: { display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '4px' }
}));
