import React from 'react';
import { Box, Stack } from '@mui/material';
import { GenderSelector } from './GenderSelector';
import { LanguageSelector } from './LanguageSelector';
import { MessageSelector } from '@provider-components';

import { getSelectedDeviceId, selectIsDeviceInPrivacyMode, selectIsDevicePaused } from '@provider-selectors/esitter';
import { useSelector } from 'react-redux';

export const PrerecordedMessages = (): React.ReactElement | null => {
  const deviceId = useSelector(getSelectedDeviceId);

  // TODO: MM refactor to use a combined hook selector
  const privacyMode = useSelector(state => selectIsDeviceInPrivacyMode(state, deviceId));
  const paused = useSelector(state => selectIsDevicePaused(state, deviceId));

  const isPausedOrPrivacyMode = privacyMode || paused;

  return (
    <Stack spacing={4} data-testid='prerecordedMessagesPanel'>
      <Box>
        <GenderSelector deviceId={deviceId} disabled={isPausedOrPrivacyMode} />
      </Box>
      <Box>
        <LanguageSelector deviceId={deviceId} disabled={isPausedOrPrivacyMode} />
      </Box>
      <Box>
        <MessageSelector deviceId={deviceId} disabled={isPausedOrPrivacyMode} />
      </Box>
    </Stack>
  );
};
