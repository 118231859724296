import { useContext } from 'react';
import { FocusedContainerWidthContext } from '../../components/RoomsGrid/FocusedContainerWidthContext';

const SHOW_PTZ_CONTROL_BUTTON_WIDTH = 610;

export const usePTZControlVisibility = (): boolean => {
  const focusedContainerWidth = useContext(FocusedContainerWidthContext);

  return focusedContainerWidth < SHOW_PTZ_CONTROL_BUTTON_WIDTH;
};
