import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { postParticipantLogin } from '@provider-api/embed';
import { makeCallActive } from '@provider-api/esitter';
import { ESitterRouteNames } from '@provider-features/esitter/router';
import { VRCallRouteNames } from '@provider-features/vrcall/router';
import { EmbedRouteNames } from '../../router';
import styles from '../../styles/Begin.module.css';
import { useMediaDevices, useMediaHardwareChecker } from '@provider-hooks';
import { InfoModal } from '@provider-components';
import LogoutSvg from '@provider-assets/images/logout.svg';
import { useWebsocketHelper } from 'src/wsHelper';
import { isBackendError } from '@provider-errors/BackendError';
import { RootState } from '@provider-reducers/root';
import {
  setAppMediaAccessModalData,
  setAppMediaAccessModalVisible,
  setCmsVersion,
  setIsCamActive,
  setCallType,
  setMyUserId
} from '@provider-reducers/appSlice';
import { setRedirectDisabled } from '@provider-reducers/esitterSlice';
import { ECallType } from '@provider-types/enums';

const Begin: React.FC<EmptyProps> = () => {
  const navigate = useNavigate();
  const { callUuid, loginToken } = useParams();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const dispatch = useDispatch();

  const { initialCamCheckComplete, initialMicCheckComplete, isCameraOk, isMicrophoneOk } = useSelector(
    (state: RootState) => state.mediapermissions
  );

  const { triggerCameraAndMicCheck, triggerMicCheck } = useMediaHardwareChecker();
  const { mediaAccessModalVisible, mediaAccessModalData } = useSelector((state: RootState) => state.app);
  const { initiateWsConnection, wsReady, setWsOnMessageHandler } = useWebsocketHelper();

  const [loggedIn, setLoggedIn] = useState(false);
  const [callParticipants, setCallParticipants] = useState<any[]>([]);
  const [userUuid, setUserUuid] = useState('');
  const [requiredMediaIsReady, setRequiredMediaIsReady] = useState(false);
  const [wsMessageHandlerIsConfigured, setWsMessageHandlerIsConfigured] = useState(false);
  const [hasTriggeredInitialMediaCheck, setHasTriggeredInitialMediaCheck] = useState(false);

  // Trigger population of redux state for media devices before joining call.
  useMediaDevices({ shouldEnumerateDevices: true });

  const { changingMode, initialRequestedDeviceMode, mode } = useSelector((state: RootState) => state.vrcall);

  const onModalClose = useCallback(() => {
    dispatch(setAppMediaAccessModalVisible(false));
  }, [dispatch]);

  // Handle initially obtaining media permissions
  useEffect(() => {
    if (requiredMediaIsReady) {
      return;
    }

    dispatch(setCallType(query.get('type') ?? ECallType.ESITTER));
    const callIsVr = query.get('type') === ECallType.VR;

    if (!hasTriggeredInitialMediaCheck) {
      if (callIsVr && !initialCamCheckComplete) {
        console.log('VR begin: triggering mic/cam check');
        triggerCameraAndMicCheck();
      } else if (!initialMicCheckComplete) {
        console.log('esitter begin: triggering mic check');
        triggerMicCheck();
      }
      setHasTriggeredInitialMediaCheck(true);
      return;
    }

    // Permissions checks started, wait for them to coplete
    if ((callIsVr && !initialCamCheckComplete) || !initialMicCheckComplete) {
      return;
    }

    let displayMediaErrorModal = !isMicrophoneOk;
    let modalTitle = 'Allow mic use';
    let modalDescription =
      'We need access to your microphone so that Patients can hear you during an intervention.' +
      ' Click the mic blocked icon in your browsers address bar to adjust the settings and continue.';
    let primaryBtnName = 'Retry';
    let secondaryBtnName = 'End Session';
    if (callIsVr) {
      displayMediaErrorModal = displayMediaErrorModal || !isCameraOk;
      primaryBtnName = 'Retry';
      secondaryBtnName = 'Cancel your call';
      modalTitle = 'Allow cam and mic use';
      modalDescription =
        'We need access to your camera and microphone so that other participants can see and hear you.' +
        ' Click the camera blocked icon in your browsers address bar to adjust the settings and continue.';
    }

    // do not proceed to make the call active until we confirm media hardware is available
    if (displayMediaErrorModal) {
      dispatch(setRedirectDisabled(false));
      dispatch(
        setAppMediaAccessModalData({
          title: modalTitle,
          MuiSvgIcon: ErrorOutlineIcon,
          MuiSvgIconStyle: { color: '#D93B3D' },
          description: modalDescription,
          primaryBtnName,
          secondaryBtnName,
          onPrimaryBtnClick: () => {
            // nothing, as the `onClose` behavior captures this case. We want to recheck the hardware.
          },
          onSecondaryBtnClick: () => {
            if (!callIsVr) {
              //Set end Screen message and Icon
              dispatch({
                type: 'SET_END_CALL_ICON',
                payload: LogoutSvg
              });
              dispatch({
                type: 'SET_END_CALL_MESSAGE',
                payload: 'Session Ended.'
              });
            }
            navigate(EmbedRouteNames.CallEnded);
            // Loading call ended page will trigger useEffect cleanup in eSitter to Stop Call
          },
          hideCloseBtn: true,
          onClose: () => {
            if (callIsVr && !isCameraOk) {
              triggerCameraAndMicCheck();
            } else if (!isMicrophoneOk) {
              triggerMicCheck();
            } else {
              // Permissions are OK, proceed
              dispatch(setRedirectDisabled(true));
              onModalClose();
              setRequiredMediaIsReady(true);
            }
          }
        })
      );
      dispatch(setAppMediaAccessModalVisible(true));
      return;
    }
    // if we reach here, there is no error
    onModalClose();
    setRequiredMediaIsReady(true);
  }, [
    requiredMediaIsReady,
    setRequiredMediaIsReady,
    query,
    navigate,
    dispatch,
    onModalClose,
    triggerCameraAndMicCheck,
    triggerMicCheck,
    isCameraOk,
    isMicrophoneOk,
    initialCamCheckComplete,
    initialMicCheckComplete,
    hasTriggeredInitialMediaCheck,
    setHasTriggeredInitialMediaCheck
  ]);

  // Handle login separately from subsequent logic so it is not attempted multiple times
  // Only log in after we ensure required media is available
  useEffect(() => {
    if (!callUuid || !loginToken) {
      return;
    }
    // Check for permissions for *any* valid mic/camera, and ensure we've successfully detected at least one
    // mic before proceeding
    if (!requiredMediaIsReady) {
      return;
    }
    if (!loggedIn) {
      const attemptLogin = async () => {
        const postResponse = await postParticipantLogin({ callUuid: callUuid, loginToken: loginToken });

        if (isBackendError(postResponse)) {
          if (query.get('type') === ECallType.ESITTER) {
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: LogoutSvg
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: 'Session Ended.'
            });
          }
          dispatch(setRedirectDisabled(false));
          navigate(EmbedRouteNames.CallEnded);
          return;
        }

        dispatch(setCmsVersion(postResponse.headers.get('x-cms-version') as string));

        setUserUuid(postResponse.body.userUuid);
        //MXTODO: temp solution. will be refactored later
        dispatch(setMyUserId(postResponse.body.participants[0]));
        setCallParticipants(postResponse.body.participants);
        setLoggedIn(true);
        // now, also trigger websocket establishment
        initiateWsConnection();
      };
      attemptLogin();
    }
  }, [
    callUuid,
    query,
    dispatch,
    navigate,
    loginToken,
    loggedIn,
    requiredMediaIsReady,
    setLoggedIn,
    initiateWsConnection
  ]);

  // copy handler here so we can access it
  /* WEBSOCKET MESSAGES HANDLING */
  const onWebsocketMessage = useCallback(
    (data: any) => {
      let message: any;
      try {
        message = JSON.parse(data?.data);
      } catch (error) {
        console.log(`Received unparseable JSON from server: ${data?.data}`);
        return;
      }
      console.log('WS message: ', message);
      const messageData = message?.data && typeof message?.data === 'string' ? JSON.parse(message.data) : message?.data;
      console.log('WS message data: ', messageData);

      //ignore WS messages with different callUUID.
      if (!message || !messageData || message.callUuid !== callUuid) {
        return;
      }

      if (message?.event === 'deviceCallState') {
        //Change Mode confirmation message
        if (messageData.stateType === 'reported') {
          dispatch({
            type: 'SET_VR_MODE',
            payload: messageData.mode
          });
          // if the device is ringing - set ringing
          if (messageData.ringing) {
            console.log('messageData for ringing 1: ', messageData.ringing);
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: true
            });
          }
        }
        // else if (messageData.stateType === 'desired') {
        //   //for other participants on the call (only hosts care for this)
        //   dispatch({
        //     type: 'SET_VR_MODE_CHANGING',
        //     payload: true
        //   });
        // }
      } else if (message?.event === 'error') {
        //Patient rejected the call or somehow the change mode failed
        if (messageData.failedEvent === 'deviceCallState') {
          console.log(
            'Error message from device because: ',
            messageData.reasonCode,
            messageData.reasonMsg,
            messageData.supplementaryData
          );
          // Note: We don't expect to receive 3001 before the call starts (since it is an error
          // from an external API, so the device should try to 'join the call' before returning it),
          // but adding a handler here for consistency
          if (messageData.reasonCode === 3001) {
            // end the call and notify user
            dispatch({
              type: 'SET_VR_MODAL_DATA',
              payload: {
                title: 'Video call not possible at this time',
                MuiSvgIcon: VideocamOffIcon,
                description:
                  `A video call could not be initiated due to an error with ` +
                  `the TV controller. ` +
                  `Please contact your IT department.` +
                  `\n\n Observation mode may still work.`,
                primaryBtnName: 'Okay',
                onPrimaryBtnClick: () => {
                  //same behavior as onClose
                },
                onClose: () => {
                  dispatch({
                    type: 'END_CALL_PATIENT_REJECTION',
                    payload: true
                  });
                }
              }
            });
            dispatch({
              type: 'SET_VR_MODAL_VISIBLE',
              payload: true
            });
          }
          // only leave the call on patient rejection if:
          // 1. We are *not* trying to begin an interaction, the device is currently in observation mode,
          // and rejects (rejects the initial call), OR
          // - The initial requested device mode (before call started) was interactive
          // busy = 1001, privacy mode = 1006
          else if (messageData.reasonCode === 1001 || messageData.reasonCode === 1006) {
            if (messageData.reasonCode === 1006) {
              dispatch({
                type: 'SET_VR_FECC_VALUES',
                payload: { privacyMode: true }
              });
            }
            //only if reason is patient rejected the call
            if (
              (!changingMode && mode.includes('observation')) ||
              initialRequestedDeviceMode?.includes('interactive')
            ) {
              // end the call and notify user
              dispatch({
                type: 'SET_VR_MODAL_DATA',
                payload: {
                  title: 'Patient not available at this time',
                  MuiSvgIcon: VideocamOffIcon,
                  description:
                    'The patient is not available for a visit at this time. Please try again after some time.',
                  primaryBtnName: 'Okay',
                  onPrimaryBtnClick: () => {
                    //same behavior as onClose
                  },
                  onClose: () => {
                    dispatch({
                      type: 'END_CALL_PATIENT_REJECTION',
                      payload: true
                    });
                  }
                }
              });
            } else {
              // notify user, do not end the call
              dispatch({
                type: 'SET_VR_MODAL_DATA',
                payload: {
                  title: 'Patient not available at this time',
                  MuiSvgIcon: VideocamOffIcon,
                  description:
                    'The patient is not available for a visit at this time. Please try again after some time.',
                  primaryBtnName: 'Okay',
                  onPrimaryBtnClick: () => {
                    //same behavior as onClose
                  },
                  onClose: () => {
                    dispatch({
                      type: 'END_CALL_PATIENT_REJECTION',
                      payload: true
                    });
                  }
                }
              });
            }
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: false
            });
            dispatch({
              type: 'SET_AM_I_STARTING_INTERACTION',
              payload: false
            });
            dispatch({
              type: 'SET_VR_MODAL_VISIBLE',
              payload: true
            });
          } else if (messageData.reasonCode === 1002) {
            // if a mode change was already happening
            dispatch({
              type: 'SET_VR_MODE_CHANGING',
              payload: true
            });
          }
        }
      } else if (message?.event === 'deviceFeccState' && message?.senderType === 'device') {
        if (messageData.stateType === 'reported') {
          dispatch({
            type: 'SET_VR_FECC_VALUES',
            payload: messageData
          });
          dispatch({
            type: 'SET_HAS_RECEIVED_WS_MESSAGE',
            payload: true
          });
        }
      }
    },
    [changingMode, initialRequestedDeviceMode, dispatch, mode, callUuid]
  );

  // Listen for initial call state/FECC state that might be received before the page
  // navigates to VRCall page
  useEffect(() => {
    const callType = query.get('type');
    if (wsReady) {
      if (callType === ECallType.VR) {
        setWsOnMessageHandler(onWebsocketMessage);
        setWsMessageHandlerIsConfigured(true);
      } else {
        setWsMessageHandlerIsConfigured(true);
      }
    }
  }, [wsReady, setWsOnMessageHandler, setWsMessageHandlerIsConfigured, onWebsocketMessage, query]);

  useEffect(() => {
    // Only start call once login is complete, and websocket is established, and websocket listener is configured
    if (!loggedIn || !callUuid || !wsMessageHandlerIsConfigured) {
      return;
    }
    const callType = query.get('type');

    const beginAndNavigateToCall = async () => {
      const patchResponse = await makeCallActive(callUuid);

      if (isBackendError(patchResponse)) {
        if (query.get('type') === ECallType.ESITTER) {
          //Set end Screen message and Icon
          dispatch({
            type: 'SET_END_CALL_ICON',
            payload: LogoutSvg
          });
          dispatch({
            type: 'SET_END_CALL_MESSAGE',
            payload: 'Session Ended.'
          });
        }
        dispatch(setRedirectDisabled(false));
        navigate(EmbedRouteNames.CallEnded);
        return;
      }

      switch (callType) {
        case ECallType.ESITTER: {
          dispatch(setIsCamActive(false));
          navigate(ESitterRouteNames.Esitter, {
            state: {
              call: patchResponse
            },
            replace: true
          });

          break;
        }
        case ECallType.VR: {
          dispatch({
            type: 'SET_IS_MY_MIC_ACTIVE',
            payload: true
          });

          dispatch(setIsCamActive(true));

          dispatch({
            type: 'SET_VR_TEST_CALL',
            payload: query.get('test')?.includes('true') ? true : false
          });

          // get the thumbnail for this provider
          const matchingUser = callParticipants.find((p: any) => p.uuid === userUuid);
          console.log(`part: ${JSON.stringify(callParticipants)} self: ${userUuid}`);
          dispatch({
            type: 'SET_ALL_PARTICIPANT_STATUS',
            payload: [
              {
                host: true,
                uuid: userUuid,
                // includes image URL
                metadata: matchingUser?.metadata
              }
            ]
          });
          console.log('[Begin] patchResponse', patchResponse);
          navigate(VRCallRouteNames.VRCall, {
            state: { call: patchResponse, userUuid, isHost: true }
          });
          break;
        }
      }
    };
    beginAndNavigateToCall();
  }, [callUuid, userUuid, callParticipants, loggedIn, navigate, query, dispatch, wsMessageHandlerIsConfigured]);
  // TODO return black screen instead of white screen
  return (
    <div className={styles.container}>
      <InfoModal
        visible={mediaAccessModalVisible}
        title={mediaAccessModalData.title}
        MuiSvgIcon={mediaAccessModalData.MuiSvgIcon}
        MuiSvgIconStyle={mediaAccessModalData.MuiSvgIconStyle}
        description={mediaAccessModalData.description}
        secondaryBtnName={mediaAccessModalData.secondaryBtnName}
        primaryBtnName={mediaAccessModalData.primaryBtnName}
        onSecondaryBtnClick={mediaAccessModalData.onSecondaryBtnClick}
        onPrimaryBtnClick={mediaAccessModalData.onPrimaryBtnClick}
        hideCloseBtn={mediaAccessModalData.hideCloseBtn}
        onClose={mediaAccessModalData.onClose || onModalClose}
      />
    </div>
  );
};

export default Begin;
