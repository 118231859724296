import { FieldConfig, FieldMetaProps, FormikProps, useField, useFormikContext } from 'formik';
import { FilledInputWithLabel, FilledInputWithLabelProps } from './FilledInputWithLabel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';

export const hasErrorHelper = (form: FormikProps<any>, meta: FieldMetaProps<any>): boolean => {
  return (meta.touched || form.submitCount > 0) && Boolean(meta.error);
};

export type FieldConfigPart = Omit<FieldConfig, 'value' | 'type' | 'component' | 'render'>;

type FilledInputWithLabelFieldProps = FilledInputWithLabelProps &
  FieldConfigPart & {
    onChangeCallback?: (value: string) => void;
    errorIcon?: boolean;
  };
export const FilledInputWithLabelField = React.memo((props: FilledInputWithLabelFieldProps): React.ReactElement => {
  const [field, meta] = useField(props.name);
  const formik = useFormikContext();
  const { onChangeCallback, label, errorIcon, ...otherProps } = props;
  const hasError = hasErrorHelper(formik, meta);

  if (errorIcon && hasError) {
    const endAdornment = (
      <InputAdornment position='end'>
        <ErrorOutlineIcon color={'error'} />
      </InputAdornment>
    );

    otherProps.endAdornment = endAdornment;
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowUp', 'ArrowLeft', 'ArrowDown', 'ArrowRight', 'Minus', 'Equal'].includes(event.key)) {
      event.stopPropagation();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldTouched(field.name);

    formik.setFieldValue(field.name, event.target.value);
    if (onChangeCallback) {
      onChangeCallback(event.target.value);
    }
  };

  return (
    <>
      <FilledInputWithLabel
        {...otherProps}
        autoComplete={'off'}
        name={field.name}
        value={field.value}
        error={hasError}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        label={label}
      />
      {hasError && !props.disabled ? <FormHelperText error={true}>{meta.error}</FormHelperText> : null}
    </>
  );
});
