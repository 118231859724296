import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import styles from './ButtonControl.module.css';
import { Box } from '@mui/system';
import { EButtonControlSize, EButtonControlColor } from '@provider-types/enums';

export interface Props {
  backgroundColor: string;
  color?: EButtonControlColor.BLUE | EButtonControlColor.GRAY | EButtonControlColor.RED | EButtonControlColor.WHITE;
  disabled?: boolean;
  size?:
    | EButtonControlSize.SMALL
    | EButtonControlSize.MEDIUM
    | EButtonControlSize.LARGE
    | EButtonControlSize.EXTRALARGE;
  tipTitle?: string;
  children: JSX.Element;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  [dataAttibute: `data-${string}`]: string;
}

export const ButtonControl: React.FC<Props> = ({
  backgroundColor,
  color,
  disabled,
  size,
  tipTitle = '',
  children,
  onClick,
  ...rest
}) => {
  let sizeToPixels_h = 24;
  let sizeToPixels_w = 24;
  if (size === EButtonControlSize.MEDIUM) {
    sizeToPixels_h = 32;
    sizeToPixels_w = 32;
  } else if (size === EButtonControlSize.LARGE) {
    sizeToPixels_h = 48;
    sizeToPixels_w = 48;
  } else if (size === EButtonControlSize.EXTRALARGE) {
    sizeToPixels_h = 48;
    sizeToPixels_w = 152;
  }
  const [style, setStyle] = useState('normal');
  const styleColor = color ? color : EButtonControlColor.GRAY;

  return (
    <Tooltip title={tipTitle}>
      <Box
        component='div'
        className={`${styles.btnControlContainer} ${disabled ? styles.disabled : ''} ${
          style === 'hover' ? styles.hover : ''
        } ${style === 'normal' ? styles.normal : ''} ${style === 'active' ? styles.active : ''} 
              ${styleColor === EButtonControlColor.BLUE ? styles.blue : ''} ${
          styleColor === EButtonControlColor.GRAY ? styles.gray : ''
        }
              ${styleColor === EButtonControlColor.RED ? styles.red : ''} ${
          styleColor === EButtonControlColor.WHITE ? styles.white : ''
        } 
              `}
        style={{
          backgroundColor: color ? undefined : backgroundColor,
          width: `${sizeToPixels_w}px`,
          height: `${sizeToPixels_h}px`,
          borderRadius: size === EButtonControlSize.EXTRALARGE ? `${sizeToPixels_h / 2}px` : '50%'
        }}
        onClick={onClick}
        onMouseEnter={() => setStyle('hover')}
        onMouseLeave={() => setStyle('normal')}
        onMouseDown={() => setStyle('active')}
        onMouseUp={() => setStyle('normal')}
        {...rest}
      >
        {children}
      </Box>
    </Tooltip>
  );
};
