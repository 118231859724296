import { Action as ReduxAction, combineReducers } from 'redux';

import devicesReducer from './devices';
import websocketsReducer from './websockets';
import vrCallReducer from './vrcall';
import vrParticipantsReducer from './vrparticipants';
import uiStateReducer from './uiState';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import smartRailsReducer from './smartRailsSlice';
import toastReducer from './toastSlice';
import closeCaptionsSlice from './closeCaptionsSlice';
import appSlice from './appSlice';
import settingsSlice from './settingsSlice';
import esitterSlice from './esitterSlice';
import mediaPermissionsSlice from './mediaPermissionsSlice';
import dialogSlice from './dialogSlice';
import logSlice from './logSlice';

const rootReducer = combineReducers({
  app: appSlice,
  devices: devicesReducer,
  dialog: dialogSlice,
  settings: settingsSlice,
  websockets: websocketsReducer,
  vrcall: vrCallReducer,
  vrparticipants: vrParticipantsReducer,
  esitter: esitterSlice,
  mediapermissions: mediaPermissionsSlice,
  uiState: uiStateReducer,
  smartRails: smartRailsReducer,
  toast: toastReducer,
  closeCaptions: closeCaptionsSlice,
  log: logSlice
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, ReduxAction<string>>;
export type AppDispatch = ThunkDispatch<RootState, unknown, ReduxAction<string>>;
export default rootReducer;
