import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getSelectedDeviceName, selectSelectedDeviceFacility } from '@provider-selectors/esitter';
import { useTranslation } from 'react-i18next';

export default function DeviceInfo(): React.ReactElement {
  const selectedDeviceName = useSelector(getSelectedDeviceName);
  const selectedDeviceFacility = useSelector(selectSelectedDeviceFacility);
  const { t } = useTranslation();

  const sections = [
    {
      caption: t('esitter.components.SidePanel.DeviceInfo.hospitalHeader'),
      value: selectedDeviceFacility,
      dataTestId: 'hospital'
    },
    {
      caption: t('esitter.components.SidePanel.DeviceInfo.deviceHeader'),
      value: selectedDeviceName,
      dataTestId: 'device'
    }
  ];

  return (
    <>
      {sections.map(({ caption, value, dataTestId }, idx) => (
        <Stack spacing={1} sx={{ overflow: 'hidden' }} key={idx} data-testid={dataTestId}>
          <Typography data-testid={`${dataTestId}-label`} variant='caption' color={'text.secondary'}>
            {caption}
          </Typography>
          <Typography
            data-testid={`${dataTestId}-value`}
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
            title={value}
          >
            {value}
          </Typography>
        </Stack>
      ))}
    </>
  );
}
