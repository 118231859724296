import { getCallId, getSelectedDeviceId } from '@provider-selectors/esitter';
import { useEsitterBlurMode } from '@provider-hooks';

import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React from 'react';

export const BlurModePanel = (): React.ReactElement => {
  const deviceId = useSelector(getSelectedDeviceId);
  const callId = useSelector(getCallId);

  const { t } = useTranslation();

  const { isEnabled, isButtonDisabled, toggleBlurMode } = useEsitterBlurMode(deviceId, callId);

  return (
    <FormControlLabel
      data-testid='blurModeSwitch'
      disabled={isButtonDisabled}
      control={<Switch checked={isEnabled} onChange={toggleBlurMode} />}
      label={
        isEnabled
          ? t('esitter.components.SidePanel.BlurModePanel.mode.on')
          : t('esitter.components.SidePanel.BlurModePanel.mode.off')
      }
      color={'text.secondary'}
    />
  );
};
