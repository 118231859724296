import React from 'react';
import { FallbackPage } from '@provider-components';
import { AuthRouteNames } from './route-names';

const SignIn = React.lazy(() => import('../screens/SignIn'));

export const AuthRoutes = [
  {
    path: AuthRouteNames.SignIn,
    element: (
      <React.Suspense fallback={<FallbackPage />}>
        <SignIn />
      </React.Suspense>
    )
  }
];
