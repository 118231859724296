import React from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styles from './LandscapeWrapper.module.css';
import { useSelector } from 'react-redux';
import { EBrowserType } from '@provider-types/enums';
import { RootState } from '@provider-reducers/root';

export const LandscapeWrapper: React.FC<EmptyProps> = ({ children }) => {
  const { browserType } = useSelector((state: RootState) => state.settings);

  /**
   * Comment this if statement if you want to test it
   * with mobile dimensions in browser
   * OR:
   * Other lifehack if you are testing, set needed dimensions
   * and reload the page. It`ll recognize as mobile browser type
   */
  if (browserType === EBrowserType.DESKTOP) {
    return <>{children}</>;
  }

  return (
    <>
      <div className={styles.container}>{children}</div>
      <div className={styles.landscapeMode}>
        <div className={styles.landscapeModeContent}>
          <ErrorOutlineIcon sx={{ color: '#ffffff' }} />
          This call is only available in portrait mode. Please turn your phone vertically.
        </div>
      </div>
    </>
  );
};
