import { useWebsocketHelper } from 'src/wsHelper';
import { PolygonPoint } from '@provider-types/reducer';
import { useCallback } from 'react';
// eslint-disable-next-line max-len
import { SmartRailsSensitivityLevel } from '../../components/SidePanel/content/SmartRailsContent/SmartRailsContent';
import { EWebsocketEvent } from '@provider-types/enums';

export enum wsCommandMode {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  HOME = 'home',
  REQUEST_STATE = 'requestState',
  STORE_HOME_POSITION = 'storeHomePosition',
  SMART_RAILS_HOME = 'virtualBedRailsHome'
}

export enum wsFileAction {
  PLAY_AUDIO = 'play'
}

export enum AlarmType {
  TRIGGERED_ALERT = 'providerTriggeredAlert'
}

type AlarmWSMessageParams = {
  alarmType?: AlarmType;
  enabled: boolean;
};

export type WsFileActionsParams = {
  actionType: wsFileAction;
  fileActionId: string;
  fileUri: string;
  data?: object;
};

type DeviceBlurModeWSMessageParams = {
  enabled: boolean;
};

export interface UseEsitterWSMessages {
  sendDeviceFeccStateWSMessage: (commandMode: wsCommandMode, data: object) => void;
  sendDeviceAlarmStateWSMessage: (params: AlarmWSMessageParams) => void;
  sendUpdateSmartRailsEnabledWSMessage: (
    cameraUuid: string,
    enable: boolean,
    sensitivityLevel?: SmartRailsSensitivityLevel,
    polygon?: PolygonPoint[]
  ) => void;
  sendFileActionWSMessage: (params: WsFileActionsParams) => void;
  sendDeviceBlurModeStateWSMessage: (params: DeviceBlurModeWSMessageParams) => void;
}

export const useEsitterWSMessages = (deviceId: string, callId: string): UseEsitterWSMessages => {
  const { sendWsMessage } = useWebsocketHelper();

  const sendDeviceFeccStateWSMessage = useCallback(
    (commandMode: wsCommandMode, data = {}): void =>
      sendWsMessage({
        event: 'deviceFeccState',
        recipientType: 'device',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          commandMode: commandMode,
          ...data
        }
      }),
    [callId, deviceId, sendWsMessage]
  );

  const sendUpdateSmartRailsEnabledWSMessage = useCallback(
    (
      cameraUuid: string,
      enable: boolean,
      sensitivityLevel?: SmartRailsSensitivityLevel,
      polygon?: PolygonPoint[]
    ): void => {
      const messageObj = {
        event: 'deviceVirtualBedRailsState',
        recipientType: 'device',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          cameraUuid: cameraUuid,
          enabled: enable,
          sensitivityLevel: sensitivityLevel || null,
          polygon: polygon || null
        }
      };
      return sendWsMessage(messageObj);
    },
    [callId, deviceId, sendWsMessage]
  );

  const sendFileActionWSMessage = useCallback(
    (params: WsFileActionsParams) => {
      const messageObj = {
        event: EWebsocketEvent.DEVICE_FILE_ACTION,
        recipientType: 'device',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          ...params
        }
      };
      return sendWsMessage(messageObj);
    },
    [callId, deviceId, sendWsMessage]
  );

  const sendDeviceAlarmStateWSMessage = useCallback(
    ({ alarmType = AlarmType.TRIGGERED_ALERT, enabled }: AlarmWSMessageParams): void =>
      sendWsMessage({
        event: 'deviceAlarmState',
        recipientType: 'device',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          alarmType,
          enabled
        }
      }),
    [callId, deviceId, sendWsMessage]
  );

  const sendDeviceBlurModeStateWSMessage = useCallback(
    ({ enabled }: DeviceBlurModeWSMessageParams) => {
      sendWsMessage({
        event: EWebsocketEvent.DEVICE_VIDEO_FILTER,
        recipientType: 'device',
        recipientUuid: deviceId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          filterName: 'sobel',
          enabled: enabled
        }
      });
    },
    [callId, deviceId, sendWsMessage]
  );

  return {
    sendDeviceAlarmStateWSMessage,
    sendDeviceFeccStateWSMessage,
    sendUpdateSmartRailsEnabledWSMessage: sendUpdateSmartRailsEnabledWSMessage,
    sendFileActionWSMessage,
    sendDeviceBlurModeStateWSMessage
  };
};
