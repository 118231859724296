import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceRoomInfo } from '@provider-types/provider';
import Tooltip from '@mui/material/Tooltip';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { makeThemedStyles } from '@provider-hooks';
import { selectHoveredDeviceId } from '@provider-selectors/esitter';
import { setHoveredDevice } from '@provider-reducers/esitterSlice';

interface DeviceListItemProps {
  device: IDeviceRoomInfo;
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement>, device: IDeviceRoomInfo) => void;
  onDeviceClick: (device: IDeviceRoomInfo) => void;
}
export const MyPatientsDeviceListItem = (props: DeviceListItemProps): JSX.Element => {
  const { device, onDeviceClick, onMenuClick } = props;
  const dispatch = useDispatch();
  const hoveredDeviceId = useSelector(selectHoveredDeviceId);

  const handleDeviceClick = (): void => {
    onDeviceClick(device);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onMenuClick(event, device);
  };

  const handleMouseOver = useCallback(() => {
    if (device) {
      dispatch(setHoveredDevice(device.deviceId));
    }
  }, [device, dispatch]);

  const handleMouseLeave = useCallback(() => {
    if (device) {
      dispatch(setHoveredDevice(''));
    }
  }, [device, dispatch]);

  const { styles } = useStyles();

  return (
    <Tooltip key={device.deviceId} title={device.deviceName || device.deviceId}>
      <ListItem
        disablePadding
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        sx={hoveredDeviceId === device.deviceId ? styles.hovered : undefined}
        secondaryAction={
          <IconButton
            edge='end'
            sx={styles.menuIcon}
            aria-label='more'
            id='long-button'
            // aria-controls={open ? 'long-menu' : undefined}
            // aria-expanded={open ? 'true' : undefined}
            aria-haspopup='true'
            component='span'
            onClick={handleMenuClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        data-testid='deviceListItem'
      >
        <ListItemButton onClick={handleDeviceClick} sx={styles.button}>
          <ListItemIcon sx={{ minWidth: '32px' }}>
            <VideocamOutlinedIcon sx={styles.icon} />
          </ListItemIcon>
          <ListItemText
            primary={device.deviceName || device.deviceId}
            sx={styles.listItemText}
            data-testid={`device-item-${device.deviceId}`}
          />
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const useStyles = makeThemedStyles()(() => ({
  listItemText: {
    wordWrap: 'break-word'
  },
  button: {
    justifyContent: 'start',
    pr: 8,
    '&:hover': {
      backgroundColor: '#313A42',
      cursor: 'pointer'
    }
  },
  hovered: {
    backgroundColor: '#313A42'
  },
  icon: {
    fontSize: '24px',
    backgroundColor: '#1A1F24',
    borderRadius: '50%',
    color: '#15DD4A',
    padding: '3px'
  },
  menuIcon: {
    color: '#BEC3CC',
    backgroundColor: 'transparent',
    padding: '0px'
  }
}));
