import { createSelector } from 'reselect';
import { ELogEvent } from '@provider-types/enums';
import { LogState } from '@provider-reducers/logSlice';
import { RootState } from '@provider-reducers/root';

type LogFilter = {
  page?: number;
  typeFilter?: ELogEvent[];
  deviceId?: string;
  itemsPerPage?: number;
};

export const getLogState = (state: RootState): LogState => state.log;

export const selectEsitterLogs = createSelector([getLogState], log => log.esitterLog);

export const selectEsitterLogsByFilter = createSelector(
  [getLogState, (log, filter: LogFilter) => filter],
  (log, filter = {}) => {
    const { typeFilter = [], deviceId, page = 1, itemsPerPage } = filter;

    const filteredLogs = log.esitterLog.filter(
      el => el.deviceId === deviceId && (typeFilter.length ? typeFilter.includes(el.type) : true)
    );
    const slicedLogs = filteredLogs.slice(0, itemsPerPage ? page * itemsPerPage : filteredLogs.length);

    return {
      logs: slicedLogs,
      pageInfo: {
        hasMore: slicedLogs.length < filteredLogs.length
      }
    };
  }
);
