import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import { makeThemedStyles } from '@provider-hooks';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

interface CustomAvatarProps {
  thumbnailUrl?: string;
  initials: string;
  color?: string;
  isDevice?: boolean;
}

const ParticipantAvatar: React.FC<CustomAvatarProps> = props => {
  const { thumbnailUrl, initials, color, isDevice } = props;
  const [showThumbnail, setShowThumbnail] = useState(true);
  const { styles } = useStyles({ isDevice, color });

  const handleImageError = () => {
    setShowThumbnail(false);
  };

  const content = !isDevice ? (
    <Typography variant={'body1'} fontWeight={600} textTransform={'uppercase'}>
      {initials}
    </Typography>
  ) : (
    <PersonIcon sx={{ color: '#ffffff', width: '16px', height: '16px' }} data-testid='person-icon' />
  );

  return (
    <Avatar sx={styles.avatar} data-testid='avatar-div'>
      {showThumbnail && thumbnailUrl ? (
        <Box
          component={'img'}
          src={thumbnailUrl}
          alt='thumbnail'
          onError={handleImageError}
          sx={{
            width: '32px',
            height: '32px',
            borderRadius: '16px'
          }}
        />
      ) : (
        content
      )}
    </Avatar>
  );
};

export default ParticipantAvatar;

type StyleProps = {
  isDevice?: boolean;
  color?: string;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { isDevice, color }) => ({
  image: {},
  avatar: {
    width: '32px',
    height: '32px',
    borderRadius: '16px',
    backgroundColor: isDevice ? '#1774CC' : `#${color || 'FF787A'}`
  }
}));
