import React from 'react';
import styled from '@emotion/styled';
import spinner from '@provider-features/embed/styles/Spinner.module.css';

const Container = styled.div(() => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#1a1f24'
}));

export const FallbackPage = (): JSX.Element => (
  <Container>
    <span className={spinner.spinner3}> </span>
  </Container>
);
