import List from '@mui/material/List';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IDeviceRoomInfo } from '@provider-types/provider';
import { setSelectedDevice } from '@provider-reducers/esitterSlice';
import { MyPatientsDeviceListItem } from './MyPatientsDeviceListItem';
import { selectRoomsArray } from '@provider-selectors/esitter';

interface DevicesListProps {
  onMenuClick: (event: React.MouseEvent<HTMLButtonElement>, device: IDeviceRoomInfo) => void;
}

export const MyPatientsDevicesList = (props: DevicesListProps): JSX.Element => {
  const { onMenuClick } = props;
  const roomsArray = useSelector(selectRoomsArray);
  const dispatch = useDispatch();

  const onDeviceClick = (device: IDeviceRoomInfo) => {
    // WIP TODO Davide: please leave for now.
    // console.log('clicked on device: ', device.deviceName);
    dispatch(setSelectedDevice(device));
  };

  return (
    <List sx={{ width: '100%' }} data-testid={'deviceList'}>
      {roomsArray.map(device => (
        <MyPatientsDeviceListItem
          key={device.deviceId}
          device={device}
          onMenuClick={onMenuClick}
          onDeviceClick={onDeviceClick}
        />
      ))}
    </List>
  );
};
