import { AddDevice, Call, IAddDeviceToEsitter } from '@provider-types/provider';
import { BackendError, isBackendError } from '@provider-errors/BackendError';
import api from '@provider-services/api/api';

export async function addDevice(callId: string, deviceUuid: string): Promise<IAddDeviceToEsitter | BackendError> {
  try {
    const res = await api.post<IAddDeviceToEsitter>(`/api/v2/calls/${callId}/devices/${deviceUuid}`, {
      body: { mode: 'observation_v' }
    });

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function removeDevice(callId: string, deviceId: string): Promise<string | BackendError> {
  try {
    const res = await api.delete<string>(`/api/v2/calls/${callId}/devices/${deviceId}`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function changeMode(
  callId: string,
  deviceId: string,
  data: { mode: string; forceMode: boolean; skipPatientPrompt?: boolean }
): Promise<string | BackendError> {
  try {
    const res = await api.patch<string>(`/api/v2/calls/${callId}/devices/${deviceId}`, { body: data });

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function stopCall(callId: string, keepalive?: boolean): Promise<string | BackendError> {
  try {
    const res = await api.delete<string>(`/api/v2/calls/${callId}`, undefined, keepalive ? { keepalive } : undefined);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function removeUser(callId: string, userId: string): Promise<string | BackendError> {
  try {
    const res = await api.delete<string>(`/api/v2/calls/${callId}/users/${userId}`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function makeCallActive(callId: string): Promise<Call | BackendError> {
  try {
    const res = await api.patch<Call>(`/api/v2/calls/${callId}`, { body: { status: 'active' } });

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

export async function getCallableDevicesList(data: { callId: string }): Promise<AddDevice[] | BackendError> {
  try {
    const res = await api.get<AddDevice[]>(`/api/v2/calls/${data.callId}/callableDevices`);

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}

/*** MAKE A HOST ***/
export async function makeParticipantHost(callId: string, uuid: string): Promise<string | BackendError> {
  console.log('Assigning Host status to participant: ', uuid);
  try {
    const res = await api.patch<string>(`/api/v2/calls/${callId}/users/${uuid}`, { body: { host: 'true' } });

    console.log('Participant ', uuid, ' was successfully made Host');

    return res.body;
  } catch (error) {
    if (isBackendError(error)) {
      console.log(`Error: ${error.message}`);
    }

    return error as BackendError;
  }
}
