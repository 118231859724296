import React, { useCallback, useState } from 'react';
import { ButtonControl } from '@provider-components';
import { EButtonControlColor, EButtonControlSize } from '@provider-types/enums';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MediaSelect } from '@provider-components';
import { useMediaDevices } from '@provider-hooks';

export const SettingsButton = React.memo((): React.ReactElement => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const isSettingsOpen = Boolean(anchorEl);

  // Don't trigger automatic device list enumeration
  const { updateDeviceList } = useMediaDevices({ shouldEnumerateDevices: false, shouldUpdateCameraList: false });

  const onSettingsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      updateDeviceList();
      setAnchorEl(event.currentTarget);
    },
    [updateDeviceList]
  );

  const handleOnClose = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <MediaSelect refer={'sitter'} anchorEl={anchorEl} hideSelectCamera onClose={handleOnClose} />
      <ButtonControl
        size={EButtonControlSize.MEDIUM}
        backgroundColor={isSettingsOpen ? '#1774CC' : '#313A42'}
        color={isSettingsOpen ? EButtonControlColor.BLUE : EButtonControlColor.GRAY}
        onClick={onSettingsClick}
        data-testid='settingsBtn'
      >
        <MoreVertIcon />
      </ButtonControl>
    </>
  );
});
