import React from 'react';
import { useTranslation } from 'react-i18next';

const ErrorBoundaryFallback: React.FC<EmptyProps> = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h2>{t('errorBoundaryFallback.title')}</h2>
      <p>{t('errorBoundaryFallback.description')}</p>
    </div>
  );
};

export default ErrorBoundaryFallback;
