import React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import { AddPatientListItem } from './AddPatientListItem';

export enum DeviceAvailability {
  ONLINE = 'online',
  OFFLINE = 'offline',
  BUSY = 'busy'
}

export interface AddPatientDeviceListItemProps {
  label: string;
  description?: string;
  deviceUuid: string;
  onDeviceClick: (deviceUuid: string) => void;
  availability: DeviceAvailability;
}
export const AddPatientDeviceListItem = React.memo((props: AddPatientDeviceListItemProps): React.ReactElement => {
  const { label, description, deviceUuid, onDeviceClick, availability } = props;

  const handleDeviceClick = (): void => {
    if (![DeviceAvailability.BUSY, DeviceAvailability.OFFLINE].includes(availability)) {
      onDeviceClick(deviceUuid);
    }
  };

  const { styles } = useStyles({ availability });

  return (
    <AddPatientListItem
      dataTestId={`room-${availability}`}
      label={label}
      description={description}
      showCircleIcon={true}
      onItemClick={handleDeviceClick}
      arrowIconSx={styles.arrowIcon}
      circleIconSx={styles.circleIcon}
      labelSx={styles.label}
    />
  );
});

type StyleProps = {
  availability: DeviceAvailability;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { availability }) => ({
  label: {
    color: [DeviceAvailability.OFFLINE, DeviceAvailability.BUSY].includes(availability) ? '#5B6B7B' : '#ffffff'
  },
  arrowIcon: {
    color: [DeviceAvailability.OFFLINE, DeviceAvailability.BUSY].includes(availability) ? '#5B6B7B' : '#ffffff'
  },
  circleIcon: {
    color:
      availability === DeviceAvailability.ONLINE
        ? '#39D627'
        : availability === DeviceAvailability.BUSY
        ? '#EF9C23'
        : '#D93B3D'
  }
}));
