import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Device } from '@provider-types/devices';

export interface DevicesInitialState {
  approvedDevices: ListResponse<Device[]>;
  pendingDevices: ListResponse<Device[]>;
}

export const initialState: DevicesInitialState = {
  approvedDevices: {
    rows: [],
    count: 0,
    page: 0
  },
  pendingDevices: {
    rows: [],
    count: 0,
    page: 0
  }
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    addApprovedDevice: (state, action: PayloadAction<ListResponse<Device[]>>) => {
      state.approvedDevices = { ...action.payload };
    },
    addPendingDevice: (state, action: PayloadAction<ListResponse<Device[]>>) => {
      state.pendingDevices = { ...action.payload };
    },
    removeDevice: state => {
      state.approvedDevices = initialState.approvedDevices;
      state.pendingDevices = initialState.pendingDevices;
    }
  }
});

export const { addApprovedDevice, addPendingDevice, removeDevice } = devicesSlice.actions;

export default devicesSlice.reducer;
