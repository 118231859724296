import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@provider-reducers/root';
import { AppInitialState } from '@provider-types/reducer';

export const selectApp = (state: RootState): AppInitialState => state.app;

export const selectMediaAccessModalVisible = createSelector([selectApp], app => app.mediaAccessModalVisible);

export const selectMediaAccessModalData = createSelector([selectApp], app => app.mediaAccessModalData);
export const selectIsMyCamActive = createSelector([selectApp], app => app.isMyCamActive);
export const selectCurrentUser = createSelector([selectApp], app => app.currentUser);
export const selectProviderAppVersion = createSelector([selectApp], app => app.providerAppVersion);
export const selectCmsVersion = createSelector([selectApp], app => app.cmsVersion);

export const selectIsCameraControlHovered = createSelector([selectApp], app => app.isCameraControlHovered);
export const selectIsAudioControlHovered = createSelector([selectApp], app => app.isAudioControlHovered);
export const selectCallType = createSelector([selectApp], app => app.callType);
