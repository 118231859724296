import * as React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FilledInputWithLabelField } from './FilledInputWithLabelField';

export interface PatientInfoPanelFormValues {
  name: string | null;
  reasonForMonitoring: string | null;
  language: string | null;
  location: string | null;
  primaryContact: string | null;
}
export interface PatientInfoPanelFormProps {
  initialValues: PatientInfoPanelFormValues;
  onSubmit: (values: PatientInfoPanelFormValues) => void;
  onCancel: () => void;
}

export const PatientInfoPanelFormSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  reasonForMonitoring: Yup.string().nullable(),
  language: Yup.string().nullable(),
  location: Yup.string().nullable(),
  primaryContact: Yup.string().nullable()
});

export const _PatientInfoPanelForm = (
  props: PatientInfoPanelFormProps & FormikProps<PatientInfoPanelFormValues>
): React.ReactElement => {
  const { onCancel, isSubmitting, values, isValid, dirty } = props;

  return (
    <Form>
      <Stack direction={'column'} spacing={4} data-testid='patientInfoPanelForm'>
        <FilledInputWithLabelField name={'name'} label='Patient name' value={values.name} />
        <FilledInputWithLabelField
          name={'reasonForMonitoring'}
          label='Reason for monitoring'
          value={values.reasonForMonitoring}
        />
        <FilledInputWithLabelField name={'language'} label='Language' value={values.language} />
        <FilledInputWithLabelField name={'location'} label='Location' value={values.location} />
        <FilledInputWithLabelField name={'primaryContact'} label='Primary Contact' value={values.primaryContact} />
        <Typography color={'textSecondary'}>These notes will not be saved beyond this session.</Typography>
        <Stack direction={'row'} justifyContent={'start'} spacing={4}>
          <Button disabled={!isValid || isSubmitting || !dirty} variant={'contained'} type={'submit'}>
            Save
          </Button>
          <Button variant={'contained'} color={'secondary'} onClick={onCancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
};

export const PatientInfoPanelForm = withFormik<PatientInfoPanelFormProps, PatientInfoPanelFormValues>({
  mapPropsToValues: props => props.initialValues,
  validationSchema: PatientInfoPanelFormSchema,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  enableReinitialize: true,
  displayName: 'PatientInfoPanelForm'
})(_PatientInfoPanelForm);
