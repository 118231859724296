import { useEffect, useState } from 'react';
import {
  LocalTrack,
  LocalAudioTrack,
  RemoteAudioTrackPublication,
  LocalVideoTrack,
  RemoteVideoTrackPublication,
  Participant,
  VideoTrack,
  AudioTrack
} from 'twilio-video';

export function useParticipantMedia(twilioParticipant?: Participant): {
  isScreenShareAvailable: boolean;
  videoTrack: VideoTrack | null;
  isVideoAvailable: boolean;
  audioTrack: AudioTrack | null;
  isAudioAvailable: boolean;
  screenShareTrack: VideoTrack | null;
} {
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [isScreenShareAvailable, setIsScreenShareAvailable] = useState(false);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);
  const [screenShareTrack, setScreenShareTrack] = useState<VideoTrack | null>(null);
  const [videoTrack, setVideoTrack] = useState<VideoTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<AudioTrack | null>(null);

  // when the participant video or audio tracks change, update the state returned by this hook
  // We use this instead of the built in `useTracks` because `useTracks` did not appear
  // to update state correctly in complex scenarios (track permissions removed, then added again)
  // during a call. Instead, we listen directly to the relevant participant events, which appears to
  // work reliably.
  useEffect(() => {
    const updateCamActiveForTrack = (trackOrTrackPub: LocalVideoTrack | RemoteVideoTrackPublication) => {
      let track;

      // remote tracks are passed in as trackpub events, and local events are just tracks.
      // detect the 'remote' case here...
      if ('track' in trackOrTrackPub) {
        track = trackOrTrackPub.track;
      } else {
        track = trackOrTrackPub as LocalVideoTrack;
      }

      if (!track) {
        return;
      }

      let newState = false;

      if (!track.isEnabled) {
        newState = false;
      }
      // local
      else if ('isStopped' in track && !track.isStopped) {
        newState = true;
      }

      // remote track is nested doubly...
      else newState = 'isSwitchedOff' in track && !track.isSwitchedOff;

      if (track.name === 'screenshare') {
        setScreenShareTrack(track);
        setIsScreenShareAvailable(newState);
      } else {
        setVideoTrack(track);
        setIsVideoAvailable(newState);
      }
    };

    const updateMicActiveForTrack = (trackOrTrackPub: LocalAudioTrack | RemoteAudioTrackPublication) => {
      let track;

      // remote tracks are passed in as trackpub events, and local events are just tracks.
      // detect the 'remote' case here...
      if ('track' in trackOrTrackPub) {
        track = trackOrTrackPub.track;
      } else {
        track = trackOrTrackPub;
      }

      // ignore missing tracks
      if (!track) {
        return;
      }

      setAudioTrack(track);

      if (!track.isEnabled) {
        setIsAudioAvailable(false);
      }
      // local
      else if ('isStopped' in track && !track.isStopped) {
        setIsAudioAvailable(true);
      }

      // remote
      else if ('isSwitchedOff' in track && !track.isSwitchedOff) {
        setIsAudioAvailable(true);
      } else {
        setIsAudioAvailable(false);
      }
    };

    const trackEventHandler = (
      track: LocalAudioTrack | RemoteAudioTrackPublication | LocalVideoTrack | RemoteVideoTrackPublication
    ) => {
      if (track?.kind === 'audio') {
        updateMicActiveForTrack(track);
      } else if (track?.kind === 'video') {
        updateCamActiveForTrack(track);
      }
    };

    const remoteParticipantRemovalHandler = (trackPub: RemoteAudioTrackPublication | RemoteVideoTrackPublication) => {
      if (trackPub?.kind === 'video') {
        if (trackPub?.trackName === 'screenshare') {
          setIsScreenShareAvailable(false);
        } else {
          setIsVideoAvailable(false);
        }
      } else if (trackPub?.kind === 'audio') {
        setIsAudioAvailable(false);
      }
    };

    const localParticipantRemovalHandler = (track: LocalTrack) => {
      if (track?.kind === 'video') {
        if (track?.name === 'screenshare') {
          setIsScreenShareAvailable(false);
        } else {
          setIsVideoAvailable(false);
        }
      } else if (track?.kind === 'audio') {
        setIsAudioAvailable(false);
      }
    };

    if (twilioParticipant && (twilioParticipant.audioTracks || twilioParticipant.videoTracks)) {
      // initial state set
      for (const [, trackPub] of twilioParticipant.audioTracks) {
        trackEventHandler(trackPub.track as LocalAudioTrack | RemoteAudioTrackPublication);
      }
      for (const [, trackPub] of twilioParticipant.videoTracks) {
        trackEventHandler(trackPub.track as LocalVideoTrack | RemoteVideoTrackPublication);
      }

      twilioParticipant.on('trackEnabled', trackEventHandler);
      twilioParticipant.on('trackStarted', trackEventHandler);
      twilioParticipant.on('trackStopped', localParticipantRemovalHandler);
      twilioParticipant.on('trackUnpublished', remoteParticipantRemovalHandler);
      twilioParticipant.on('trackDisabled', trackEventHandler);
      twilioParticipant.on('trackRemoved', trackEventHandler);
      return () => {
        twilioParticipant.removeListener('trackEnabled', trackEventHandler);
        twilioParticipant.removeListener('trackStarted', trackEventHandler);
        twilioParticipant.removeListener('trackStopped', localParticipantRemovalHandler);
        twilioParticipant.removeListener('trackUnpublished', remoteParticipantRemovalHandler);
        twilioParticipant.removeListener('trackDisabled', trackEventHandler);
        twilioParticipant.removeListener('trackRemoved', trackEventHandler);
      };
    }
  }, [twilioParticipant]);

  // Translate the local state in this hook into global state if we are monitoring the local participant
  // IT WAS COMMENTED TO CHECK IF IT REALLY EFFECTS ON SOMETHING
  // REMOVE IT MEDIA PERMISSIONS WORKS WELL FOR A LONG TIME
  // IF NOT TEST IT AND UPDATE BECAUSE UNCOMMENTING WILL BREAK THE LOGIC ON START
  // useEffect(() => {
  //   if (!isLocalParticipant) {
  //     return;
  //   }

  //   if (isVideoAvailable) {
  //     dispatch({ type: 'SET_MY_CAM_IS_ACTIVE' });
  //   } else {
  //     dispatch({ type: 'RESET_MY_CAM_IS_ACTIVE' });
  //   }
  // }, [dispatch, isLocalParticipant, isVideoAvailable]);

  // useEffect(() => {
  //   if (!isLocalParticipant) {
  //     return;
  //   }

  //   if (isAudioAvailable) {
  //     dispatch({ type: 'SET_MY_MIC_IS_ACTIVE' });
  //   } else {
  //     dispatch({ type: 'RESET_MY_MIC_IS_ACTIVE' });
  //   }
  // }, [dispatch, isLocalParticipant, isAudioAvailable]);

  return { isScreenShareAvailable, isAudioAvailable, isVideoAvailable, screenShareTrack, audioTrack, videoTrack };
}
