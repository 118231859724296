import React from 'react';
import Typography from '@mui/material/Typography';
import { makeThemedStyles } from '@provider-hooks';
import Box from '@mui/material/Box';

export const NoPatientsMessage = React.memo((): React.ReactElement => {
  const { styles } = useStyles();
  return (
    <Box sx={styles.container}>
      <Typography>You are currently not monitoring any patients.</Typography>
    </Box>
  );
});

const useStyles = makeThemedStyles()(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#313A42',
    padding: '16px 8px 16px 16px',
    margin: '8px'
  }
}));
