import { useCallback } from 'react';
import { useWebsocketHelper } from 'src/wsHelper';

interface UseEsitterCallWSMessages {
  sendDeviceDebugState: (enabled: boolean) => void;
}

export const useEsitterCallWSMessages = (callId: string): UseEsitterCallWSMessages => {
  const { sendWsMessage } = useWebsocketHelper();
  const sendDeviceDebugState = useCallback(
    (enabled: boolean) => {
      const messageObj = {
        event: 'deviceDebugState',
        recipientType: 'call',
        recipientUuid: callId,
        callUuid: callId,
        data: {
          stateType: 'desired',
          enabled: enabled
        }
      };
      return sendWsMessage(messageObj);
    },
    [callId, sendWsMessage]
  );

  return {
    sendDeviceDebugState
  };
};
