import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectIsMicActive as selectEsitterRoomMicActive, getSelectedDeviceId } from '@provider-selectors/esitter';
import { selectIsMyMicActive as selectVRMicActive } from '@provider-selectors/vrcall';
import { selectCallType } from '@provider-selectors/app';
import { setIsMicActive as setEsitterMicActive } from '@provider-reducers/esitterSlice';
import { ECallType } from '@provider-types/enums';

// TODO: use hook instead of seletors in context of Reduce Selectors Amount task

export const useMicrophone = (
  deviceId?: string
): { setIsMicActive: (isMicActive: boolean) => void; isMicActive: boolean } => {
  const dispatch = useDispatch();

  const isVRMicActive = useSelector(selectVRMicActive);
  const isEsitterRoomMicActive = useSelector(state => selectEsitterRoomMicActive(state, deviceId));

  const callType = useSelector(selectCallType);
  const esitterSelectedDeviceId = useSelector(getSelectedDeviceId);

  const setIsMicActive = useCallback(
    (isMicActive: boolean) => {
      if (callType === ECallType.ESITTER) {
        dispatch(setEsitterMicActive({ deviceId: deviceId ?? esitterSelectedDeviceId, isMicActive }));
      } else {
        dispatch({
          type: 'SET_IS_MY_MIC_ACTIVE',
          payload: isMicActive
        });
      }
    },
    [callType, esitterSelectedDeviceId, deviceId, dispatch]
  );

  const isMicActive = callType === ECallType.ESITTER ? isEsitterRoomMicActive : isVRMicActive;

  return {
    setIsMicActive,
    isMicActive
  };
};
