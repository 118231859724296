import { setDeviceBlurModeStatus } from '@provider-reducers/esitterSlice';
import { selectBlurModeStatus } from '@provider-selectors/esitter';
import { BlurModeStatus } from '@provider-types/enums';
import { UseBlurMode } from '@provider-types/blurMode';
import { useBlurMode } from '../useBlurMode/useBlurMode';

import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export function useEsitterBlurMode(deviceId: string, callId: string): UseBlurMode {
  const status = useSelector(state => selectBlurModeStatus(state, deviceId));

  const dispatch = useDispatch();

  const {
    isDisabled,
    isEnabled,
    isButtonDisabled,
    enableBlurMode: enable,
    disableBlurMode: disable
  } = useBlurMode(deviceId, callId, status);

  const enableBlurMode = useCallback(() => {
    enable(() => {
      dispatch(setDeviceBlurModeStatus({ id: deviceId, status: BlurModeStatus.ENABLED }));
    });
  }, [enable, dispatch, deviceId]);

  const disableBlurMode = useCallback(() => {
    disable(() => {
      dispatch(setDeviceBlurModeStatus({ id: deviceId, status: BlurModeStatus.DISABLED }));
    });
  }, [deviceId, disable, dispatch]);

  const toggleBlurMode = useCallback(() => {
    if (isEnabled) {
      disableBlurMode();
    } else if (isDisabled) {
      enableBlurMode();
    }
  }, [isEnabled, isDisabled, disableBlurMode, enableBlurMode]);

  return {
    isEnabled,
    isDisabled,
    isButtonDisabled,
    enableBlurMode,
    disableBlurMode,
    toggleBlurMode
  };
}
