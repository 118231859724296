import React, { useEffect } from 'react';
import CallEndIcon from '@mui/icons-material/CallEnd';
import styles from '../../styles/CallEnded.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@mui/material';
import { RootState } from '@provider-reducers/root';
import { useNavigate } from 'react-router-dom';
import { DeviceRouteNames } from '@provider-features/devices/router';
import { setRedirectDisabled, resetEsitterState } from '@provider-reducers/esitterSlice';
import DOMPurify from 'dompurify';
import { resetUIState } from '@provider-reducers/uiState';
import { resetSmartRailsState } from '@provider-reducers/smartRailsSlice';
import { resetLog } from '@provider-reducers/logSlice';

const CallEnded: React.FC<EmptyProps> = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { didVisitDeviceTable } = useSelector((state: RootState) => state.settings);

  const { endCallScreenIcon, endCallScreenMessage, endCallScreenParticipant } = useSelector(
    (state: RootState) => state.vrcall
  );

  useEffect(() => {
    dispatch({ type: 'CLEAR_WEBSOCKETS' });
    dispatch(resetEsitterState());
    dispatch(resetUIState());
    dispatch(resetSmartRailsState());
    dispatch(resetLog());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setRedirectDisabled(false));

    if (!endCallScreenParticipant) {
      setTimeout(() => {
        if (didVisitDeviceTable) {
          navigate(DeviceRouteNames.AdminDevices);
          dispatch({
            type: 'SET_STATE_BACK_TO_DEFAULT'
          });
        }

        window.parent.postMessage(
          JSON.stringify({
            event: 'call_ended'
            //callType: 'esitter'
            // TODO: might need to be re-added later (maybe)
            // in that case it needs to be added to a common VR+eSitter Redux
          }),
          '*'
        );

        const shouldReload = localStorage.getItem('@should-reload');

        if (shouldReload && JSON.parse(shouldReload) === true) {
          localStorage.setItem('@should-reload', JSON.stringify(false));

          window.location.reload();
        }
      }, 5000);
    }
  }, [didVisitDeviceTable, endCallScreenParticipant, dispatch, navigate]);

  return (
    <div className={styles.container}>
      {endCallScreenIcon ? (
        typeof endCallScreenIcon === 'string' ? (
          <img alt='End call icon' src={endCallScreenIcon} />
        ) : (
          <Icon sx={{ color: '#ffffff' }}>{endCallScreenIcon}</Icon>
        )
      ) : (
        <CallEndIcon sx={{ color: '#ffffff' }} />
      )}
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(endCallScreenMessage)
        }}
      />
    </div>
  );
};

export default CallEnded;
