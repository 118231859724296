import { SxProps, Theme } from '@mui/material';
import type { StyleProps } from '@provider-hooks';

type MergeSxArgument = SxProps<Theme> | undefined;

export const mergeSx = (...sxStyles: MergeSxArgument[]): MergeSxArgument => {
  return sxStyles.reduce((prev, current) => {
    if (Array.isArray(prev)) {
      return prev.concat(current);
    }
    return [prev].concat(current);
  }, []);
};

export const utilityStyleKeys = {
  children: '> :not([hidden])',
  afterFirstChild: '> :not([hidden]) ~ :not([hidden])',
  scrollbarThumb: '::-webkit-scrollbar-thumb',
  scrollbarTrack: '::-webkit-scrollbar-track'
};

export const styledScrollbar = (): StyleProps => {
  const scrollbarThumbColor = '#5B6B7B';
  const scrollbarTrackColor = 'transparent';
  const { scrollbarThumb, scrollbarTrack } = utilityStyleKeys;

  return {
    scrollbarWidth: 'auto',
    scrollbarColor: `${scrollbarThumbColor} ${scrollbarTrackColor}`,
    '::-webkit-scrollbar': {
      width: 16
    },
    [`${scrollbarThumb}, ${scrollbarTrack}`]: {
      border: '6px solid transparent',
      borderRadius: 8,
      backgroundClip: 'padding-box'
    },
    [scrollbarThumb]: {
      backgroundColor: scrollbarThumbColor
    },
    [scrollbarTrack]: {
      backgroundColor: scrollbarTrackColor
    }
  };
};
export const invisibleScrollbar = (): StyleProps => {
  return {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      width: '0.1em',
      backgroundColor: 'transparent'
    },
    '::-webkit-scrollbar-thumb': {
      width: '0.1em',
      backgroundColor: 'transparent'
    }
  };
};
