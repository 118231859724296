import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NotificationIcon from '@mui/icons-material/NotificationImportant';
import { selectSelectedDeviceAlarm, selectDeviceById } from '@provider-selectors/esitter';
import { setDeviceAlarm, handleChangeMode } from '@provider-reducers/esitterSlice';
import { LoadingWrapper } from '@provider-components';
import { useEsitterWSMessages } from '../../../../hooks';
import { TextButton } from '@provider-components';
import { AlarmStatus } from '@provider-types/enums';
import { useTranslation } from 'react-i18next';
import { ConnectionMode } from '@provider-types/enums';

export interface AlarmToggleButtonProps {
  deviceId: string;
  callId: string;
  isInteractiveMode?: boolean;
  disabled?: boolean;
}

export const AlarmToggleButton = ({
  isInteractiveMode = false,
  deviceId,
  callId,
  disabled = false
}: AlarmToggleButtonProps): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { sendDeviceAlarmStateWSMessage } = useEsitterWSMessages(deviceId, callId);

  const alarmStatus = useSelector(state => selectSelectedDeviceAlarm(state, deviceId));
  const device = useSelector(state => selectDeviceById(state, deviceId));

  const turnOn = () => {
    if (isInteractiveMode) {
      dispatch(handleChangeMode(device, ConnectionMode.OBSERVATION));
    }
    dispatch(setDeviceAlarm({ id: deviceId, status: AlarmStatus.LOADING }));

    sendDeviceAlarmStateWSMessage({ enabled: true });
  };

  const turnOff = () => {
    sendDeviceAlarmStateWSMessage({ enabled: false });
    dispatch(setDeviceAlarm({ id: deviceId, status: AlarmStatus.LOADING }));
  };

  return (
    <LoadingWrapper loadingText='Loading...' isLoading={alarmStatus === AlarmStatus.LOADING}>
      {alarmStatus === AlarmStatus.ENABLED ? (
        <TextButton
          fullWidth
          disabled={disabled}
          title={t('alarmControl.off')}
          color='error'
          icon={NotificationIcon}
          onClick={turnOff}
        />
      ) : (
        <TextButton
          fullWidth
          title={t('alarmControl.on')}
          color={isInteractiveMode ? 'secondary' : 'success'}
          icon={NotificationIcon}
          onClick={turnOn}
          disabled={disabled}
        />
      )}
    </LoadingWrapper>
  );
};
