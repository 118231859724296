import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FilledInput } from '@mui/material';
import * as React from 'react';
import { makeThemedStyles } from '@provider-hooks';
import { FilledInputProps } from '@mui/material/FilledInput';

export type FilledInputWithLabelProps = FilledInputProps & {
  label: string;
};

export const FilledInputWithLabel = (props: FilledInputWithLabelProps): React.ReactElement => {
  const { name, label, ...otherProps } = props;
  const { styles } = useStyles();
  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'subtitle2'} color={'textSecondary'} component={'label'} htmlFor={name}>
        {label}
      </Typography>
      <FilledInput id={name} name={name} fullWidth={true} {...otherProps} sx={styles.textField} />
    </Stack>
  );
};

const useStyles = makeThemedStyles()(() => ({
  textField: {
    color: '#BEC3CC', // note 'textSecondary' does not work in this context.
    backgroundColor: '#313A42',
    boxShadow: '0px -1px 0px 0px #BEC3CC inset',
    '&:hover': {
      backgroundColor: '#313A42'
    },
    '&.Mui-focused': {
      backgroundColor: '#313A42'
    },
    '& .MuiFilledInput-input': {
      padding: '8px 16px'
    }
  }
}));
