import React, { useEffect } from 'react';
import { ETagColor, ETagColorName } from '@provider-types/enums';
import styles from './TagButton.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDeviceId } from '@provider-selectors/esitter';
import { setRoomTagColor } from '@provider-reducers/esitterSlice';

const DEFAULT_NONE_COLOR: ETagColor = ETagColor.NONE;

export interface TagButtonProps {
  color: ETagColor;
  activeColor: string | null;
  colorName?: string;
}

export const TagButton = ({ color, activeColor, colorName }: TagButtonProps): JSX.Element => {
  const active: boolean = color === activeColor;
  const contentStyle = styles.content + ' ' + (active ? styles.content_active : '');
  const noneStyle = styles.none + ' ' + (active ? styles.none_active : '');

  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!activeColor) {
      dispatch(
        setRoomTagColor({
          id: selectedDeviceId,
          tagColor: DEFAULT_NONE_COLOR
        })
      );
    }
  }, [activeColor, dispatch, selectedDeviceId]);

  const handleClick = () => {
    dispatch(
      setRoomTagColor({
        id: selectedDeviceId,
        tagColor: color
      })
    );
  };

  return (
    <div className={contentStyle} data-testid={`tagName${colorName}`} onClick={handleClick}>
      {colorName === ETagColorName.NONE ? (
        <div className={noneStyle}>None</div>
      ) : (
        <div style={{ backgroundColor: color }} className={styles.coloredLogo}>
          {colorName && (
            <span className={colorName === ETagColorName.YEL ? styles.dark_label : styles.light_label}>
              {colorName}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
