import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateMessageRoomLangGenderPair } from '@provider-reducers/esitterSlice';
import { selectAvailableMessagesLang, selectRoomMessageLang } from '@provider-selectors/esitter';

type Props = {
  deviceId: string;
  disabled?: boolean;
};

export const LanguageSelector = ({ deviceId, disabled = false }: Props): React.ReactElement | null => {
  const lang = useSelector(state => selectRoomMessageLang(state, deviceId));
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleMessageLangChange = (event: SelectChangeEvent) => {
    dispatch(updateMessageRoomLangGenderPair(deviceId, event.target.value));
  };

  const availableLangs = useSelector(selectAvailableMessagesLang);

  if (!availableLangs.length) return null;

  return (
    <Select
      id='prerecordedMessageLang'
      aria-label='Language'
      value={lang}
      onChange={handleMessageLangChange}
      sx={{
        fontSize: '12px',
        minWidth: '105px',
        '& .MuiSelect-select': {
          padding: '4px 8px'
        },
        '& fieldset': {
          border: ({ palette }) => `1px solid ${palette.grey['700']}`
        },
        '& .MuiSelect-icon': {
          color: ({ palette }) => palette.common.white,
          fontSize: '1rem'
        }
      }}
      MenuProps={{
        sx: {
          '& .MuiPaper-root': {
            backgroundColor: ({ palette }) => palette.background.default
          },
          '& .MuiMenuItem-root:hover': {
            backgroundColor: ({ palette }) => palette.background.secondary
          },
          '& .Mui-selected': {
            backgroundColor: ({ palette }) => palette.background.secondary
          }
        }
      }}
      disabled={disabled}
    >
      {availableLangs.map(item => (
        <MenuItem key={item} value={item}>
          {t(`langCode.${item}`)}
        </MenuItem>
      ))}
    </Select>
  );
};
