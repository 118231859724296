import * as React from 'react';
import { FormikProps } from 'formik';
import { useCallback } from 'react';

export interface UseFormHandlers<TFormValues> {
  editMode: boolean;
  editModeOn: () => void;
  editModeOff: () => void;
  handleKeyDown: (event: React.KeyboardEvent<any>) => void;
  fireSubmit: (form: FormikProps<TFormValues>) => void;
}

export const useFormHandlers = <TFormValues>(initialState = false): UseFormHandlers<TFormValues> => {
  const [editMode, setEditMode] = React.useState(initialState);

  const editModeOn = useCallback(() => {
    setEditMode(true);
  }, []);
  const editModeOff = useCallback(() => {
    setEditMode(false);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<any>) => {
    // escape button press
    if (event.keyCode === 27) {
      event.preventDefault();
      editModeOff();
    }
  };

  const fireSubmit = (form: FormikProps<TFormValues>) => {
    if (!form.isSubmitting) {
      form.submitForm();
    }
  };

  return { editModeOn, editModeOff, handleKeyDown, editMode, fireSubmit };
};
