import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IDeviceRoomInfo } from '@provider-types/provider';

export interface DeviceMenuProps {
  focusedRoom: string | undefined;
  onFocusClick: (device?: IDeviceRoomInfo) => void;
  onRemoveClick: (device: IDeviceRoomInfo) => void;
  onUnfocusClick: (device: IDeviceRoomInfo) => void;
}

interface DeviceMenuState {
  anchorEl: Element | null;
  device: IDeviceRoomInfo | null;
}

export class DeviceMenu extends React.PureComponent<DeviceMenuProps, DeviceMenuState> {
  state = {
    anchorEl: null,
    device: {} as IDeviceRoomInfo
  };

  setAnchorEl = (anchorEl: Element | null): void => {
    this.setState({ anchorEl });
  };

  setDevice = (device: IDeviceRoomInfo | null): void => {
    this.setState({ device });
  };

  handleMenuClose = (event: React.MouseEvent<HTMLLIElement>): void => {
    event.stopPropagation();
    event.preventDefault();
    this.setAnchorEl(null);
  };

  handleFocus = (event: React.MouseEvent<HTMLLIElement>): void => {
    this.handleMenuClose(event);
    this.props.onFocusClick(this.state.device);
  };

  handleUnFocus = (event: React.MouseEvent<HTMLLIElement>): void => {
    this.handleMenuClose(event);
    this.props.onUnfocusClick(this.state?.device);
  };

  handleRemove = (event: React.MouseEvent<HTMLLIElement>): void => {
    this.handleMenuClose(event);
    this.props.onRemoveClick(this.state.device);
  };

  render(): React.ReactElement {
    const isFocused = Boolean(this.props.focusedRoom === this.state.device?.deviceId);

    return (
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '200px',
            backgroundColor: '#232A30',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: '#FFFFFF'
          }
        }}
      >
        <MenuItem onClick={isFocused ? this.handleUnFocus : this.handleFocus}>
          <ListItemIcon>
            <RemoveRedEyeOutlinedIcon fontSize='small' style={{ color: '#FFFFFF' }} />
          </ListItemIcon>
          {isFocused ? 'Unfocus View' : 'Focus View'}
        </MenuItem>
        <MenuItem onClick={this.handleRemove}>
          <ListItemIcon>
            <DoNotDisturbOnOutlinedIcon fontSize='small' style={{ color: '#FFFFFF' }} />
          </ListItemIcon>
          Remove Patient
        </MenuItem>
      </Menu>
    );
  }
}
