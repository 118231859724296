import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';

export interface UIState {
  showSmartRailsPTZChangedWarning: boolean;
  showSmartRailsCameraChangedWarning: boolean;
  cameraUuid: string;
  isDebugModeOn: boolean;
  isDeviceGridResizing: boolean;
}

const initialState: UIState = {
  showSmartRailsPTZChangedWarning: false,
  showSmartRailsCameraChangedWarning: false,
  cameraUuid: '',
  isDebugModeOn: false,
  isDeviceGridResizing: false
};

interface WarningOnNotification {
  cameraUuid: string;
}

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    resetUIState: () => initialState,
    setShowSmartRailsPTZChangedWarningOn: (state: UIState, action: PayloadAction<WarningOnNotification>) => {
      const { cameraUuid } = action.payload;

      state.cameraUuid = cameraUuid;
      state.showSmartRailsPTZChangedWarning = true;
    },
    setShowSmartRailsPTZChangedWarningOff: state => {
      state.showSmartRailsPTZChangedWarning = false;
    },
    setShowSmartRailsCameraChangedWarningOn: (state: UIState, action: PayloadAction<WarningOnNotification>) => {
      const { cameraUuid } = action.payload;
      state.cameraUuid = cameraUuid;
      state.showSmartRailsCameraChangedWarning = true;
    },
    setShowSmartRailsCameraChangedWarningOff: (state: UIState) => {
      state.showSmartRailsCameraChangedWarning = false;
    },
    setDebugModeOn: (state: UIState, action: PayloadAction<boolean>) => {
      state.isDebugModeOn = action.payload;
    },
    setDeviceGridResizing: (state: UIState, action: PayloadAction<boolean>) => {
      state.isDeviceGridResizing = action.payload;
    }
  }
});

export const {
  setShowSmartRailsPTZChangedWarningOn,
  setShowSmartRailsPTZChangedWarningOff,
  setShowSmartRailsCameraChangedWarningOn,
  setShowSmartRailsCameraChangedWarningOff,
  setDebugModeOn,
  resetUIState,
  setDeviceGridResizing
} = uiStateSlice.actions;

export const selectNotificationCameraUuid = (state: RootState): string => state.uiState.cameraUuid;
export const selectShowSmartRailsPTZChangedWarning = (state: RootState): boolean =>
  state.uiState.showSmartRailsPTZChangedWarning;
export const selectShowSmartRailsCameraChangedWarning = (state: RootState): boolean =>
  state.uiState.showSmartRailsCameraChangedWarning;

export const selectIsDebugModeUIOn = (state: RootState): boolean => state.uiState.isDebugModeOn;
export const selectIsDeviceGridResizing = (state: RootState): boolean => state.uiState.isDeviceGridResizing;

export default uiStateSlice.reducer;
