import React from 'react';
import { circularProgressClasses, CircularProgressProps } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const LoadingCircularProgress = (props: CircularProgressProps): React.ReactElement => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: '#ffffff'
        }}
        size={24}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: '#000000',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={24}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
