import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSelectedCameraSmartRailsPolygon,
  isBoundariesSetUpForSelectedCamera,
  selectEnabled,
  selectIsSaved,
  selectSensitivityLevel
} from '@provider-selectors/smartRails';
import { RootState } from '@provider-reducers/root';
import { useEsitterWSMessages } from '../../../../hooks';
import {
  getCallId,
  getSelectedDeviceId,
  selectDeviceSelectedCameraUuid,
  selectIsDevicePaused
} from '@provider-selectors/esitter';
import {
  setBoundaryFirstTime,
  setBoundaryToDefault,
  setSaveDisabled,
  setSavingWithTimeout
} from '@provider-reducers/smartRailsSlice';
import { transformPointsToPolygon } from '../../../../functions';
import {
  setShowSmartRailsCameraChangedWarningOff,
  setShowSmartRailsPTZChangedWarningOff
} from '@provider-reducers/uiState';

interface SmartrailsSwitchProps {
  disabled: boolean;
}

export const SmartrailsSwitch = (props: SmartrailsSwitchProps): React.ReactElement => {
  const { disabled } = props;

  const dispatch = useDispatch();

  const callId = useSelector(getCallId);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedCameraUuid = useSelector(state => selectDeviceSelectedCameraUuid(state, selectedDeviceId));

  const boundaryEnabled = useSelector((state: RootState) => selectEnabled(state, selectedCameraUuid));
  const isBoundariesSetUp = useSelector(isBoundariesSetUpForSelectedCamera);
  const sensitivityLevel = useSelector((state: RootState) => selectSensitivityLevel(state, selectedCameraUuid));
  const points = useSelector(getSelectedCameraSmartRailsPolygon);
  const loaded = useSelector(selectIsSaved);
  const paused = useSelector(state => selectIsDevicePaused(state, selectedDeviceId));

  const [switchEnabled, setSwitchEnabled] = useState(boundaryEnabled);
  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);

  useEffect(() => {
    setSwitchEnabled(boundaryEnabled);
  }, [boundaryEnabled]);

  useEffect(() => {
    if (!isBoundariesSetUp && selectedCameraUuid && selectedDeviceId) {
      dispatch(setBoundaryToDefault({ cameraUuid: selectedCameraUuid, deviceId: selectedDeviceId }));
    }
  }, [dispatch, isBoundariesSetUp, selectedCameraUuid, selectedDeviceId]);

  const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = event.target.checked;
    setSwitchEnabled(enabled);

    const polygon = transformPointsToPolygon(points);
    sendUpdateSmartRailsEnabledWSMessage(selectedCameraUuid, enabled, sensitivityLevel, polygon);
    dispatch(setSavingWithTimeout(selectedCameraUuid));
    dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: true }));
    dispatch(setShowSmartRailsCameraChangedWarningOff());
    dispatch(setShowSmartRailsPTZChangedWarningOff());
  };

  useEffect(() => {
    if (loaded) {
      dispatch(setBoundaryFirstTime({ cameraUuid: selectedCameraUuid }));
    }
  }, [dispatch, loaded, selectedCameraUuid]);

  return (
    <FormControlLabel
      data-testid='alertSwitch'
      control={<Switch disabled={disabled || paused} checked={switchEnabled} onChange={handleEnabledChange} />}
      label={switchEnabled ? 'ON' : 'OFF'}
      color={'text.secondary'}
    />
  );
};
