import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@provider-reducers/root';
import { useTranslation } from 'react-i18next';
import { setAppMediaAccessModalData, setAppMediaAccessModalVisible } from '@provider-reducers/appSlice';

export type Props = {
  track: any;
  forcePlay?: boolean;
};

const AudioTrack = ({ track, forcePlay }: Props): React.ReactElement => {
  const ref = useRef<(HTMLAudioElement & { setSinkId?: (sinkId: string) => void; sinkId?: string }) | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // When audio output ID changes, change the audio sink on our audio embeds
  const { audioOutputId } = useSelector((state: RootState) => state.settings);

  const onPlayErrorCallback = useCallback(() => {
    const onInteractWithToast = () => {
      if (ref.current) {
        const playPromise = ref.current.play();

        if (playPromise !== undefined) {
          playPromise.catch(() => {
            console.error('Failed to play audio');
          });
        }
      }

      dispatch(setAppMediaAccessModalVisible(false));
    };

    dispatch(
      setAppMediaAccessModalData({
        title: t('errors.browserAudioPermissionTitle'),
        description: t('errors.browserAudioPermission'),
        primaryBtnName: t('global.confirm'),
        onPrimaryBtnClick: onInteractWithToast,
        onClose: onInteractWithToast
      })
    );

    dispatch(setAppMediaAccessModalVisible(true));
  }, [dispatch, t]);

  useEffect(() => {
    if (track && ref.current) {
      const el = ref.current;
      track.attach(el);

      return () => {
        track.detach(el);
      };
    }
  }, [track, ref, forcePlay, onPlayErrorCallback]);

  useEffect(() => {
    if (ref.current) {
      const el = ref.current;
      el.addEventListener('pause', () => {
        el.play();
      });
    }
  }, [ref]);

  useEffect(() => {
    const updateSinkId = async (newSinkId: string) => {
      try {
        if (ref.current?.sinkId !== newSinkId) {
          await ref.current?.setSinkId?.(newSinkId);
          console.log(`Set sink ID to ${newSinkId}`);
        }
      } catch (error) {
        console.log('Did not update audio sink ID');
      }
    };
    if (audioOutputId && ref.current?.setSinkId) {
      updateSinkId(audioOutputId);
    }
  }, [audioOutputId]);

  return (
    <audio
      autoPlay={forcePlay}
      ref={ref}
      data-testid='audio'
      onCanPlay={() => {
        if (forcePlay) {
          const playPromise = ref.current?.play();

          if (playPromise !== undefined) {
            playPromise.catch(onPlayErrorCallback);
          }
        }
      }}
    />
  );
};

export default AudioTrack;
