import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { makeThemedStyles } from '@provider-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDeviceId, getSelectedDeviceNotes } from '@provider-selectors/esitter';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { setRoomNote } from '@provider-reducers/esitterSlice';
import { styledScrollbar } from '@provider-utils/styles';

export const Notes = React.memo((): React.ReactElement => {
  const { styles } = useStyles();
  const selectedNote = useSelector(getSelectedDeviceNotes);
  const selectedDeviceId = useSelector(getSelectedDeviceId);

  const dispatch = useDispatch();

  const [noteValue, setNoteValue] = useState(selectedNote || '');
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  useEffect(() => {
    setNoteValue(selectedNote);
  }, [selectedNote, selectedDeviceId]);

  useEffect(() => {
    setIsSaved(false);
  }, [selectedDeviceId]);

  const onChangeNoteValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoteValue(e.target.value);
    if (!isTyping) {
      setIsTyping(true);
    }
    if (isSaved) {
      setIsSaved(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsSaved(true);
      setIsTyping(false);
      dispatch(
        setRoomNote({
          id: selectedDeviceId,
          note: noteValue
        })
      );
    }, 700);
    return () => clearTimeout(timeoutId);
  }, [noteValue, dispatch, selectedDeviceId]);

  return (
    <div>
      <Stack direction='row' alignItems='baseline' justifyContent={'space-between'} marginBottom={'5px'}>
        <Typography variant='caption' color={'text.secondary'}>
          Notes
        </Typography>
        {isSaved && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} component={'div'}>
            <Typography variant='caption' color={'text.secondary'}>
              Saved
            </Typography>
            <DownloadDoneIcon sx={styles.savedIcon} />
          </Stack>
        )}
        {isTyping && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-start'} component={'div'}>
            <Typography variant='caption' color={'text.secondary'}>
              Typing...
            </Typography>
          </Stack>
        )}
      </Stack>
      <TextField
        placeholder='Add notes here'
        sx={styles.textfield}
        fullWidth={true}
        variant='filled'
        multiline
        rows={23}
        value={noteValue}
        onChange={onChangeNoteValue}
      />
    </div>
  );
});

const useStyles = makeThemedStyles()(() => ({
  savedIcon: {
    width: '16px',
    height: '16px',
    color: '#BEC3CC'
  },
  textfield: {
    backgroundColor: '#313A42',
    '& .MuiInputBase-input': {
      color: '#BEC3CC',
      letterSpacing: '0.5px',
      ...styledScrollbar()
    }
  }
}));
