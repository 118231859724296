import { ButtonControl } from '@provider-components';
import { EButtonControlColor, EButtonControlSize } from '@provider-types/enums';
import GroupIcon from '@mui/icons-material/Group';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeThemedStyles } from '@provider-hooks';
import { selectSidePanelCollapsed } from '@provider-selectors/esitter';
import { setSidePanelCollapsed } from '@provider-reducers/esitterSlice';

export const CollapseButton = React.memo((): React.ReactElement => {
  const sidePanelCollapsed = useSelector(selectSidePanelCollapsed);

  const dispatch = useDispatch();
  const { styles } = useStyles({ sidePanelCollapsed });
  const handleClick = useCallback(
    () => dispatch(setSidePanelCollapsed(!sidePanelCollapsed)),
    [dispatch, sidePanelCollapsed]
  );

  return (
    <>
      <ButtonControl
        size={EButtonControlSize.MEDIUM}
        backgroundColor={sidePanelCollapsed ? '#313A42' : '#1774CC'}
        color={sidePanelCollapsed ? EButtonControlColor.GRAY : EButtonControlColor.BLUE}
        onClick={handleClick}
        data-testid='myPatientsBtn'
      >
        <GroupIcon sx={styles.icon} />
      </ButtonControl>
    </>
  );
});

type StyleProps = {
  sidePanelCollapsed: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { sidePanelCollapsed }) => ({
  icon: {
    width: '19.86px',
    height: '19.86px',
    color: sidePanelCollapsed ? '#E2E2E2' : '#ffffff'
  }
}));
