import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingsInitialState } from '@provider-types/reducer';
import { getBrowserType } from 'src/detectMobileDevice';
import { EBrowserType } from '@provider-types/enums';

const initialState: SettingsInitialState = {
  browserType: getBrowserType(),
  didVisitDeviceTable: false,
  selectedAudioInputId: null,
  selectedVideoInputId: null,
  audioInputId: null,
  audioOutputId: null,
  videoInputId: null,
  audioInputInitialized: false,
  audioOutputInitialized: false,
  videoInputInitialized: false
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setBrowserType: (state, action: PayloadAction<EBrowserType>) => {
      state.browserType = action.payload;
    },
    setDidVisitDeviceTable: state => {
      state.didVisitDeviceTable = true;
    },
    setSelectedAudioInputIdFromUser: (state, action: PayloadAction<string | null>) => {
      state.selectedAudioInputId = action.payload;
    },
    setSelectedVideoInputIdFromUser: (state, action: PayloadAction<string | null>) => {
      state.selectedVideoInputId = action.payload;
    },
    setAudioInputId: (state, action: PayloadAction<string | null>) => {
      state.audioInputId = action.payload;
      state.audioInputInitialized = true;
    },
    setAudioOutputId: (state, action: PayloadAction<string | null>) => {
      state.audioOutputId = action.payload;
      state.audioOutputInitialized = true;
    },
    setVideoInputId: (state, action: PayloadAction<string | null>) => {
      state.videoInputId = action.payload;
      state.videoInputInitialized = true;
    },
    setAudioInputInitialized: (state, action: PayloadAction<boolean>) => {
      state.audioInputInitialized = action.payload;
    },
    setAudioOutputInitialized: (state, action: PayloadAction<boolean>) => {
      state.audioOutputInitialized = action.payload;
    },
    setVideoInputInitialized: (state, action: PayloadAction<boolean>) => {
      state.videoInputInitialized = action.payload;
    }
  }
});

export const {
  setBrowserType,
  setDidVisitDeviceTable,
  setSelectedAudioInputIdFromUser,
  setSelectedVideoInputIdFromUser,
  setAudioInputId,
  setAudioOutputId,
  setVideoInputId,
  setAudioInputInitialized,
  setAudioOutputInitialized,
  setVideoInputInitialized
} = settingsSlice.actions;

export default settingsSlice.reducer;
