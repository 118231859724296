import { AddParticipantResponseData, IInvitedParticipantInfo, IParticipantLogin } from '@provider-types/embed';
import { EBrowserType } from '@provider-types/enums';
import { BackendError } from '@provider-errors/BackendError';
import api from '@provider-services/api/api';

export async function addParticipant(
  callId: string,
  data: { displayName: string; otpToken: string; browserType: EBrowserType.DESKTOP | EBrowserType.MOBILE }
): Promise<{ body: AddParticipantResponseData; headers: Headers } | BackendError> {
  try {
    const res = await api.post<AddParticipantResponseData>(`/api/v2/calls/${callId}/users`, { body: data });

    return res;
  } catch (error) {
    console.log(error);

    return error as BackendError;
  }
}

export async function postParticipantLogin(data: {
  callUuid: string;
  loginToken: string;
}): Promise<{ body: IParticipantLogin; headers: Headers } | BackendError> {
  try {
    const res = await api.post<IParticipantLogin>('/api/v2/auth/participantLogin', { body: data });

    return res;
  } catch (error) {
    return error as BackendError;
  }
}

export async function getInvitationInfo(
  callId: string | undefined,
  otpToken: string | undefined
): Promise<IInvitedParticipantInfo | BackendError> {
  try {
    const res = await api.get<IInvitedParticipantInfo>(`/api/v2/calls/${callId}/invitation`, {
      headers: { loginToken: otpToken || '' }
    });

    return res.body;
  } catch (error) {
    return error as BackendError;
  }
}
