import React, { ElementRef, useRef, useState } from 'react';
import { default as MuiAccardion } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { mergeSx } from '@provider-utils/styles';
import { useSize, useThrottleEffect } from 'ahooks';

type Props = {
  elements: AccordionElement[];
  isMultipleAllowed?: boolean;
  wrapperSx?: SxProps<Theme>;
  accordionContainerSx?: SxProps<Theme>;
  accordionSummarySx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  accordionDetailsSx?: SxProps<Theme>;
  scrollIntoViewOnOpen?: boolean;
};

type AccordionItemProps = AccordionElement &
  Omit<Props, 'elements' | 'wrapperSx' | 'hidden'> & {
    expanded: boolean;
    onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void;
  };

type AccordionElement = {
  id: string;
  title: string;
  content: React.ReactNode;
  hidden?: boolean;
};

const AccordionItem = ({
  id,
  title,
  content,
  accordionContainerSx,
  accordionSummarySx,
  titleSx,
  accordionDetailsSx,
  expanded,
  scrollIntoViewOnOpen,
  onChange
}: AccordionItemProps) => {
  const accordionRef = useRef<ElementRef<'div'>>(null);

  const size = useSize(accordionRef);
  const height = size?.height;

  useThrottleEffect(
    () => {
      if (scrollIntoViewOnOpen && expanded && height !== undefined) {
        accordionRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    [scrollIntoViewOnOpen, expanded, height],
    { leading: true, wait: 50 }
  );

  const icon = (
    <ExpandMoreIcon
      sx={{
        color: ({ palette }) => palette.common.white,
        transform: 'rotate(-90deg)'
      }}
    />
  );

  return (
    <MuiAccardion
      ref={accordionRef}
      key={id}
      expanded={expanded}
      onChange={onChange}
      sx={mergeSx(
        {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderRadius: '0!important',
          borderBottom: ({ palette }) => `1px solid ${palette.common.black}`,
          '&:first-of-type': {
            borderTop: ({ palette }) => `1px solid ${palette.common.black}`
          },
          '&.Mui-expanded': {
            margin: 0
          },
          '&.Mui-disabled': {
            background: 'transparent'
          }
        },
        accordionContainerSx
      )}
    >
      <AccordionSummary
        expandIcon={icon}
        id={id}
        data-testid={id}
        aria-controls={`${id}-content`}
        sx={mergeSx(
          {
            padding: '4px 0',
            '&.Mui-expanded': {
              minHeight: 'auto'
            },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
              transform: 'rotate(90deg)'
            },
            '&.Mui-disabled': {
              opacity: 1,
              '&> .MuiAccordionSummary-content': {
                opacity: 0.65
              },
              ' .MuiAccordionSummary-expandIconWrapper': {
                pointerEvents: 'auto'
              }
            },
            '& .MuiAccordionSummary-content': {
              overflow: 'hidden'
            },

            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: '12px 0'
            }
          },
          accordionSummarySx
        )}
      >
        <Typography
          variant='h4'
          title={title}
          sx={mergeSx(
            {
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            },
            titleSx
          )}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={mergeSx({ padding: '0 0 12px' }, accordionDetailsSx)}>{content}</AccordionDetails>
    </MuiAccardion>
  );
};

export const Accordion = (props: Props): React.ReactElement => {
  const { elements, isMultipleAllowed, wrapperSx, ...rest } = props;
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(prev => {
      return !isExpanded ? prev.filter(item => item !== panel) : isMultipleAllowed ? [...prev, panel] : [panel];
    });
  };

  const content = elements
    .filter(el => !el.hidden)
    .map(itemProps => {
      const { id } = itemProps;

      return (
        <AccordionItem key={id} {...itemProps} expanded={expanded.includes(id)} onChange={handleChange(id)} {...rest} />
      );
    });

  return <Box sx={mergeSx(wrapperSx)}>{content}</Box>;
};
