import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EsitterInitialState, ModalData, PrerecordedMessage } from '@provider-types/reducer';
import { ConnectionMode } from '@provider-types/enums';
import { DebugShape, DeviceInfo, IDeviceRoomInfo, PatientInfo } from '@provider-types/provider';
import { AlarmStatus, EPrerecordedMessageGender, ESortType, ETagColor, BlurModeStatus } from '@provider-types/enums';
import { SidePanelContent } from '@provider-features/esitter/components/SidePanel/SidePanel';
import { AppDispatch, AppThunk } from '../root';
import { changeMode } from '@provider-api/esitter';
import { getPrerecordedMessages } from '@provider-api/precannedMessages';
import { arrayMove } from '@dnd-kit/sortable';
import { esitterDeviceModeChange } from '../logSlice';
import { getAvailbleGendersForLang } from '@provider-utils/redux/prerecordedMessages';

export const initialState: EsitterInitialState = {
  callId: '',
  roomsArray: [],
  selectedDevice: undefined,
  hoveredDeviceId: '',
  isCameraControlActive: false,
  isAudioControlActive: false,
  isPendingRemoval: false,
  refererRemovalTab: undefined,
  contentName: SidePanelContent.MY_PATIENTS,
  modalVisible: false,
  modalData: {
    title: '',
    MuiSvgIcon: undefined,
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    onSecondaryBtnClick: undefined,
    onPrimaryBtnClick: undefined,
    onClose: undefined
  },
  patientDetailsVisible: false,
  sidePanelCollapsed: false,
  sortType: ESortType.ATOZ,
  interventionConnectionStatus: {
    connecting: false,
    disconnecting: false,
    deviceId: null
  },
  listenModeConnectionStatus: {
    connecting: false,
    disconnecting: false,
    deviceId: null
  },
  isRedirectDisabled: true,
  isRoomLoading: false,
  listenModeDevicesStorageWhileIntervention: [],
  messages: [],
  focusedRoom: undefined
};

const tagTable = {
  '': 1, // none, not selected grey
  '#5B6B7B': 2, // none, selected grey
  '#00857A': 3, // green
  '#FFA800': 4, // yellow
  '#FF0000': 5 // red
};

const reorderRoomsArray = (roomsArray: IDeviceRoomInfo[], sortType: ESortType, hasFocusedRoom?: boolean) => {
  let sortedRoomsArray = [...roomsArray];

  // If hasFocusedRoom is true, remove the first element
  let focusedRoom;
  if (hasFocusedRoom) {
    [focusedRoom, ...sortedRoomsArray] = sortedRoomsArray;
  }

  if (sortType === ESortType.ATOZ) {
    sortedRoomsArray.sort((a, b) => a.deviceName.localeCompare(b.deviceName));
  } else if (sortType === ESortType.ZTOA) {
    sortedRoomsArray.sort((a, b) => b.deviceName.localeCompare(a.deviceName));
  } else if (sortType === ESortType.TAG) {
    sortedRoomsArray.sort((a, b) => {
      const aTagValue = tagTable[a.tagColor] || 0;
      const bTagValue = tagTable[b.tagColor] || 0;

      // First, compare tag values
      if (aTagValue !== bTagValue) {
        return bTagValue - aTagValue;
      }

      // If tag values are the same, sort alphabetically by device name
      return a.deviceName.localeCompare(b.deviceName);
    });
  }

  // If hasFocusedRoom is true, add the first element back at the beginning
  if (hasFocusedRoom && focusedRoom) {
    sortedRoomsArray = [focusedRoom, ...sortedRoomsArray];
  }

  return sortedRoomsArray;
};

export const fetchEsitterMessages = createAsyncThunk('esitter/messages', async () => {
  return await getPrerecordedMessages();
});

const esitterSlice = createSlice({
  name: 'esitter',
  initialState,
  reducers: {
    resetEsitterState: () => initialState,
    setRooms: (state, action: PayloadAction<IDeviceRoomInfo[]>) => {
      state.roomsArray = action.payload;
    },
    addRoom: (state, action: PayloadAction<IDeviceRoomInfo>) => {
      const hasFocusedRoom = Boolean(state.focusedRoom);
      state.roomsArray = reorderRoomsArray([...state.roomsArray, action.payload], state.sortType, hasFocusedRoom);
    },
    removeRoomByDeviceId: (state, action: PayloadAction<string>) => {
      state.roomsArray = state.roomsArray.filter((d: IDeviceRoomInfo) => d.deviceId !== action.payload);
    },
    setRoomMode: (state, action: PayloadAction<{ id: string; mode: string }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.mode = action.payload.mode;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.mode = action.payload.mode;
      }
    },
    setRoomPaused: (state, action: PayloadAction<{ id: string; paused: boolean }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.paused = action.payload.paused;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.paused = action.payload.paused;
      }
    },
    setRoomAudioLevel: (state, action: PayloadAction<{ id: string; level: number }>) => {
      const room = state.roomsArray.find(room => room.deviceId === action.payload.id);

      if (room) room.audioLevel = action.payload.level;

      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.audioLevel = action.payload.level;
      }
    },
    setRoomMessageGender: (state, action: PayloadAction<{ id: string; gender: EPrerecordedMessageGender }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.messageGender = action.payload.gender;
        }
      });

      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.messageGender = action.payload.gender;
      }
    },
    setRoomMessageLang: (state, action: PayloadAction<{ id: string; lang: string }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.messageLang = action.payload.lang;
        }
      });

      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.messageLang = action.payload.lang;
      }
    },
    setRoomMessagePlaying: (
      state,
      action: PayloadAction<{ id: string; messageId: string | null; progress?: number | undefined }>
    ) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.messagePlaying = {
            messageId: action.payload.messageId,
            progress: action.payload.progress ? action.payload.progress : 0
          };
        }
      });

      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.messagePlaying = {
          messageId: action.payload.messageId,
          progress: action.payload.progress ? action.payload.progress : 0
        };
      }
    },
    setRoomTagColor: (state, action: PayloadAction<{ id: string; tagColor: ETagColor }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.tagColor = action.payload.tagColor;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.tagColor = action.payload.tagColor;
      }
      if (state.sortType === ESortType.TAG) {
        const hasFocusedRoom = Boolean(state.focusedRoom);
        state.roomsArray = reorderRoomsArray(state.roomsArray, ESortType.TAG, hasFocusedRoom);
      }
    },
    setRoomNote: (state, action: PayloadAction<{ id: string; note: string }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.note = action.payload.note;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.note = action.payload.note;
      }
    },
    setPatientInfo: (state, action: PayloadAction<{ id: string; patientInfo: PatientInfo }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.patientInfo = action.payload.patientInfo;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.patientInfo = action.payload.patientInfo;
      }
    },
    setFeccValues: (state, action: PayloadAction<{ id: string; data: DeviceInfo }>) => {
      state.roomsArray = state.roomsArray.map(room => {
        if (room.deviceId === action.payload.id) {
          const updatedDeviceInfo = { ...room.deviceInfo, ...action.payload.data };
          return {
            ...room,
            deviceInfo: updatedDeviceInfo
          };
        }
        return room;
      });
      // allow confirmation of updated selected device camera state.
      // TODO: We likely need to consider this for other dynamically updated data for selected device
      if (state.selectedDevice?.deviceId === action.payload.id) {
        state.selectedDevice.deviceInfo = action.payload.data;
      }
    },
    setSelectedDevice: (state, action: PayloadAction<IDeviceRoomInfo | undefined>) => {
      state.selectedDevice = action.payload;
    },
    setSelectedDeviceById: (state, action: PayloadAction<string>) => {
      state.selectedDevice = state.roomsArray.find(el => el.deviceId === action.payload) || undefined;
    },
    setDevicePreviousMode: (state, action: PayloadAction<{ deviceId: string; prevMode: string }>) => {
      state.roomsArray = state.roomsArray.map((room: IDeviceRoomInfo) => {
        if (room.deviceId === action.payload.deviceId) {
          return {
            ...room,
            prevMode: action.payload.prevMode
          };
        } else {
          return room;
        }
      });
    },
    setListenModeDevices: (state, action: PayloadAction<IDeviceRoomInfo[]>) => {
      state.listenModeDevicesStorageWhileIntervention = [...action.payload];
    },
    resetSelectedDevice: state => {
      state.selectedDevice = undefined;
    },
    setHoveredDevice: (state, action: PayloadAction<string | null>) => {
      state.hoveredDeviceId = action.payload;
    },
    setIsCameraControlActive: (state, action: PayloadAction<string | boolean>) => {
      state.isCameraControlActive = action.payload;
    },
    setIsAudioControlActive: (state, action: PayloadAction<string | boolean>) => {
      state.isAudioControlActive = action.payload;
    },
    setPendingRemoval: (state, action: PayloadAction<boolean>) => {
      state.isPendingRemoval = state.selectedDevice ? action.payload : false;
    },
    setRefererRemovalTab: (state, action: PayloadAction<SidePanelContent | undefined>) => {
      state.refererRemovalTab = action.payload;
    },
    setSidePanelContent: (state, action: PayloadAction<SidePanelContent>) => {
      state.contentName = action.payload;
    },
    setCallId: (state, action: PayloadAction<string>) => {
      state.callId = action.payload;
    },
    setEsitterModalVisible: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.modalVisible = action.payload;
      } else {
        state.modalVisible = action.payload;
        state.modalData = { ...initialState.modalData };
      }
    },
    setEsitterModalData: (state, action: PayloadAction<ModalData>) => {
      state.modalData = {
        title: action.payload?.title || '',
        MuiSvgIcon: action.payload?.MuiSvgIcon,
        description: action.payload?.description || '',
        primaryBtnName: action.payload?.primaryBtnName || '',
        secondaryBtnName: action.payload?.secondaryBtnName || '',
        onSecondaryBtnClick: action.payload?.onSecondaryBtnClick,
        onPrimaryBtnClick: action.payload?.onPrimaryBtnClick,
        onClose: action.payload?.onClose,
        hideCloseBtn: action.payload?.hideCloseBtn
      };
    },
    setSidePanelCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sidePanelCollapsed = action.payload;
    },
    setHasReceivedWsMessage: (state, action: PayloadAction<{ id: string; value: boolean }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.hasReceivedWSMessage = action.payload.value;
        }
      });
    },
    setIsMicActive: (state, action: PayloadAction<{ deviceId: string; isMicActive: boolean }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.deviceId) {
          room.isEsitterLocalMicActive = action.payload.isMicActive;
        }
      });
    },
    setSortType: (state, action: PayloadAction<ESortType>) => {
      const sortType = action.payload;
      const hasFocusedRoom = Boolean(state.focusedRoom);

      if (sortType !== ESortType.CUSTOM) {
        state.roomsArray = reorderRoomsArray(state.roomsArray, sortType, hasFocusedRoom);
      }
      state.sortType = sortType;
    },
    setInterventionConnectionStatus: (
      state,
      action: PayloadAction<{ connecting: boolean; disconnecting: boolean; deviceId: string }>
    ) => {
      state.interventionConnectionStatus = {
        connecting: action.payload.connecting,
        disconnecting: action.payload.disconnecting,
        deviceId: action.payload.deviceId
      };
    },
    setListenModeConnectionStatus: (
      state,
      action: PayloadAction<{ connecting: boolean; disconnecting: boolean; deviceId: string }>
    ) => {
      state.listenModeConnectionStatus = {
        connecting: action.payload.connecting,
        disconnecting: action.payload.disconnecting,
        deviceId: action.payload.deviceId
      };
    },
    setDebugMode: (state, action: PayloadAction<{ id: string; debugModeOn: boolean; debugShapes: DebugShape[] }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.debugModeOn = action.payload.debugModeOn;
          room.debugShapes = action.payload.debugShapes;
        }
      });
      if (state.selectedDevice && action.payload.id === state.selectedDevice.deviceId) {
        state.selectedDevice.debugModeOn = action.payload.debugModeOn;
        state.selectedDevice.debugShapes = action.payload.debugShapes;
      }
    },
    setDeviceAlarm: (state, action: PayloadAction<{ id: string; status: AlarmStatus }>) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.alarmStatus = action.payload.status;
        }
      });
    },
    setRedirectDisabled: (state, action: PayloadAction<boolean>) => {
      state.isRedirectDisabled = action.payload;
    },
    setStateBackToDefault: () => initialState,
    setIsRoomLoading: (state, action: PayloadAction<boolean>) => {
      state.isRoomLoading = action.payload;
    },
    setFocusedRoom: (state, action: PayloadAction<string | undefined>) => {
      const deviceId = action.payload;
      state.focusedRoom = deviceId;

      if (deviceId) {
        const index = state.roomsArray.findIndex(r => r.deviceId === deviceId);
        state.roomsArray = arrayMove(state.roomsArray, index, 0);
      } else if (state.sortType !== ESortType.CUSTOM) {
        state.roomsArray = reorderRoomsArray(state.roomsArray, state.sortType, false);
      }
    },
    setPrerecordedMessages: (state, action: PayloadAction<PrerecordedMessage[]>) => {
      state.messages = action.payload;
    },
    setDeviceBlurModeStatus: (
      state,
      action: PayloadAction<{ id: string; status: BlurModeStatus; localStatus?: boolean }>
    ) => {
      state.roomsArray.forEach(room => {
        if (room.deviceId === action.payload.id) {
          room.blurModeStatus = action.payload.status;
          if (action.payload.localStatus !== undefined) {
            room.localBlurModeStatus = action.payload.localStatus;
          }
        }
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchEsitterMessages.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
  }
});

export const handleChangeMode = (device: IDeviceRoomInfo | undefined, mode?: string): AppThunk => {
  return async (dispatch: AppDispatch, getState): Promise<void> => {
    const state = getState();

    if (device) {
      console.log('Change Mode for device ', device.deviceId);

      const deviceRoom = state.esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === device.deviceId);

      if (deviceRoom && deviceRoom.deviceId === device.deviceId) {
        dispatch(setDevicePreviousMode({ deviceId: deviceRoom.deviceId, prevMode: deviceRoom.mode }));
      }

      if (state.esitter.callId && state.esitter.roomsArray.length > 0) {
        let changeToMode = '';

        if (mode) {
          switch (mode) {
            case ConnectionMode.INTERACTIVE:
              changeToMode = ConnectionMode.INTERACTIVE;
              break;

            case ConnectionMode.LISTENING:
              changeToMode = ConnectionMode.LISTENING;
              break;

            case ConnectionMode.OBSERVATION:
              changeToMode = ConnectionMode.OBSERVATION;
              break;

            default:
              changeToMode = ConnectionMode.OBSERVATION;
          }
        } else if (!mode && deviceRoom?.prevMode === ConnectionMode.LISTENING) {
          changeToMode = ConnectionMode.LISTENING;
        } else {
          changeToMode = ConnectionMode.OBSERVATION;
        }

        console.log('change to: ', device.deviceId, changeToMode, ' for callUuid - ', state.esitter.callId);
        await changeMode(state.esitter.callId, device.deviceId, {
          mode: changeToMode,
          forceMode: true
        });

        /** @TODO replace by log event emitter when implemented */
        dispatch(
          esitterDeviceModeChange({
            prevState: deviceRoom?.mode,
            nextState: changeToMode,
            deviceId: device.deviceId
          })
        );

        dispatch(setIsAudioControlActive(false));
        dispatch(setIsCameraControlActive(false));
      }
    }
  };
};

export const updateMessageRoomLangGenderPair = (deviceId: string, lang: string): AppThunk => {
  return async (dispatch: AppDispatch, getState): Promise<void> => {
    const state = getState().esitter;
    const currentGender = state.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === deviceId)?.messageGender;

    dispatch(setRoomMessageLang({ id: deviceId, lang }));

    const availableGenders = getAvailbleGendersForLang({ esitter: state, lang });

    if (availableGenders && !availableGenders.includes(currentGender)) {
      dispatch(setRoomMessageGender({ id: deviceId, gender: availableGenders[0] }));
    }
  };
};

export const {
  setRooms,
  addRoom,
  removeRoomByDeviceId,
  setRoomMode,
  setRoomPaused,
  setRoomTagColor,
  setRoomNote,
  setFeccValues,
  setSelectedDevice,
  setSelectedDeviceById,
  resetSelectedDevice,
  setIsCameraControlActive,
  setIsAudioControlActive,
  setPendingRemoval,
  setRefererRemovalTab,
  setSidePanelContent,
  setCallId,
  setEsitterModalVisible,
  setEsitterModalData,
  setSidePanelCollapsed,
  setHasReceivedWsMessage,
  setSortType,
  setStateBackToDefault,
  setHoveredDevice,
  setInterventionConnectionStatus,
  setListenModeConnectionStatus,
  setPatientInfo,
  setDebugMode,
  setRedirectDisabled,
  setIsRoomLoading,
  setRoomAudioLevel,
  setDevicePreviousMode,
  setListenModeDevices,
  setRoomMessageGender,
  setRoomMessageLang,
  setRoomMessagePlaying,
  setDeviceAlarm,
  setFocusedRoom,
  setPrerecordedMessages,
  resetEsitterState,
  setDeviceBlurModeStatus,
  setIsMicActive
} = esitterSlice.actions;

export default esitterSlice.reducer;
