import React, { useRef, useState } from 'react';
import styles from './Button.module.css';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

interface IconParams {
  color?: string;
  width: string;
  height: string;
}

export interface Props {
  id?: string;
  children: string | JSX.Element;
  variant?: 'primary' | 'secondary' | 'text';
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string;
  height?: string;
  color?: string;
  status?: 'loading' | 'success' | 'error';
  StartIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  EndIcon?: OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
  IconParams?: IconParams;
  onClick: () => void;
}

/**
 * @param fullWidth make button full width if it`s needed
 * @param IconParams param for configuring style(sx) of StartIcon or EndIcon
 * @param status changes content to one from icons by it value
 */
export const Button: React.FC<Props> = ({
  id,
  children,
  variant = 'primary',
  disabled,
  fullWidth,
  width = '',
  height,
  color,
  status = '',
  StartIcon,
  EndIcon,
  IconParams,
  onClick
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [elWidth] = useState('');

  const btnClassName = {
    primary: `${styles.container_primary} ${disabled && styles.container_primary_disabled}`,
    secondary: `${styles.container_secondary} ${disabled && styles.container_secondary_disabled}`,
    text: styles.container_text
  }[variant];

  const disabledContentColor = {
    primary: '#BEC3CC',
    secondary: '#5B6B7B',
    text: '#FFFFFF'
  }[variant];

  const statusContent =
    {
      loading: <CircularProgress size={16} sx={{ color: '#ffffff' }} />,
      success: <CheckIcon sx={{ width: '20px', color: '#ffffff' }} />,
      error: <ErrorOutlineIcon sx={{ width: '20px', height: '20px', color: '#ffffff' }} />
    }[status] || undefined;

  return (
    <button
      id={id}
      className={btnClassName}
      style={{
        width: fullWidth ? '100%' : width ? width : undefined,
        height: height ? height : undefined,
        cursor: disabled ? 'default' : 'pointer',
        backgroundColor: color ? color : undefined,
        border: color ? `2px solid ${color}` : undefined
      }}
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <div ref={contentRef} className={styles.content} style={{ width: elWidth ? elWidth : undefined }}>
        {statusContent ? (
          statusContent
        ) : (
          <>
            {StartIcon && (
              <StartIcon sx={{ ...IconParams, color: disabled ? disabledContentColor : IconParams?.color }} />
            )}
            {children}
            {EndIcon && <EndIcon sx={{ ...IconParams, color: disabled ? disabledContentColor : IconParams?.color }} />}
          </>
        )}
      </div>
    </button>
  );
};
