import React, { useCallback, useRef } from 'react';
import {
  setIsAudioControlActive,
  setIsCameraControlActive,
  setSelectedDeviceById,
  setSidePanelCollapsed,
  setSidePanelContent
} from '@provider-reducers/esitterSlice';
import { resetEditMode } from '@provider-reducers/smartRailsSlice';
import { SidePanelContent } from '../../components/SidePanel/SidePanel';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAudioControlHovered, selectIsCameraControlHovered } from '@provider-selectors/app';
import { selectEditMode } from '@provider-selectors/smartRails';
import { selectDeviceSelectedCameraUuid } from '@provider-selectors/esitter';

interface UseSelectRoomBox {
  handleSelectionClick: (event: React.MouseEvent<HTMLElement>) => void;
  cancelSidePanelVisibilityChange: () => void;
}

const defaultOptions = {
  delaySidePanelVisibilityChange: 0
};

export const useSelectRoomBox = (deviceId: string, options = defaultOptions): UseSelectRoomBox => {
  const { delaySidePanelVisibilityChange } = Object.assign({}, defaultOptions, options);
  const isCameraControlHovered = useSelector(selectIsCameraControlHovered);
  const isAudioControlHovered = useSelector(selectIsAudioControlHovered);
  const selectedCameraUuid = useSelector(state => selectDeviceSelectedCameraUuid(state, deviceId));
  const editMode = useSelector(state => selectEditMode(state, selectedCameraUuid));
  const timeoutRef = useRef<NodeJS.Timeout>();

  const dispatch = useDispatch();

  const cancelSidePanelVisibilityChange = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const handleSelectionClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      // Clicking anywhere *except* the camera.audio control should hide it
      if (!isCameraControlHovered) {
        dispatch(setIsCameraControlActive(false));
      }

      if (!isAudioControlHovered) {
        dispatch(setIsAudioControlActive(false));
      }

      if (!editMode) {
        dispatch(resetEditMode());
        dispatch(setSelectedDeviceById(deviceId));

        // Clear any existing timeout
        cancelSidePanelVisibilityChange();

        // Set new timeout for side panel actions
        timeoutRef.current = setTimeout(() => {
          dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
          dispatch(setSidePanelCollapsed(false));
        }, delaySidePanelVisibilityChange);
      }
    },
    [
      deviceId,
      dispatch,
      editMode,
      isAudioControlHovered,
      isCameraControlHovered,
      cancelSidePanelVisibilityChange,
      delaySidePanelVisibilityChange
    ]
  );

  // Cleanup timeout on unmount
  React.useEffect(() => {
    return () => {
      cancelSidePanelVisibilityChange();
    };
  }, [cancelSidePanelVisibilityChange]);

  return {
    handleSelectionClick,
    cancelSidePanelVisibilityChange
  };
};
