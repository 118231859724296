import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import React, { useEffect, useMemo } from 'react';
import { useFormHandlers, makeThemedStyles } from '@provider-hooks';
import { PatientInfoPanelForm, PatientInfoPanelFormValues } from './PatientInfoPanelForm';
import { useDispatch, useSelector } from 'react-redux';
import { setPatientInfo } from '@provider-reducers/esitterSlice';
import { PatientInfo } from '@provider-types/provider';
import {
  getSelectedDeviceId,
  selectIsSelectedDeviceInInteractiveMode,
  selectSelectedDevicePatientLanguage,
  selectSelectedDevicePatientLocation,
  selectSelectedDevicePatientName,
  selectSelectedDevicePatientPrimaryContact,
  selectSelectedDevicePatientReasonForMonitoring
} from '@provider-selectors/esitter';
import { PatientInfoLabel } from './PatientInfoLabel';
import { OverflowTip } from '@provider-components';

export const PatientInfoPanel = (): React.ReactElement => {
  const { editMode, editModeOn, editModeOff } = useFormHandlers();
  const dispatch = useDispatch();

  const selectedDeviceId = useSelector(getSelectedDeviceId);

  const handleSubmit = (values: PatientInfoPanelFormValues) => {
    dispatch(setPatientInfo({ id: selectedDeviceId, patientInfo: values as PatientInfo }));
    editModeOff();
  };
  const { styles } = useStyles();
  const patientName = useSelector(selectSelectedDevicePatientName);
  const reasonForMonitoring = useSelector(selectSelectedDevicePatientReasonForMonitoring);
  const language = useSelector(selectSelectedDevicePatientLanguage);
  const location = useSelector(selectSelectedDevicePatientLocation);
  const primaryContact = useSelector(selectSelectedDevicePatientPrimaryContact);
  const isInteractiveMode = useSelector(selectIsSelectedDeviceInInteractiveMode);

  useEffect(() => {
    if (isInteractiveMode) {
      editModeOff();
    }
  }, [editModeOff, isInteractiveMode]);

  const initialValues = useMemo(() => {
    return {
      name: patientName,
      reasonForMonitoring: reasonForMonitoring,
      language: language,
      location: location,
      primaryContact: primaryContact
    };
  }, [patientName, reasonForMonitoring, language, location, primaryContact]);

  if (editMode) {
    return <PatientInfoPanelForm onSubmit={handleSubmit} onCancel={editModeOff} initialValues={initialValues} />;
  }
  return (
    <Stack spacing={4} data-testid='patientInfoPanel'>
      <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'}>
        <OverflowTip title={patientName}>
          <Typography data-testid='patientName' variant='h4' sx={styles.name}>
            {patientName || "Patient's Name"}
          </Typography>
        </OverflowTip>
        {!isInteractiveMode && (
          <IconButton
            size={'small'}
            onClick={editModeOn}
            sx={{ color: '#ffffff', mt: '2px', p: 0, fontSize: '1rem' }}
            data-testid='editPatientNameBtn'
          >
            <EditIcon fontSize={'inherit'} />
          </IconButton>
        )}
      </Stack>
      <PatientInfoLabel
        data-testid='reasonForMonitoring'
        text={reasonForMonitoring || 'Reason for monitoring'}
        icon={RemoveRedEyeIcon}
      />
      <PatientInfoLabel data-testid='language' text={language || 'Language'} icon={ChatBubbleIcon} />
      <PatientInfoLabel data-testid='location' text={location || 'Location'} icon={LocationOnIcon} />
      <PatientInfoLabel
        data-testid='primaryContact'
        text={primaryContact || 'Primary Contact'}
        icon={PermContactCalendarIcon}
      />
    </Stack>
  );
};
const useStyles = makeThemedStyles()(() => ({
  name: {
    textWrap: 'wrap',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all'
  }
}));
