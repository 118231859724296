import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: 'Open Sans',
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 800,
  h1: {
    fontSize: 32,
    lineHeight: 1.375,
    fontWeight: 600
  },
  h2: {
    fontSize: 24,
    lineHeight: 1.33,
    fontWeight: 600
  },
  h3: {
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 600
  },
  h4: {
    fontSize: 16,
    lineHeight: 1.375,
    fontWeight: 600
  },
  h5: {
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 600
  },
  cc: {
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 400
  },
  subtitle1: { fontSize: 16, lineHeight: 1.375, fontWeight: 400 },
  subtitle2: { fontSize: 12, lineHeight: 1.33, fontWeight: 400 },
  body1: {
    fontSize: 14,
    lineHeight: 1.4,
    fontWeight: 400
  },
  body2: {
    fontSize: 12,
    lineHeight: 1.33,
    fontWeight: 400
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.33,
    fontWeight: 400,
    letterSpacing: '0.15px'
  }
};
