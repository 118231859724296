import React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedCameraBoundaryMutedTimestamp } from '@provider-selectors/smartRails';
import { useTimer } from 'react-timer-hook';

export const AlertMutedTimer = (): JSX.Element => {
  const mutedTimestamp = useSelector(getSelectedCameraBoundaryMutedTimestamp);
  const { seconds, minutes } = useTimer({
    expiryTimestamp: new Date(mutedTimestamp),
    autoStart: true
  });

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  return (
    <span data-testid='alertMutedTimer'>
      Turning back on in {padTo2Digits(minutes)}:{padTo2Digits(seconds)}
    </span>
  );
};
