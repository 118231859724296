import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@provider-reducers/root';
import { VRCallInitialState } from '@provider-types/reducer';

export const selectVRCall = (state: RootState): VRCallInitialState => state.vrcall;

export const selectCallId = createSelector([selectVRCall], vrcall => vrcall.callId);
export const selectMode = createSelector([selectVRCall], vrcall => vrcall.mode);
export const selectInitialRequestedDeviceMode = createSelector(
  [selectVRCall],
  vrcall => vrcall.initialRequestedDeviceMode
);
export const selectBreakingTheGlass = createSelector([selectVRCall], vrcall => vrcall.breakingTheGlass);
export const selectChangingMode = createSelector([selectVRCall], vrcall => vrcall.changingMode);
export const selectMyUuid = createSelector([selectVRCall], vrcall => vrcall.myUuid);
export const selectPanLimit = createSelector([selectVRCall], vrcall => vrcall.panLimit);
export const selectTiltLimit = createSelector([selectVRCall], vrcall => vrcall.tiltLimit);
export const selectZoomLimit = createSelector([selectVRCall], vrcall => vrcall.zoomLimit);
export const selectPatientVolumeLimit = createSelector([selectVRCall], vrcall => vrcall.patientVolumeLimit);
export const selectMicGainLimit = createSelector([selectVRCall], vrcall => vrcall.micGainLimit);
export const selectZoom = createSelector([selectVRCall], vrcall => vrcall.zoom);
export const selectPatientVolume = createSelector([selectVRCall], vrcall => vrcall.patientVolume);
export const selectVideoRotationAngle = createSelector([selectVRCall], vrcall => vrcall.videoRotationAngle);
export const selectPrivacyMode = createSelector([selectVRCall], vrcall => vrcall.privacyMode);
export const selectCameraList = createSelector([selectVRCall], vrcall => vrcall.cameraList);
export const selectSelectedCameraUuid = createSelector([selectVRCall], vrcall => vrcall.selectedCameraUuid);
export const selectCamera = createSelector([selectVRCall], vrcall => vrcall.camera);
export const selectHasReceivedWSMessage = createSelector([selectVRCall], vrcall => vrcall.hasReceivedWSMessage);
export const selectScreenshareParticipantId = createSelector([selectVRCall], vrcall => vrcall.screenshareParticipantId);
export const selectMicGain = createSelector([selectVRCall], vrcall => vrcall.micGain);
export const selectModalVisible = createSelector([selectVRCall], vrcall => vrcall.modalVisible);
export const selectModalData = createSelector([selectVRCall], vrcall => vrcall.modalData);
export const selectReconnectModal = createSelector([selectVRCall], vrcall => vrcall.reconnectModal);
export const selectInvitationsData = createSelector([selectVRCall], vrcall => vrcall.invitationsData);
export const selectTestCall = createSelector([selectVRCall], vrcall => vrcall.testCall);
export const selectAmIStartingInteraction = createSelector([selectVRCall], vrcall => vrcall.amIStartingInteraction);
export const selectEndCallScreenIcon = createSelector([selectVRCall], vrcall => vrcall.endCallScreenIcon);
export const selectEndCallScreenParticipant = createSelector([selectVRCall], vrcall => vrcall.endCallScreenParticipant);
export const selectEndCallScreenMessage = createSelector([selectVRCall], vrcall => vrcall.endCallScreenMessage);
export const selectIsKeyDownHandlerActive = createSelector([selectVRCall], vrcall => vrcall.isKeyDownHandlerActive);
export const selectDeviceHasVideoTrack = createSelector([selectVRCall], vrcall => vrcall.deviceHasVideoTrack);
export const selectEndCallPatientRejection = createSelector([selectVRCall], vrcall => vrcall.endCallPatientRejection);
export const selectInitiateGetInvites = createSelector([selectVRCall], vrcall => vrcall.initiateGetInvites);
export const selectBlurModeStatus = createSelector([selectVRCall], vrcall => vrcall.blurModeStatus);
export const selectIsMyMicActive = createSelector([selectVRCall], vrcall => vrcall.isMyMicActive);
