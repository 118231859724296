import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '@provider-reducers/root';
import { CloseCaptionsInitialState } from '@provider-reducers/closeCaptionsSlice';

export const getCloseCaptions = (state: RootState): CloseCaptionsInitialState => state.closeCaptions;

export const selectCloseCaptionsConfig = createSelector(
  [getCloseCaptions],
  closeCaptions => closeCaptions.closeCaptionsConfig
);

export const selectCloseCaptionsMessages = createSelector(
  [getCloseCaptions],
  closeCaptions => closeCaptions.closeCaptionsMessages
);
