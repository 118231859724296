import React from 'react';
import { Provider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AppRouter from './Router';
import { store } from './store';
import { ThemeProvider } from '@mui/material';
import { theme } from '@provider-theme';
import { VersionCheckDialog } from '@provider-components';
import { ErrorBoundary } from '@sentry/react';
import { ErrorBoundaryFallback } from '@provider-components';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {
    /** */
  };
}

const App: React.FC<EmptyProps> = () => {
  const { t } = useTranslation();

  return (
    <div className='App'>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
      </Helmet>
      <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
        <AppRouter />
      </ErrorBoundary>
      <VersionCheckDialog />
    </div>
  );
};

const AppWithContext: React.FC<EmptyProps> = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </ThemeProvider>
  </Provider>
);

export default AppWithContext;
