import MUIButton from '@mui/material/Button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ButtonProps } from '@mui/material/Button/Button';

interface MuteButtonProps extends ButtonProps {
  onMute: (value: number) => void;
  isVisible?: boolean;
  label?: string;
  options?: MuteButtonOption[];
}

interface MuteButtonOption {
  label: string;
  ms: number; // time to mute in ms
}

export const MUTE_BUTTON_OPTIONS = [
  { label: '2 minutes', ms: 120000 },
  { label: '5 minutes', ms: 300000 },
  { label: '10 minutes', ms: 600000 }
];

export const MuteButton = React.memo((props: MuteButtonProps): React.ReactElement | null => {
  const { isVisible = true, onMute, label = 'Mute Alert', options = MUTE_BUTTON_OPTIONS, ...otherProps } = props;

  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      onMute(options[index].ms);
      setOpen(false);
    },
    [onMute, options]
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  /**
   * Close menu when element becomes invisible,
   * otherwise menu will be opened without correct anchor when element becomes visible again
   */
  useEffect(() => {
    if (!isVisible) setOpen(false);
  }, [isVisible]);

  useEffect(() => {
    return () => {
      setOpen(false); // Close the menu when unmounting
    };
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <MUIButton onClick={handleClick} ref={anchorRef} {...otherProps}>
        {label}
      </MUIButton>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem key={option.ms} onClick={event => handleMenuItemClick(event, index)}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});
