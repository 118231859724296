import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppInitialState } from '@provider-types/reducer';
import packageJson from '../../../package.json';

import { ECallType } from '@provider-types/enums';

const initialState: AppInitialState = {
  providerAppVersion: `${packageJson.version}`,
  cmsVersion: '0.0.0',
  callType: ECallType.VR,
  myMicDisabled: false,
  myCamDisabled: false,
  isMyCamActive: false,
  mediaAccessModalVisible: false,
  mediaAccessModalData: {
    title: '',
    MuiSvgIcon: undefined,
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    onSecondaryBtnClick: undefined,
    onPrimaryBtnClick: undefined,
    hideCloseBtn: undefined,
    onClose: undefined,
    disablePrimaryBtnAutoClose: false
  },
  currentUser: {
    host: false,
    metadata: {
      displayName: '',
      imageUrl: '',
      specialty: ''
    },
    uuid: ''
  },
  isCameraControlHovered: false,
  isAudioControlHovered: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsCamActive: (state, action: PayloadAction<boolean>) => {
      state.isMyCamActive = action.payload;
    },

    setCmsVersion: (state, action: PayloadAction<string>) => {
      state.cmsVersion = action.payload;
    },
    setAppMediaAccessModalVisible: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.mediaAccessModalVisible = action.payload;
      } else {
        state.mediaAccessModalVisible = false;
        state.mediaAccessModalData = { ...initialState.mediaAccessModalData };
      }
    },
    setAppMediaAccessModalData: (
      state,
      action: PayloadAction<{
        title?: string;
        MuiSvgIcon?: any;
        MuiSvgIconStyle?: any;
        description?: string;
        primaryBtnName?: string;
        secondaryBtnName?: string;
        onSecondaryBtnClick?: any;
        onPrimaryBtnClick?: any;
        hideCloseBtn?: any;
        onClose?: any;
        disablePrimaryBtnAutoClose?: boolean;
      }>
    ) => {
      state.mediaAccessModalData = {
        title: action.payload?.title || '',
        MuiSvgIcon: action.payload?.MuiSvgIcon,
        MuiSvgIconStyle: action.payload?.MuiSvgIconStyle,
        description: action.payload?.description || '',
        primaryBtnName: action.payload?.primaryBtnName || '',
        secondaryBtnName: action.payload?.secondaryBtnName || '',
        onSecondaryBtnClick: action.payload?.onSecondaryBtnClick,
        onPrimaryBtnClick: action.payload?.onPrimaryBtnClick,
        hideCloseBtn: action.payload?.hideCloseBtn,
        onClose: action.payload?.onClose,
        disablePrimaryBtnAutoClose: action.payload?.disablePrimaryBtnAutoClose
      };
    },
    setMyUserId: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload;
    },
    setIsCameraControlHovered: (state, action: PayloadAction<boolean>) => {
      state.isCameraControlHovered = action.payload;
    },
    setIsAudioControlHovered: (state, action: PayloadAction<boolean>) => {
      state.isAudioControlHovered = action.payload;
    },
    setCallType: (state, action: PayloadAction<string>) => {
      state.callType = action.payload;
    }
  }
});

export const {
  setIsCamActive,
  setCmsVersion,
  setAppMediaAccessModalVisible,
  setAppMediaAccessModalData,
  setMyUserId,
  setIsCameraControlHovered,
  setIsAudioControlHovered,
  setCallType
} = appSlice.actions;

export default appSlice.reducer;
