import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectFocusedRoom } from '@provider-selectors/esitter';
import { setFocusedRoom } from '@provider-reducers/esitterSlice';

interface UseFocusedRoom {
  onFocusClick: (deviceId: string, callback?: () => void) => void;
  focusedRoom: string | undefined;
}

export const useFocusedRoom = (): UseFocusedRoom => {
  const dispatch = useDispatch();
  const focusedRoom = useSelector(selectFocusedRoom);

  const onFocusClick = useCallback(
    (deviceId: string, callback?: () => void) => {
      if (focusedRoom === deviceId) {
        dispatch(setFocusedRoom(undefined));
      } else {
        dispatch(setFocusedRoom(deviceId));
      }
      callback && callback();
    },
    [dispatch, focusedRoom]
  );
  return {
    onFocusClick,
    focusedRoom
  };
};
