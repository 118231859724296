import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { TextButton } from '@provider-components';
import NotificationsOffOutlinedIcon from '@mui/icons-material/NotificationsOffOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { setIsCameraControlActive, setSidePanelContent } from '@provider-reducers/esitterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { MuteButton } from '../../../../screens/NotificationBar/MuteButton/MuteButton';
import {
  getCallId,
  selectSelectedDeviceSelectedCameraUuid,
  getSelectedDeviceId,
  selectSelectedDeviceInPrivacyMode,
  selectIsSmartRailsSupportedForSelectedDevice,
  selectIsDevicePaused,
  selectHasReceivedWSMessage
} from '@provider-selectors/esitter';
import { SidePanelContent } from '../../../SidePanel/SidePanel';
import {
  setEnabled,
  setMotionDetected,
  setRoomBoundaryMuted,
  setRoomBoundaryMutedFor
} from '@provider-reducers/smartRailsSlice';
import { useEsitterWSMessages, useFocusedRoom } from '../../../../hooks';
import { getSelectedCameraSmartRailsStatus, selectSensitivityLevel } from '@provider-selectors/smartRails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { SmartrailsSwitch } from './SmartrailsSwitch';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';
import { RootState } from '@provider-reducers/root';
import { makeThemedStyles } from '@provider-hooks';
import { AlertMutedTimer } from '../SmartRailsIndicator/AllertMutedTimer';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

export enum SmartRailsStatus {
  ON = 'ON',
  OFF = 'OFF',
  MUTED = 'MUTED',
  ALARM = 'ALARM'
}

interface SmartRailsPanelSettings {
  component: React.ReactNode;
  editDisabled: boolean;
  hideSwitchElement?: boolean;
}

type SmartRailsComponentsMap = Record<SmartRailsStatus, SmartRailsPanelSettings>;

export const SmartRailsPanel = React.memo((): React.ReactElement | null => {
  const { focusedRoom, onFocusClick } = useFocusedRoom();
  const dispatch = useDispatch();
  const status = useSelector(getSelectedCameraSmartRailsStatus);
  const callId = useSelector(getCallId);
  const selectedCameraUuid = useSelector(selectSelectedDeviceSelectedCameraUuid);

  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const isDeviceInPrivacyMode = useSelector(selectSelectedDeviceInPrivacyMode);
  const [switchEnabled, setSwitchEnabled] = useState(false);
  const sensitivityLevel = useSelector((state: RootState) => selectSensitivityLevel(state, selectedCameraUuid));
  const paused = useSelector(state => selectIsDevicePaused(state, selectedDeviceId));
  const isDeviceReceivedWSMessage = useSelector(state => selectHasReceivedWSMessage(state, selectedDeviceId));

  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);
  const { t } = useTranslation();
  const { styles } = useStyles();

  const onEditClick = useCallback(async () => {
    await dispatch(setSidePanelContent(SidePanelContent.SMART_RAILS));
    await dispatch(setIsCameraControlActive(false));

    if (focusedRoom !== selectedDeviceId) {
      onFocusClick(selectedDeviceId);
    }
  }, [dispatch, onFocusClick, selectedDeviceId, focusedRoom]);

  const handleMute = useCallback(
    (ms: number) => {
      dispatch(setRoomBoundaryMutedFor(selectedCameraUuid, ms));
    },
    [dispatch, selectedCameraUuid]
  );

  const handleTurnOff = useCallback(() => {
    dispatch(setRoomBoundaryMuted(selectedCameraUuid, 0));

    dispatch(setEnabled({ cameraUuid: selectedCameraUuid, enabled: false }));
    dispatch(setMotionDetected({ cameraUuid: selectedCameraUuid, motionDetected: false }));

    sendUpdateSmartRailsEnabledWSMessage(selectedCameraUuid, false);
  }, [selectedCameraUuid, dispatch, sendUpdateSmartRailsEnabledWSMessage]);

  const handleReactivate = useCallback(() => {
    setSwitchEnabled(true);
    dispatch(setRoomBoundaryMuted(selectedCameraUuid, 0));
  }, [dispatch, selectedCameraUuid]);

  const notificationsOffOutlinedIcon = useMemo(() => <NotificationsOffOutlinedIcon />, []);
  const arrowDropDownIcon = useMemo(() => <ArrowDropDownIcon />, []);

  useEffect(() => {
    if (status === SmartRailsStatus.MUTED && switchEnabled) {
      setSwitchEnabled(false);
    }
  }, [status, switchEnabled]);

  const MuteAndDismissButtons = React.memo(() => (
    <>
      <MuteButton
        fullWidth
        color={'secondary'}
        sx={{ justifyContent: 'start' }}
        variant={'text'}
        startIcon={notificationsOffOutlinedIcon}
        endIcon={arrowDropDownIcon}
        onMute={handleMute}
      />
      <TextButton
        color={'secondary'}
        fullWidth
        title='Turn Off'
        onClick={handleTurnOff}
        icon={HighlightOffOutlinedIcon}
      />
    </>
  ));

  const ReactivateAlertButton = React.memo(() => (
    <Stack direction='column' sx={{ ml: 3 }}>
      <FormControlLabel
        data-testid='alertSwitchTurnOn'
        control={<Switch checked={switchEnabled} onChange={handleReactivate} />}
        label={t(`esitter.components.SidePanel.SmartrailsSwitch.${switchEnabled ? 'on' : 'off'}`)}
        color={'text.secondary'}
      />
      <Typography variant='caption' color={'text.secondary'}>
        <AlertMutedTimer />
      </Typography>
    </Stack>
  ));

  const componentsMap: SmartRailsComponentsMap = {
    [SmartRailsStatus.ON]: { component: null, editDisabled: false },
    [SmartRailsStatus.OFF]: { component: null, editDisabled: false },
    [SmartRailsStatus.ALARM]: { component: <MuteAndDismissButtons />, editDisabled: false },
    [SmartRailsStatus.MUTED]: { component: <ReactivateAlertButton />, editDisabled: true, hideSwitchElement: true }
  };

  const {
    component,
    editDisabled,
    hideSwitchElement = false
  } = componentsMap[status] || {
    component: null,
    editDisabled: true
  };

  const isSmartRailsSupported = useSelector(selectIsSmartRailsSupportedForSelectedDevice);

  if (!isSmartRailsSupported) {
    return null;
  }

  const isEditSmartRailsHidden = isDeviceInPrivacyMode || paused || !isDeviceReceivedWSMessage;

  return (
    <Box aria-label='Smart Rails Panel'>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' alignItems='center'>
          <Typography variant='h4'>{t('esitter.components.SidePanel.SmartRailsPanel.blockTitle')}</Typography>
          <Tooltip
            componentsProps={{ popper: { sx: styles.tooltip } }}
            placement='top'
            title={t('esitter.components.SidePanel.SmartRailsPanel.tooltipContent')}
          >
            <InfoOutlinedIcon fontSize='small' sx={{ fontSize: '16px', ml: 2 }} />
          </Tooltip>
        </Stack>
        {!isEditSmartRailsHidden && (
          <IconButton
            size='small'
            onClick={onEditClick}
            disabled={editDisabled || !isDeviceReceivedWSMessage}
            sx={{ color: '#ffffff', p: 0, fontSize: '1rem' }}
            data-testid='smartrails-edit-button'
          >
            <EditIcon fontSize='inherit' />
          </IconButton>
        )}
      </Stack>
      <Stack spacing={1} sx={{ mt: '2px', mb: 1 }}>
        <Typography variant='caption' color={'text.secondary'}>
          {t('esitter.components.SidePanel.SmartRailsPanel.subtitleSensitivityLevel')}&nbsp;
          {sensitivityLevel}
        </Typography>
      </Stack>
      <Box color={'text.secondary'}></Box>
      {!hideSwitchElement && (
        <SmartrailsSwitch disabled={isDeviceInPrivacyMode || editDisabled || !isDeviceReceivedWSMessage} />
      )}
      <Box sx={{ ml: -3 }}>{component}</Box>
    </Box>
  );
});

const useStyles = makeThemedStyles()(() => ({
  tooltip: {
    maxWidth: '200px',
    [`& .${tooltipClasses.tooltip}`]: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      padding: 4
    }
  }
}));
