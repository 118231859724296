import React from 'react';
import { Accordion } from '@provider-components';
import { PrerecordedMessages } from '../PrerecordedMessages/PrerecordedMessages';
import { DeviceInfoPanel } from '../DeviceInfoPanel/DeviceInfoPanel';
import { BlurModePanel } from '../BlurModePanel/BlurModePanel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectAreMessagesSupportedForDevice } from '@provider-selectors/esitter';

type Props = {
  deviceId: string;
};

export const AccordionContainer = ({ deviceId }: Props): React.ReactElement => {
  const { t } = useTranslation();

  const areMessagesSupported = useSelector(state => selectAreMessagesSupportedForDevice(state, deviceId));

  return (
    <Accordion
      isMultipleAllowed
      scrollIntoViewOnOpen
      elements={[
        {
          title: t('esitter.components.SidePanel.MessagesAccordion.title'),
          id: 'messages',
          content: <PrerecordedMessages />,
          hidden: !areMessagesSupported
        },
        {
          title: t('esitter.components.SidePanel.BlurModePanel.title'),
          id: 'blurModePanel',
          content: <BlurModePanel />
        },
        {
          title: t('esitter.components.SidePanel.DeviceInfoAccordion.title'),
          id: 'deviceInfo',
          content: <DeviceInfoPanel />
        }
      ]}
    />
  );
};
