import React, { useEffect, useMemo } from 'react';
import { MyPatientsContent } from './content/MyPatients.Content';
import { AddPatientContent } from './content/AddPatient.Content';
import { PatientDetailsContent } from './content/PatientDetails.Content';
import { useDispatch, useSelector } from 'react-redux';

import styles from './SidePanel.module.css';
// Images
import EsitterLogoSvg from '@provider-assets/images/esitter_logo.svg';
import { SmartRailsContent } from './content/SmartRailsContent/SmartRailsContent';
import { setSidePanelContent } from '@provider-reducers/esitterSlice';
import { CollapseButton } from './content/CollapseButton/CollapseButton';
import { SettingsButton } from './content/SettingsButton/SettingsButton';
import { LogoutButton } from './content/LogoutButton/LogoutButton';
import { SidePanelContainer } from './content/SidePanelContainer/SidePanelContainer';
import { RemovePatientPanel } from './content/RemovePatientPanel/RemovePatientPanel';
import {
  getSelectedDevice,
  getSelectedDeviceId,
  selectIsRoomsArrayEmpty,
  selectSidePanelCollapsed,
  selectSidePanelContentName
} from '@provider-selectors/esitter';
import { useRemoveDevice } from '../../hooks';

export enum SidePanelContent {
  MY_PATIENTS = 'my_patients',
  ADD_PATIENT = 'add_patient',
  PATIENT_DETAILS = 'patient_details',
  SMART_RAILS = 'smart_rails',
  PATIENT_REMOVAL = 'patient_remove'
}

interface SidePanelContentObject {
  title: string;
  content: React.ReactElement;
}

type SidePanelContentMap = Record<SidePanelContent, SidePanelContentObject>;

const shouldShowPatientDetailsPanel = (
  contentName: SidePanelContent,
  sidePanelCollapsed: boolean,
  hasSelectedDevice: boolean
): boolean => {
  return ![SidePanelContent.SMART_RAILS].includes(contentName) && !sidePanelCollapsed && hasSelectedDevice;
};

const shouldShowMyPatientsPanel = (contentName: SidePanelContent, isRoomsArrayEmpty: boolean): boolean => {
  return (
    ![SidePanelContent.ADD_PATIENT, SidePanelContent.SMART_RAILS].includes(contentName) ||
    (![SidePanelContent.MY_PATIENTS, SidePanelContent.ADD_PATIENT].includes(contentName) && isRoomsArrayEmpty)
  );
};

export const SidePanel = React.memo((): React.ReactElement => {
  const dispatch = useDispatch();

  const contentName = useSelector(selectSidePanelContentName);
  const selectedDevice = useSelector(getSelectedDevice);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const sidePanelCollapsed = useSelector(selectSidePanelCollapsed);
  const isRoomsArrayEmpty = useSelector(selectIsRoomsArrayEmpty);
  const { isPendingRemoval, onCancelRemoveClick } = useRemoveDevice();

  useEffect(() => {
    if (isPendingRemoval && sidePanelCollapsed) {
      onCancelRemoveClick();
    }
  }, [isPendingRemoval, onCancelRemoveClick, sidePanelCollapsed]);

  useEffect(() => {
    if (isPendingRemoval) {
      dispatch(setSidePanelContent(SidePanelContent.PATIENT_REMOVAL));
    } else if (shouldShowPatientDetailsPanel(contentName, sidePanelCollapsed, Boolean(selectedDevice))) {
      dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
    } else if (shouldShowMyPatientsPanel(contentName, isRoomsArrayEmpty)) {
      dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
    }
  }, [
    sidePanelCollapsed,
    selectedDevice,
    contentName,
    dispatch,
    selectedDeviceId,
    isPendingRemoval,
    isRoomsArrayEmpty
  ]);

  const sidePanelContentMap: SidePanelContentMap = useMemo(
    () => ({
      [SidePanelContent.MY_PATIENTS]: {
        title: 'My Patients',
        content: <MyPatientsContent />
      },
      [SidePanelContent.ADD_PATIENT]: { title: 'Add Patient', content: <AddPatientContent /> },
      [SidePanelContent.PATIENT_DETAILS]: {
        title: 'Patient Details',
        content: <PatientDetailsContent />
      },
      [SidePanelContent.SMART_RAILS]: { title: 'Smart Rails', content: <SmartRailsContent /> },
      [SidePanelContent.PATIENT_REMOVAL]: { title: 'Remove Patient', content: <RemovePatientPanel /> }
    }),
    []
  );

  const { title, content } = useMemo(
    () =>
      sidePanelContentMap[contentName as SidePanelContent] || {
        title: '',
        content: null
      },
    [contentName, sidePanelContentMap]
  );

  return (
    <div className={styles.container} data-testid='sidePanel'>
      <div className={styles.contentLeft} data-testid='sidePanelLeft'>
        <div className={styles.contentLeftTop}>
          <img className={styles.contentLeftTopLogo} src={EsitterLogoSvg} alt='logo' />
          <CollapseButton />
        </div>
        <div className={styles.contentLeftBottom}>
          <SettingsButton />
          <LogoutButton />
        </div>
      </div>
      <SidePanelContainer title={title} sidePanelCollapsed={sidePanelCollapsed}>
        {content}
      </SidePanelContainer>
    </div>
  );
});
