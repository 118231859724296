import { SidebarBackNavigation } from '../SidebarBackNavigation/SidebarBackNavigation';
import { resetSelectedDevice, setSidePanelContent } from '@provider-reducers/esitterSlice';
import { SidePanelContent } from '../../SidePanel';
import React from 'react';
import { useDispatch } from 'react-redux';

export const PatientDetailsNavigation = (): React.ReactElement => {
  const dispatch = useDispatch();

  return (
    <SidebarBackNavigation
      title={'All Patients'}
      onClick={() => {
        dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
        dispatch(resetSelectedDevice());
      }}
    />
  );
};
