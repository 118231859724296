import { useCallback, useState } from 'react';
import { BackendError } from '@provider-errors/BackendError';

interface UseRequest<T> {
  request: (...args: any[]) => Promise<any>;
  data: T | null;
  error: BackendError | null;
  isFetching: boolean;
}

export default function useRequest<T>(
  request: (...args: any[]) => Promise<T>,
  options?: {
    onSuccess?: (data: T, args: any[]) => void;
    onError?: (data: BackendError) => void;
  }
): UseRequest<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<BackendError | null>(null);
  const [isFetching, setIsFetching] = useState(false);

  const { onSuccess, onError } = options || {};

  const makeRequest = useCallback(
    async (...args: any[]) => {
      setIsFetching(true);
      try {
        const res = await request(...args);
        setData(res);
        onSuccess?.(res, args);
      } catch (error) {
        setError(error as BackendError);
        onError?.(error as BackendError);
      } finally {
        setIsFetching(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [request]
  );

  return { request: makeRequest, data, error, isFetching };
}
