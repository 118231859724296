import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeDialog,
  selectDialogVisible,
  selectDialogTitle,
  selectDialogMuiSvgIcon,
  selectDialogMuiSvgIconStyle,
  selectDialogDescription,
  selectDialogSecondaryBtnName,
  selectDialogPrimaryBtnName,
  selectDialogOnSecondaryBtnClick,
  selectDialogOnPrimaryBtnClick,
  selectDialogHideCloseBtn,
  selectDialogDisablePrimaryBtnAutoClose
} from '@provider-reducers/dialogSlice';
import { InfoModal } from '../InfoModal/InfoModal';

export const Dialog = (): React.ReactElement => {
  const dispatch = useDispatch();

  const isModalVisible = useSelector(selectDialogVisible);
  const title = useSelector(selectDialogTitle);
  const MuiSvgIcon = useSelector(selectDialogMuiSvgIcon);
  const MuiSvgIconStyle = useSelector(selectDialogMuiSvgIconStyle);
  const description = useSelector(selectDialogDescription);
  const secondaryBtnName = useSelector(selectDialogSecondaryBtnName);
  const primaryBtnName = useSelector(selectDialogPrimaryBtnName);
  const onSecondaryBtnClick = useSelector(selectDialogOnSecondaryBtnClick);
  const onPrimaryBtnClick = useSelector(selectDialogOnPrimaryBtnClick);
  const hideCloseBtn = useSelector(selectDialogHideCloseBtn);
  const disablePrimaryBtnAutoClose = useSelector(selectDialogDisablePrimaryBtnAutoClose);

  const handleCloseModal = () => {
    dispatch(closeDialog());
  };

  return (
    <div>
      <InfoModal
        visible={isModalVisible}
        title={title}
        MuiSvgIcon={MuiSvgIcon}
        MuiSvgIconStyle={MuiSvgIconStyle}
        description={description}
        secondaryBtnName={secondaryBtnName}
        primaryBtnName={primaryBtnName}
        onSecondaryBtnClick={onSecondaryBtnClick}
        onPrimaryBtnClick={onPrimaryBtnClick}
        hideCloseBtn={hideCloseBtn}
        onClose={handleCloseModal}
        disablePrimaryBtnAutoClose={disablePrimaryBtnAutoClose}
      />
    </div>
  );
};
