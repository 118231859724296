import React from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline, responsiveFontSizes, ThemeOptions } from '@mui/material';
import { initialPalette } from '@provider-theme/palette';
import { componentsOverrides } from '@provider-theme/components';
import { typography } from '@provider-theme/typography';

interface AppThemeProviderProps {
  children?: React.ReactNode;
}

/**
 * Provider is design to progressivly replace src/theme.ts
 * Top level pages is wrapped in it until we're ready to wrap the whole App component
 */
const AppThemeProvider: React.FC<AppThemeProviderProps> = props => {
  const initialOptions: ThemeOptions = {
    spacing: 4,
    components: componentsOverrides,
    typography,
    palette: initialPalette
  };

  const theme = responsiveFontSizes(createTheme(initialOptions));

  return (
    <StyledEngineProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
