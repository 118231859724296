import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

type Props = {
  children: React.ReactElement;
};

export const RequireAuth = ({ children }: Props): React.ReactElement => {
  const authKey = localStorage.getItem('@x-api-key');
  const location = useLocation();

  return authKey ? children : <Navigate to='/signin' replace state={{ path: location.pathname }} />;
};
