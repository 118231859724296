import React from 'react';
import { Participant, ParticipantEmpty, Begin, CallEnded } from '../screens';
import { EmbedRouteNames } from './route-names';

export const EmbedRoutes = [
  {
    path: `${EmbedRouteNames.Participant}/:callId/:otpToken`,
    element: <Participant />
  },
  {
    path: EmbedRouteNames.Participant,
    element: <ParticipantEmpty />
  },
  {
    path: `${EmbedRouteNames.Begin}/:callUuid/:loginToken`,
    element: <Begin />
  },
  {
    path: EmbedRouteNames.CallEnded,
    element: <CallEnded />
  }
];
