import { Components, Theme } from '@mui/material';

export const componentsOverrides: Components<Theme> = {
  MuiButton: {
    defaultProps: {
      disableElevation: true
    },
    styleOverrides: {
      root: {
        height: '32px',
        padding: '0px 16px',
        color: '#FFFFFF',
        textTransform: 'none',
        letterSpacing: '0.5px',
        borderRadius: 50
      },
      text: {
        textTransform: 'none',
        fontSize: '12px',
        lineHeight: '16px',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        letterSpacing: '0.15px',
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        '& > .MuiButton-startIcon > *:first-of-type': {
          width: '24px',
          height: '24px',
          padding: '4px',
          borderRadius: '50%'
        },
        '&:hover': {
          backgroundColor: 'transparent'
        },
        '&:disabled': {
          color: '#5B6B7B',
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#313A42'
          }
        }
      },
      textPrimary: {
        '& > .MuiButton-startIcon > *:first-of-type': {
          backgroundColor: '#1773cc'
        },
        '&:hover': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#46AFF2'
          }
        },
        '&:active': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#05539a'
          }
        }
      },
      textSecondary: {
        '& > .MuiButton-startIcon > *:first-of-type': {
          backgroundColor: '#313a42'
        },
        '&:hover': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#5b6b7b'
          }
        },
        '&:active': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#222a30'
          }
        }
      },
      textSuccess: {
        '& > .MuiButton-startIcon > *:first-of-type': {
          backgroundColor: '#02857a'
        },
        '&:hover': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#00ccbb'
          }
        },
        '&:active': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#004b45'
          }
        }
      },
      textError: {
        backgroundColor: 'transparent',
        '& > .MuiButton-startIcon > *:first-of-type': {
          backgroundColor: '#e92528'
        },
        '&:hover': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#FF787A'
          }
        },
        '&:active': {
          '& > .MuiButton-startIcon > *:first-of-type': {
            backgroundColor: '#b60002'
          }
        }
      },
      textSizeLarge: {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
      },
      containedPrimary: ({ theme: { palette } }) => ({
        padding: '0px 18px',
        backgroundColor: palette.button.primary.default,
        '&:hover': {
          backgroundColor: palette.button.primary.hover
        },
        '& .Mui-focusVisible': {
          backgroundColor: palette.button.primary.focus
        },
        '&:active': {
          backgroundColor: palette.button.primary.active
        },
        '&:disabled': {
          backgroundColor: palette.button.primary.disabled
        }
      }),
      containedSecondary: ({ theme: { palette } }) => ({
        padding: '0px 18px',
        backgroundColor: palette.button.secondary.default,
        '&:hover': {
          backgroundColor: palette.button.secondary.hover
        },
        '& .Mui-focusVisible': {
          backgroundColor: palette.button.secondary.focus
        },
        '&:active': {
          backgroundColor: palette.button.secondary.active
        },
        '&:disabled': {
          backgroundColor: palette.button.secondary.disabled
        }
      }),
      containedWarning: {
        backgroundColor: '#FFC554',
        color: '#141414'
        // '&:hover': {
        //   backgroundColor: '#CB4D4E'
        // },
        // '&:active': {
        //   backgroundColor: '#BD5E5F'
        // }
      },
      containedError: {
        backgroundColor: '#D93B3D',
        '&:hover': {
          backgroundColor: '#CB4D4E'
        },
        '&:active': {
          backgroundColor: '#BD5E5F'
        }
      },
      containedSizeLarge: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: 600,
        padding: '8px 16px',
        boxSizing: 'content-box'
      },
      outlinedPrimary: ({ theme: { palette } }) => ({
        color: palette.primary.light,
        borderColor: palette.primary.light,
        borderWidth: 2,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        '&:hover': {
          borderColor: palette.primary.light,
          borderWidth: 2,
          backgroundColor: 'rgba(70, 175, 242, 0.2)'
        },
        '& .Mui-focusVisible': {
          // backgroundColor: palette.button.primary.focus
        },
        '&:active': {
          //backgroundColor: palette.button.primary.active
        },
        '&:disabled': {
          //backgroundColor: palette.button.primary.disabled
        }
      }),
      startIcon: {
        '& > *:firts-of-type': {
          fontSize: '16px !important'
        }
      },
      endIcon: {
        marginRight: 0,
        '& > *:last-of-type': {
          fontSize: '16px !important'
        }
      }
    }
  },
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        '&.Mui-disabled': {
          color: '#BEC3CC'
        }
      }
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderStyle: 'solid',
        borderColor: '#000000'
      }
    }
  },
  MuiIconButton: {
    styleOverrides: {
      sizeSmall: {
        fontSize: '12px'
      },
      sizeMedium: {
        fontSize: '16px'
      },
      sizeLarge: {
        fontSize: '24px'
      }
    }
  },
  MuiTablePagination: {
    styleOverrides: {
      actions: {
        '& .Mui-disabled': {
          color: '#5B6B7B'
        },
        '& button:not(.Mui-disabled)': {
          color: '#FFFFFF'
        }
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        paddingBottom: '8px',
        textTransform: 'none',
        '&.Mui-selected': {
          color: '#46AFF2'
        },
        '&:not(.Mui-selected)': {
          color: '#FFFFFF',
          borderBottom: '2px solid #313A42'
        }
      }
    }
  },
  MuiTabs: {
    styleOverrides: {
      indicator: ({ theme: { palette } }) => ({
        backgroundColor: palette.primary.light
      })
    }
  },
  MuiTableRow: {
    defaultProps: {
      hover: true
    },
    styleOverrides: {
      root: {
        backgroundColor: '#232A30',
        '&.MuiTableRow-hover:hover': {
          backgroundColor: '#223343'
        }
      }
    }
  },
  MuiTableCell: {
    styleOverrides: {
      head: {
        backgroundColor: '#1A1F24',
        fontSize: 12,
        fontWeight: 'bold'
      },
      root: {
        fontSize: 14,
        borderColor: '#1A1F24'
      }
    }
  },
  MuiTextField: {
    styleOverrides: {
      root: ({ theme: { palette } }) => ({
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        '&:focus-within': {
          '& .MuiFilledInput-root:after': {
            borderBottomColor: palette.primary.light
          },
          '& .MuiInputLabel-filled': {
            color: palette.primary.light
          }
        },
        '& label': {
          top: '-2px',
          fontWeight: 700,
          color: '#BEC3CC'
        },
        '& input': {
          borderRadius: '4px 4px 0px 0px',
          paddingTop: '20px',
          paddingRight: '12px',
          paddingBottom: '12px',
          paddingLeft: '12px'
        }
      })
    }
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#f6f6f6',
        '&.Mui-disabled': {
          color: '#BEC3CC'
        }
      },
      colorPrimary: {
        '&.Mui-checked': {
          // Controls checked color for the thumb
          color: '#1774CC'
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 1,
        backgroundColor: '#BEC3CC',
        '.Mui-checked.Mui-checked + &': {
          // Controls checked color for the track
          opacity: 1,
          backgroundColor: '#46AFF2'
        }
      }
    }
  }
};
