import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import { makeThemedStyles, useZoomIndicator } from '@provider-hooks';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { OverflowTip } from '@provider-components';

interface ParticipantRowLabelProps {
  name: string;
  labels: string;
}

type StyleProps = {
  isZoomed: boolean;
};

const ParticipantRowText: React.FC<ParticipantRowLabelProps> = props => {
  const { currentZoomValue = 0 } = useZoomIndicator();
  const isZoomed = currentZoomValue >= 175;
  const { styles } = useStyles({ isZoomed });

  const labels = props.labels && (
    <Typography variant={'subtitle1'} sx={{ color: '#bec3cc' }}>
      ({props.labels})
    </Typography>
  );

  return (
    <ListItemText secondary={isZoomed && labels} sx={styles.nameAndLabelsContainer} role={'listitem'}>
      <Stack sx={styles.stack} role={'group'}>
        <OverflowTip title={props.name}>
          <Typography variant={'subtitle1'} sx={styles.name}>
            {props.name}
          </Typography>
        </OverflowTip>
        {!isZoomed && labels}
      </Stack>
    </ListItemText>
  );
};

const useStyles = makeThemedStyles<StyleProps>()((_, { isZoomed }) => {
  return {
    stack: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'start',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      gap: 1
    },
    nameAndLabelsContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      m: 0,
      ml: 4,
      maxWidth: isZoomed ? '190px' : '290px'
    },
    name: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };
});

export default ParticipantRowText;
