import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './app/App';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css';

// add sentry
Sentry.init({
  environment: `${process.env.REACT_APP_SENTRY_ENV}`,
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  // increased to 10 because of Redux, see: https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
  normalizeDepth: 10,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  // capture 10% of traces
  tracesSampleRate: 0.1,
  beforeSend(event) {
    if ((event?.request?.headers?.['User-Agent'] || '').search('e2e test') !== -1) {
      return null;
    }

    return event;
  }
});

const appHeight = () => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
window.addEventListener('orientationchange', appHeight);
appHeight();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
