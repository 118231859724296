import React, { useCallback, useMemo, useState } from 'react';
import { Box, List, ListItem, Stack, Typography } from '@mui/material';
import LogItem from './LogItem';
import { useSelector } from 'react-redux';
import { selectEsitterLogsByFilter } from '@provider-selectors/log';
import { getSelectedDeviceId } from '@provider-selectors/esitter';
import LogFilter from './LogFilter';
import { ELogEvent } from '@provider-types/enums';
import { ScrollableContainer } from '../ScrollableContainer/ScrollableContainer';
import { Button } from '@provider-components';
import { Trans } from 'react-i18next';

type Props = {
  logsPerPage?: number;
};

export const DEFAULT_LOGS_PER_PAGE = 20;

export default function LogsTab({ logsPerPage = DEFAULT_LOGS_PER_PAGE }: Props): React.ReactElement {
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const [typeFilter, setTypeFilter] = useState<ELogEvent[]>([]);
  const [page, setPage] = useState(1);

  const filters = useMemo(
    () => ({
      typeFilter,
      deviceId: selectedDeviceId,
      page,
      itemsPerPage: logsPerPage
    }),
    [typeFilter, selectedDeviceId, page, logsPerPage]
  );

  const {
    logs,
    pageInfo: { hasMore }
  } = useSelector(state => selectEsitterLogsByFilter(state, filters));

  const onFilterChange = useCallback(typeFilters => {
    setTypeFilter(typeFilters);
    setPage(1);
  }, []);

  const logList = logs.length ? (
    <List sx={{ pt: 0, pr: 4 }} data-testid='logList'>
      {logs.map(log => (
        <ListItem
          key={log.id}
          sx={{
            px: 0,
            pt: 0,
            pb: 8,
            '&:last-of-type': {
              pb: 4
            },
            '&:not(:last-of-type)::after': {
              content: "''",
              width: '2px',
              height: 'calc(100% - 48px)',
              position: 'absolute',
              transform: 'translate(12px, 35px)',
              background: '#5B6B7B',
              top: 0
            }
          }}
        >
          <LogItem {...log} />
        </ListItem>
      ))}
    </List>
  ) : (
    <Typography variant='body1' sx={{ color: ({ palette }) => palette.text.secondary }}>
      <Trans i18nKey={'esitter.logEvent.noEvents'} />
    </Typography>
  );

  return (
    <Stack
      sx={{
        px: 4,
        py: 4,
        position: 'relative',
        overflowY: 'auto'
      }}
    >
      <>
        <Box sx={{ width: '100%', mb: 6 }}>
          <LogFilter onChange={onFilterChange} />
        </Box>

        <ScrollableContainer
          hideScrollBar={false}
          containerSx={{
            mr: -4
          }}
          mainContainer={
            <>
              {logList}
              {hasMore && (
                <Box sx={{ justifyContent: 'center', display: 'flex', py: 3 }}>
                  <Button variant='secondary' onClick={() => setPage(prev => prev + 1)}>
                    <Trans i18nKey={'global.showMore'} />
                  </Button>
                </Box>
              )}
            </>
          }
          bottomContainer={null}
        />
      </>
    </Stack>
  );
}
