import { EPrerecordedMessageGender } from '@provider-types/enums';
import { IDeviceRoomInfo } from '@provider-types/provider';
import { EsitterInitialState } from '@provider-types/reducer';

type AvailbleGendersForLangGetter =
  | {
      esitter: EsitterInitialState;
      deviceId: string;
    }
  | {
      esitter: EsitterInitialState;
      lang: string;
    };

export const getAvailbleGendersForLang = ({
  esitter,
  ...rest
}: AvailbleGendersForLangGetter): EPrerecordedMessageGender[] | null => {
  const selectedLang =
    'lang' in rest
      ? rest.lang
      : esitter.roomsArray.find((room: IDeviceRoomInfo) => room.deviceId === rest.deviceId)?.messageLang;

  if (!selectedLang || !esitter.messages) return null;

  const availableGenders = esitter.messages
    .filter(message => message.lang === selectedLang)
    .reduce((acc, item) => acc.add(item.gender), new Set<EPrerecordedMessageGender>());

  return [...availableGenders];
};
