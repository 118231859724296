import { PolygonPoint, RoomBoundaryPoint } from '@provider-types/reducer';
import _ from 'lodash';
import { ColorRange } from '@provider-types/esitter.types';

const DEFAULT_COLOR = 'BEC3CC';

export const getDefaultCoordinates = (): RoomBoundaryPoint[] => [
  {
    id: 'p1',
    x: 0.265,
    y: 0.032
  },
  {
    id: 'p2',
    x: 0.695,
    y: 0.032
  },
  {
    id: 'p3',
    x: 0.695,
    y: 0.435
  },
  {
    id: 'p4',
    x: 0.695,
    y: 0.855
  },
  {
    id: 'p5',
    x: 0.265,
    y: 0.855
  },
  {
    id: 'p6',
    x: 0.265,
    y: 0.435
  }
];

export const getCoordinatesInRelative = (
  coords: { x: number; y: number },
  resolution: { width: number; height: number }
): { rx: number; ry: number } => {
  const rx = coords.x / resolution.width;
  const ry = coords.y / resolution.height;
  return { rx, ry };
};

export const getRelativeinCoordinates = (
  relatives: { rx: number; ry: number },
  resolution: { width: number; height: number }
): { x: number; y: number } => {
  const x = resolution.width * relatives.rx;
  const y = resolution.height * relatives.ry;
  return { x, y };
};

export const getRelativeSVGPoints = (
  relatives: { rx: number; ry: number },
  resolution: { width: number; height: number }
): string => {
  const x = resolution.width * relatives.rx;
  const y = resolution.height * relatives.ry;
  return `${x},${y}`;
};

export const transformCoordinates = (
  point: number[],
  resolutionFrom: { width: number; height: number },
  resolutionTo: { width: number; height: number }
): number[] => {
  const { rx, ry } = getCoordinatesInRelative({ x: point[0], y: point[1] }, resolutionFrom);
  const { x, y } = getRelativeinCoordinates({ rx, ry }, resolutionTo);
  return [x, y];
};

export const transformPointsToPolygon = (points: RoomBoundaryPoint[]): PolygonPoint[] => {
  return points.map(point => ({
    x: point.x,
    y: point.y
  }));
};

export const debouncedFunction = _.debounce(
  (value: any, func: any) => {
    func(value);
  },
  250,
  {
    leading: true,
    maxWait: 250
  }
);

// Throttle function to make it more responsive
const throttledFunction = _.throttle((value: any, func: any) => {
  func(value);
}, 500);

// Debounce the throttled function to cut the inertial motion short
export const debouncedAndThrottledFunction = _.debounce(throttledFunction, 20, {
  leading: true,
  trailing: false
});

export const getRangeMappedColor = (ranges: ColorRange, currentLevel: number): string => {
  const keys = [...ranges.keys()].sort((a, b) => Number(b) - Number(a));

  for (const key of keys) {
    if (currentLevel > key) return ranges.get(key)?.color || DEFAULT_COLOR;
  }

  return ranges.get(+keys[keys.length - 1])?.color || DEFAULT_COLOR;
};
