import React, { useMemo, forwardRef, Ref } from 'react';
import { Stack, SvgIcon, SxProps, Theme, Typography } from '@mui/material';
import { makeThemedStyles } from '@provider-hooks';
import { mergeSx } from '@provider-utils/styles';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface IconWithTextProps {
  icon: typeof SvgIcon;
  text: string | React.ReactNode;
  sx?: SxProps<Theme>;
  iconSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  dataTestId?: string;
}

export const IconWithText = forwardRef(
  (props: IconWithTextProps, ref: Ref<HTMLParagraphElement>): React.ReactElement | null => {
    const { styles } = useStyles();
    const { icon: Icon, text, sx, iconSx, labelSx, variant = 'body1', dataTestId, ...otherProps } = props;
    const mergedIconSx = useMemo(() => mergeSx(styles.icon, iconSx), [iconSx, styles.icon]);
    const mergedLabelSx = useMemo(() => mergeSx(styles.label, labelSx), [labelSx, styles.label]);

    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        component={'div'}
        sx={sx}
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      >
        <Icon sx={mergedIconSx} />
        <Typography variant={variant} sx={mergedLabelSx} ref={ref} {...otherProps}>
          {text}
        </Typography>
      </Stack>
    );
  }
);

const useStyles = makeThemedStyles()(() => ({
  icon: {
    fontSize: '12px'
  },
  label: {
    pl: 2,
    color: '#FFFFFF',
    textWrap: 'nowrap'
  }
}));
