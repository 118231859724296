import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Input } from '@provider-components';
import { AddDevice, DeviceInfo, IDeviceRoomInfo, PatientInfo } from '@provider-types/provider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getCallableDevicesList, addDevice } from '@provider-api/esitter';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useSelector, useDispatch } from 'react-redux';
import { EPrerecordedMessageGender, EPrerecordedMessageLang, ETagColor, EToastStatus } from '@provider-types/enums';
import spinner from '@provider-features/embed/styles/Spinner.module.css';
import { isBackendError } from '@provider-errors/BackendError';
import { addRoom, setSelectedDevice, setSidePanelContent } from '@provider-reducers/esitterSlice';
import MUIButton from '@mui/material/Button';
import { SidePanelContent } from '../SidePanel';
import { setToastData, setToastOpen, setNewPatientAddedShowedFlag } from '@provider-reducers/toastSlice';
import { getCallId, selectRoomsArray } from '@provider-selectors/esitter';
import { makeThemedStyles } from '@provider-hooks';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { DeviceAvailability } from './AddPatientList/AddPatientListItem';
import List from '@mui/material/List';
import { AddPatientDeviceListItem } from './AddPatientList/AddPatientDeviceLIstItem';
import { AddPatientHospitalListItem } from './AddPatientList/AddPatientHospitalLIstItem';
import { selectIsDebugModeUIOn } from '@provider-reducers/uiState';
import { useEsitterCallWSMessages } from '../../../hooks';

const styles = {
  selectText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
    paddingLeft: '8px',
    borderLeft: '1px solid #FFFFFF'
  },
  selectPrevText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: '#969696',
    paddingLeft: '8px',
    borderLeft: '1px solid #969696'
  },
  search: {
    padding: '24px 8px 8px 8px'
  },
  bottomBtnText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1px',
    color: '#ffffff'
  },
  emptyListMessageContainer: {
    backgroundColor: '#313A42',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    padding: '20px 16px 16px 16px',
    margin: '8px',
    color: '#ffffff',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400 as const,
    lineHeight: '16px'
  },
  emptyListMessageText: {
    color: '#ffffff',
    justifyContent: 'center',
    textAlign: 'center' as const,
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '16px'
  }
};

interface SearchComponentComponentProps {
  searchValue: string;
  OnSetSearchValue: (value: string) => void;
}
const SearchComponent = (props: SearchComponentComponentProps) => {
  const { searchValue, OnSetSearchValue } = props;
  return (
    <div style={styles.search}>
      <Input
        value={searchValue}
        placeholder='Search'
        hasClearIcon={true}
        EndIcon={<SearchIcon sx={{ color: '#BEC3CC' }} />}
        setValue={OnSetSearchValue}
      />
    </div>
  );
};

interface HospitalComponentProps {
  spinnerVisible: boolean;
  hospitals: string[];
  onHospitalClick: (hospital: string) => void;
  children: React.ReactNode;
}
const HospitalComponent = React.memo((props: HospitalComponentProps) => {
  const { spinnerVisible, hospitals, onHospitalClick, children } = props;
  const { styles: sx } = useStyles();

  const handleHospitalClick = (hospital: string) => {
    onHospitalClick(hospital);
  };

  return (
    <>
      <Box sx={sx.header}>
        <Box sx={sx.selectTop}>
          <Typography sx={sx.selectText}>Select Hospital</Typography>
        </Box>
        {children}
      </Box>

      <Box sx={sx.wrapper}>
        {spinnerVisible && (
          <div style={styles.emptyListMessageContainer}>
            <div className={spinner.spinner2} style={{ marginBottom: '16px' }}></div>
            Loading...
          </div>
        )}
        <List sx={{ width: '100%' }}>
          {hospitals.map(hospital => (
            <AddPatientHospitalListItem
              key={hospital}
              label={hospital}
              hospital={hospital}
              onHospitalClick={handleHospitalClick}
            />
          ))}
        </List>
        {hospitals.length === 0 && !spinnerVisible && (
          <div style={styles.emptyListMessageContainer}>
            <SearchOffIcon sx={{ color: '#ffffff', width: '24px', height: '24px', marginBottom: '20px' }} />
            <p style={styles.emptyListMessageText}>
              No matching Hospitals found. Please try searching a different name.
            </p>
          </div>
        )}
      </Box>
    </>
  );
});

interface DeviceComponentProps {
  selectedHospital: string;
  spinnerVisible: boolean;
  devices: AddDevice[];
  onDeviceClick: (deviceUuid: string) => void;
  children: React.ReactNode;
}
const DeviceComponent = React.memo((props: DeviceComponentProps) => {
  const { selectedHospital, spinnerVisible, onDeviceClick, devices, children } = props;
  const { styles: sx } = useStyles();
  return (
    <>
      <Box sx={sx.header}>
        <Box sx={sx.selectTop}>
          <Typography sx={sx.selectPrevText}>{selectedHospital}</Typography>
        </Box>
        <Box sx={sx.select}>
          <Typography sx={sx.selectText}>Select Device</Typography>
        </Box>
        {children}
      </Box>
      <Box sx={sx.wrapper}>
        {spinnerVisible && (
          <div style={styles.emptyListMessageContainer}>
            <div className={spinner.spinner2} style={{ marginBottom: '16px' }}></div>
            Loading...
          </div>
        )}
        <List sx={{ width: '100%' }} data-testid='roomList'>
          {devices.map(device => (
            <AddPatientDeviceListItem
              key={device.uuid}
              label={device.name}
              deviceUuid={device.uuid}
              onDeviceClick={onDeviceClick}
              availability={device.availability as DeviceAvailability}
            />
          ))}
        </List>
        {devices.length === 0 && (
          <div style={styles.emptyListMessageContainer}>
            <SearchOffIcon sx={{ color: '#ffffff', width: '24px', height: '24px', marginBottom: '20px' }} />
            <p style={styles.emptyListMessageText}>No matching devices found. Please try searching a different name.</p>
          </div>
        )}
      </Box>
    </>
  );
});

interface FinalComponentProps {
  selectedHospital: string;
  selectedDeviceName: string;
}
const FinalComponent = React.memo((props: FinalComponentProps) => {
  const { selectedHospital, selectedDeviceName } = props;
  const { styles: sx } = useStyles();
  return (
    <>
      <Box sx={sx.selectTop}>
        <Typography sx={sx.selectPrevText}>{selectedHospital}</Typography>
      </Box>
      <Box sx={sx.select}>
        <Typography sx={sx.selectPrevText}>{selectedDeviceName}</Typography>
        <Typography sx={sx.selectText}>Confirm</Typography>
      </Box>
      <Stack sx={{ padding: '8px 25px 8px 8px', marginTop: '16px' }} spacing={4}>
        <Typography>Confirm the information you selected is correct before adding the Patient:</Typography>
        <Box>
          <Typography variant='caption' color={'text.secondary'}>
            Hospital
          </Typography>
          <Typography sx={sx.selectTextName}>{selectedHospital}</Typography>
        </Box>
        <Box>
          <Typography variant='caption' color={'text.secondary'}>
            Device
          </Typography>
          <Typography sx={sx.selectTextName}>{selectedDeviceName}</Typography>
        </Box>
      </Stack>
    </>
  );
});

export const AddPatientContent = React.memo((): React.ReactElement => {
  const [stage, setStage] = useState<'hospital' | 'device' | 'final'>('hospital');
  const [listHospitals, setListHospitals] = useState<string[]>([]);
  const [listDevices, setListDevices] = useState<AddDevice[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedHospital, setSelectedHospital] = useState<string>('');
  // *not* the same as the selected device for the entire call - local 'selected' device for adding only!
  // const [selectedDevice, setSelectedDevice] = useState<AddDevice>();
  const [selectedDeviceUuid, setSelectedDeviceUuid] = useState<string>('');

  const [spinnerVisible, setSpinnerVisible] = useState<boolean>(true);

  const dispatch = useDispatch();

  const selectedDevice = useMemo(
    () => listDevices.find(d => d.uuid === selectedDeviceUuid),
    [listDevices, selectedDeviceUuid]
  );
  const isDebugModeOn = useSelector(selectIsDebugModeUIOn);
  const roomsArray = useSelector(selectRoomsArray);
  const callId = useSelector(getCallId);
  const { sendDeviceDebugState } = useEsitterCallWSMessages(callId);

  const { styles: sx } = useStyles();

  const getCallableDevices = useCallback(async () => {
    if (callId) {
      try {
        const callableDevices = await getCallableDevicesList({ callId });

        if (!isBackendError(callableDevices)) {
          const hospitalsResult = callableDevices
            .map(device => device.facility)
            .filter((value: string, index: number, list: string[]) => list.indexOf(value) === index && !!value);
          const devicesResult = callableDevices;

          hospitalsResult.sort((a: string, b: string) =>
            a.toString().localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          );

          devicesResult.sort((a: AddDevice, b: AddDevice) =>
            a.name.toString().localeCompare(b.name.toString(), undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          );

          // Testing long device name
          // const fakeDevice = devicesResult[0];
          // fakeDevice.name = 'This is a really long name to display.';
          // fakeDevice.facility = 'Z Fake Device Facility';
          // console.log('HERE: ', fakeDevice);
          // devicesResult.push(fakeDevice);

          setListHospitals(hospitalsResult);
          setListDevices(devicesResult);
          setSpinnerVisible(false);
        }
      } catch (error: unknown) {
        const e = error as Error;
        console.log(`Error: ${e.message}`);
      }
    }
  }, [callId]);

  const onHospitalClick = useCallback(
    hospital => {
      setSearchValue('');
      setSelectedHospital(hospital);
      setStage('device');
    },
    [setSelectedHospital, setStage]
  );

  const onDeviceClick = useCallback(
    deviceUuid => {
      setSearchValue('');
      setSelectedDeviceUuid(deviceUuid);
      setStage('final');
    },
    [setStage]
  );

  const onCancelClick = useCallback(() => {
    dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
  }, [dispatch]);

  const onBackClick = useCallback(() => {
    if (stage === 'device') {
      setSearchValue('');
      setSelectedDevice(undefined);
      setStage('hospital');
    } else if (stage === 'hospital') {
      onCancelClick();
    }
  }, [stage, onCancelClick]);

  const onAddPatientClick = useCallback(async () => {
    if (selectedDeviceUuid && callId) {
      const device = await addDevice(callId, selectedDeviceUuid);

      if (!isBackendError(device)) {
        dispatch(
          setToastData({
            status: EToastStatus.SUCCESS,
            message: 'A new Patient has been added.',
            autoHideDuration: 4000
          })
        );
        dispatch(setToastOpen(true));
        dispatch(setNewPatientAddedShowedFlag(true));

        sendDeviceDebugState(isDebugModeOn);

        const newRoom: IDeviceRoomInfo = {
          deviceId: device.deviceId,
          deviceName: device.deviceName,
          mode: device.mode,
          room: device.room,
          token: device.token,
          seen: true,
          facility: selectedDevice?.facility || '',
          tagColor: ETagColor.NONE,
          note: '',
          audioLevel: 0,
          deviceCapabilities: device.deviceCapabilities,
          deviceInfo: {
            panLimit: { min: true, max: true },
            tiltLimit: { min: true, max: true },
            zoomLimit: { min: true, max: true },
            patientVolumeLimit: { min: true, max: true },
            micGainLimit: { min: true, max: true },
            zoom: 0,
            patientVolume: 0,
            micGain: 0
          } as DeviceInfo,
          patientInfo: {
            name: '',
            reasonForMonitoring: '',
            language: '',
            location: '',
            primaryContact: ''
          } as PatientInfo,
          hasReceivedWSMessage: false,
          debugModeOn: false,
          debugShapes: [],
          messageGender: EPrerecordedMessageGender.MALE,
          messageLang: EPrerecordedMessageLang.ENGLISH
        };
        dispatch(addRoom(newRoom));
        dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
      } else {
        //in case of error, show toast and go back to my patients
        dispatch(
          setToastData({
            status: EToastStatus.ERROR,
            message: 'Sorry, this device is no longer available. Please try again.',
            autoHideDuration: 5000
          })
        );
        dispatch(setToastOpen(true));
        dispatch(setSidePanelContent(SidePanelContent.MY_PATIENTS));
      }

      setTimeout(() => {
        dispatch(setNewPatientAddedShowedFlag(false));
      }, 4000);
    }
  }, [selectedDeviceUuid, callId, dispatch, sendDeviceDebugState, isDebugModeOn, selectedDevice?.facility]);

  useEffect(() => {
    getCallableDevices();
    const intervalID = setInterval(getCallableDevices, 5000);
    return () => {
      clearInterval(intervalID);
    };
  }, [getCallableDevices]);

  const devices = listDevices
    // Remove all devices that are already added to the session
    .filter((ad: AddDevice) => roomsArray.find((dr: IDeviceRoomInfo) => dr.deviceId === ad.uuid) === undefined)
    // Filter to only show devices for the currently selected hospital
    .filter((ad: AddDevice) => {
      if (selectedHospital.length === 0) {
        return true; // Show all devices if no hospital is selected
      } else {
        if (selectedHospital.includes(ad.facility)) {
          return true;
        }
      }
      return false;
    })
    // Filter devices by search term
    .filter(_ => _.name.toLowerCase().includes(searchValue.toLowerCase()));

  // Hospital list filtered by search term
  const hospitals = listHospitals.filter(_ => _.toLowerCase().includes(searchValue.toLowerCase()));
  const addIcon = useMemo(() => <AddCircleIcon />, []);

  const searchComponent = useMemo(
    () => <SearchComponent searchValue={searchValue} OnSetSearchValue={setSearchValue} />,
    [searchValue]
  );

  return (
    <>
      <Box sx={sx.mainContainer}>
        {stage === 'hospital' && (
          <HospitalComponent hospitals={hospitals} onHospitalClick={onHospitalClick} spinnerVisible={spinnerVisible}>
            {searchComponent}
          </HospitalComponent>
        )}
        {stage === 'device' && (
          <DeviceComponent
            devices={devices}
            onDeviceClick={onDeviceClick}
            selectedHospital={selectedHospital}
            spinnerVisible={spinnerVisible}
          >
            {searchComponent}
          </DeviceComponent>
        )}
        {stage === 'final' && (
          <FinalComponent selectedDeviceName={selectedDevice?.name || ''} selectedHospital={selectedHospital} />
        )}
      </Box>
      <Box sx={sx.bottomContainer}>
        {stage === 'final' ? (
          <MUIButton
            data-testid='addPatientFinal'
            fullWidth
            variant='contained'
            endIcon={addIcon}
            color='primary'
            onClick={onAddPatientClick}
          >
            Add Patient
          </MUIButton>
        ) : stage !== 'hospital' ? (
          <MUIButton fullWidth variant='contained' color='primary' onClick={onBackClick}>
            Back
          </MUIButton>
        ) : null}
        <MUIButton fullWidth variant='outlined' color='primary' onClick={onCancelClick}>
          Cancel
        </MUIButton>
      </Box>
    </>
  );
});

const useStyles = makeThemedStyles()(() => ({
  header: {
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    overflowX: 'hidden',
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto'
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '8px',
    padding: '8px 8px 11px 8px',
    borderTop: '1px solid #000000'
  },
  select: {
    padding: '0px 25px 0px 8px'
  },
  selectTop: {
    padding: '8px 25px 0px 8px'
  },
  selectText: {
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: '#FFFFFF',
    paddingLeft: '8px',
    borderLeft: '1px solid #FFFFFF'
  },
  selectPrevText: {
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: '#969696',
    paddingLeft: '8px',
    borderLeft: '1px solid #969696',
    wordWrap: 'break-word'
  },
  selectTextName: {
    wordWrap: 'break-word'
  }
}));
