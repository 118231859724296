import React, { useCallback } from 'react';
import { TextButton } from '@provider-components';
import RemoveIcon from '@mui/icons-material/Remove';

import { useDispatch } from 'react-redux';
import { setPendingRemoval } from '@provider-reducers/esitterSlice';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

export default function RemovePatientButton(): React.ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onRemoveButtonClick = useCallback(() => {
    dispatch(setPendingRemoval(true));
  }, [dispatch]);

  return (
    <Box
      sx={{
        ml: '-12px!important'
      }}
    >
      <TextButton
        labelSx={{
          py: 0,
          px: 4
        }}
        iconSx={{
          backgroundColor: ({ palette }) => palette.primary.main
        }}
        color={'primary'}
        fullWidth
        title={t('esitter.components.SidePanel.RemovePatientPanel.button')}
        icon={RemoveIcon}
        onClick={onRemoveButtonClick}
      />
    </Box>
  );
}
