import React, { useEffect, useRef } from 'react';
import { SaveButton } from '../content/SaveButton/SaveButton';
import { transformPointsToPolygon } from '../../../functions';
import {
  setBoundaryFirstTime,
  setSaveDisabled,
  setSavingWithTimeout,
  setBoundaryPoints,
  setIsSaved
} from '@provider-reducers/smartRailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEsitterWSMessages } from '../../../hooks';
import { getCallId, selectSelectedDeviceSelectedCameraUuid, getSelectedDeviceId } from '@provider-selectors/esitter';
import { getSelectedCameraSmartRailsPolygon, selectIsSaved, selectIsSaving } from '@provider-selectors/smartRails';
import { SmartRailsSensitivityLevel } from '../content/SmartRailsContent/SmartRailsContent';
import { setSidePanelContent } from '@provider-reducers/esitterSlice';
import { SidePanelContent } from '../../../components/SidePanel/SidePanel';

interface SaveSmartRailsButtonProps {
  enable: boolean;
  disabled: boolean;
  sensitivityLevel: SmartRailsSensitivityLevel;
}

export const SaveSmartRailsButton = (props: SaveSmartRailsButtonProps): JSX.Element => {
  const { enable, disabled, sensitivityLevel } = props;
  const dispatch = useDispatch();
  const callId = useSelector(getCallId);
  const selectedDeviceId = useSelector(getSelectedDeviceId);
  const selectedCameraUuid = useSelector(selectSelectedDeviceSelectedCameraUuid);
  const { sendUpdateSmartRailsEnabledWSMessage } = useEsitterWSMessages(selectedDeviceId, callId);
  const points = useSelector(getSelectedCameraSmartRailsPolygon);

  const loading = useSelector(selectIsSaving);
  const loaded = useSelector(selectIsSaved);

  const checkLoaded = useRef(false);

  const onSaveClick = () => {
    // If the smart rails are disabled, save just saves the polygon locally, but sends nothing to the Hub
    // If the smart rails are enabled, save saves the polygon locally and sends updated coorindates to the Hub
    if (enable) {
      const polygon = transformPointsToPolygon(points);
      sendUpdateSmartRailsEnabledWSMessage(selectedCameraUuid, enable, sensitivityLevel, polygon);
      dispatch(setSavingWithTimeout(selectedCameraUuid));
    } else {
      dispatch(setBoundaryPoints({ cameraUuid: selectedCameraUuid, points }));
      dispatch(setIsSaved({ cameraUuid: selectedCameraUuid, cancelTimer: true }));
      checkLoaded.current = true;
    }

    dispatch(setSaveDisabled({ cameraUuid: selectedCameraUuid, disabled: true }));
  };

  useEffect(() => {
    if (loaded) {
      dispatch(setBoundaryFirstTime({ cameraUuid: selectedCameraUuid }));
    }
  }, [dispatch, loaded, selectedCameraUuid]);

  useEffect(() => {
    if (loading) {
      checkLoaded.current = true;
    }
  }, [loading]);

  useEffect(() => {
    if (checkLoaded.current && loaded) {
      checkLoaded.current = false;
      dispatch(setSidePanelContent(SidePanelContent.PATIENT_DETAILS));
    }
  }, [checkLoaded, loaded, dispatch]);

  return <SaveButton disabled={disabled} loading={loading} loaded={loaded} onClick={onSaveClick} />;
};
