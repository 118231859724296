/* eslint-disable */
// @ts-nocheck
import { style } from '@mui/system';
import React, { useEffect, useRef } from 'react';

export interface Props {
  style?: React.CSSProperties;
  track: any;
  onLoad?: () => void;
  onAttach?: () => void;
}

const VideoTrack: React.FC<Props> = ({ style, track, onLoad, onAttach }) => {
  const ref = useRef();

  useEffect(() => {
    if (track && ref.current) {
      const el = ref.current;

      const handlePause = () => {
        el.play();
      };

      const handleLoaded = () => {
        el.muted = true;
        const response = el.play();

        response?.catch(() => {
          setTimeout(() => {
            el.play();
          }, 1000);
        });

        onLoad && onLoad();
      };

      el.addEventListener('pause', handlePause);
      el.addEventListener('loadedmetadata', handleLoaded);
      track.attach(el);
      onAttach && onAttach();

      return () => {
        track.detach(el);
        el.removeEventListener('loadedmetadata', handleLoaded);
        el.removeEventListener('pause', handlePause);
      };
    }
  }, [ref, track]);

  return <video style={{ ...style }} ref={ref} data-testid='video' />;
};

export default VideoTrack;
