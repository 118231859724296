import React, { ComponentProps, ElementRef, useRef, useState } from 'react';
import { Box, Popper, SxProps, Theme } from '@mui/material';
import { useClickAway } from 'ahooks';
import { IconButton } from '@provider-components';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { mergeSx } from '@provider-utils/styles';
import { IconButtonVariant } from '@provider-types/components';
import { TextButton } from '../TextButton/TextButton';
import { makeThemedStyles } from '@provider-hooks';

type IconWithOptionsProps = {
  children: React.ReactNode;
  label?: string;
  icon?: ComponentProps<typeof IconButton>['icon'];
  menuContainerSx?: SxProps<Theme>;
  popperSx?: SxProps<Theme>;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
} & IconButtonVariant;

export const IconWithOptions = (props: IconWithOptionsProps): React.ReactElement => {
  const {
    children,
    popperSx,
    menuContainerSx,
    icon = MoreVertSharpIcon,
    label = 'More',
    variant = 'icon',
    onClick
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<ElementRef<'div'>>(null);
  const popperRef = useRef<ElementRef<'div'>>(null);

  useClickAway(
    (e: any) => {
      // fix for the case when tooltip is a parent of tooltip
      if (!e.target.closest("[role='tooltip']")) {
        setIsOpen(false);
      }
    },
    [buttonRef, popperRef],
    ['click', 'touchstart', 'contextmenu', 'mouseup']
  );

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    setIsOpen(prevIsOpen => {
      return !prevIsOpen;
    });
    onClick && onClick(e);
  };

  const button =
    variant === 'textIcon' ? (
      <TextButton
        fullWidth
        icon={icon}
        title={label}
        onClick={onClickHandler}
        color={'inherit'}
        labelSx={{ px: 2, fontSize: '12px' }}
        iconSx={{ fontSize: '16px' }}
      />
    ) : (
      <IconButton aria-label={label} onClick={onClickHandler} color={'primary'} isToggled={isOpen} icon={icon} />
    );

  const { styles } = useStyles();

  return (
    <Box ref={buttonRef}>
      {button}
      <Popper
        aria-label={label}
        open={isOpen}
        anchorEl={buttonRef.current}
        placement='bottom-start'
        ref={popperRef}
        sx={popperSx}
      >
        <Box sx={mergeSx(styles.menuContainer, menuContainerSx)}>{children}</Box>
      </Popper>
    </Box>
  );
};

const useStyles = makeThemedStyles()(() => ({
  menuContainer: {
    backgroundColor: '#232A30',
    opacity: 0.85,
    boxShadow: '2px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    my: 2,
    py: 3,
    px: 4,
    borderRadius: '4px',
    maxWidth: '225px',
    minWidth: '110px'
  }
}));
