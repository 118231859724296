import {
  setShowSmartRailsCameraChangedWarningOff,
  setShowSmartRailsCameraChangedWarningOn
} from '@provider-reducers/uiState';
import {
  useEsitterWSMessages,
  wsCommandMode
} from '@provider-features/esitter/hooks/useEsitterWSMessages/useEsitterWSMessages';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoundariesArray } from '@provider-selectors/smartRails';
import { getCallId, selectDeviceSelectedCameraUuid } from '@provider-selectors/esitter';

interface UseCameraChange {
  onCameraChange: (cameraUuid: string) => void;
  selectedCameraUuid: string;
}

export const useCameraChange = (deviceId: string): UseCameraChange => {
  const callId = useSelector(getCallId);
  const dispatch = useDispatch();
  const selectedCameraUuid = useSelector(state => selectDeviceSelectedCameraUuid(state, deviceId));

  const { sendDeviceFeccStateWSMessage } = useEsitterWSMessages(deviceId, callId);
  const boundariesArray = useSelector(selectBoundariesArray);

  const onCameraChange = (cameraUuid: string) => {
    const prevCameraHadSmartRails = boundariesArray.some(el => el.id === selectedCameraUuid && el.enabled);
    const newCameraHasSmartRails = boundariesArray.some(el => el.id === cameraUuid && el.enabled);
    if (prevCameraHadSmartRails && !newCameraHasSmartRails) {
      dispatch(setShowSmartRailsCameraChangedWarningOn({ cameraUuid: selectedCameraUuid }));
    } else {
      dispatch(setShowSmartRailsCameraChangedWarningOff());
    }
    sendDeviceFeccStateWSMessage(wsCommandMode.ABSOLUTE, {
      selectedCameraUuid: cameraUuid
    });
  };

  return { onCameraChange, selectedCameraUuid };
};
