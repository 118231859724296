import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import React from 'react';
import { IconButtonProps } from '@mui/material/IconButton/IconButton';
import { makeThemedStyles } from '@provider-hooks';

interface ParticipantMenuButtonProps extends IconButtonProps {
  isMenuOpened: boolean;
}

export const ParticipantMenuButton = (props: ParticipantMenuButtonProps): React.ReactElement => {
  const { onClick, isMenuOpened, ...otherProps } = props;
  const { styles } = useStyles({ isMenuOpened });

  const handleMenuButtonHover = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <IconButton
      edge='end'
      aria-label='more'
      component='span'
      onMouseEnter={handleMenuButtonHover}
      onClick={onClick}
      sx={styles.menuIcon}
      {...otherProps}
    >
      <MoreHorizIcon />
    </IconButton>
  );
};

type StyleProps = {
  isMenuOpened: boolean;
};

const useStyles = makeThemedStyles<StyleProps>()((_, { isMenuOpened }) => ({
  menuIcon: {
    color: isMenuOpened ? '#1774CC' : '#FFFFFF',
    backgroundColor: isMenuOpened ? '#141414' : 'transparent',
    p: 0,
    mr: '2px',
    '&:hover': {
      backgroundColor: '#141414',
      cursor: 'pointer'
    },
    width: '24px',
    height: '24px'
  }
}));
