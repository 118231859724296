import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Input } from '@provider-components';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import styles from '../../styles/Participant.module.css';
import { ButtonControl } from '@provider-components';
import { useNavigate, useParams } from 'react-router';
import { addParticipant, getInvitationInfo } from '@provider-api/embed';
import { VRCallRouteNames } from '@provider-features/vrcall/router';
import { IInvitedParticipantInfo } from '@provider-types/embed';
import { useDispatch, useSelector } from 'react-redux';
import { EButtonControlSize, EButtonControlColor } from '@provider-types/enums';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { InfoModal } from '@provider-components';
import CallEndIcon from '@mui/icons-material/CallEnd';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { EmbedRouteNames } from '../../router';
import { useWebsocketHelper } from 'src/wsHelper';
import { useMediaHardwareChecker, useMicrophone } from '@provider-hooks';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { isBackendError, isOk } from '@provider-errors/BackendError';
import { RootState } from '@provider-reducers/root';
import {
  setIsCamActive,
  setCmsVersion,
  setAppMediaAccessModalVisible,
  setAppMediaAccessModalData
} from '@provider-reducers/appSlice';
import { setRedirectDisabled } from '@provider-reducers/esitterSlice';
import AppThemeProvider from '@provider-app/AppThemeProvider';

const Participant: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [name, setName] = useState('');
  const [callInfoRequestComplete, setCallInfoRequestComplete] = useState(false);
  const [providerName, setProviderName] = useState('');
  const [hospitalName, setHospitalName] = useState('');

  const [hospitalLogoURL, setHospitalLogoURL] = useState('');
  const [hasAttemptedJoin, setHasAttemptedJoin] = useState(false);
  const [isJoinButtonClicked, setIsJoinButtonClicked] = useState(false);

  const [isParticipantMicActive, setIsParticipantMicActive] = useState(false);
  const [isParticipantCamActive, setIsParticipantCamActive] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [initialDeviceRooms, setInitialDeviceRooms] = useState<any[]>([]);
  const [myUserUuid, setMyUserUuid] = useState('');

  const { browserType } = useSelector((state: RootState) => state.settings);
  const { modalVisible, modalData } = useSelector((state: RootState) => state.vrcall);

  const { mediaAccessModalVisible, mediaAccessModalData } = useSelector((state: RootState) => state.app);
  const [requiredMediaIsReady, setRequiredMediaIsReady] = useState(false);

  const { initiateWsConnection, wsReady } = useWebsocketHelper();
  const { initialCamCheckComplete, isCameraOk, isMicrophoneOk } = useSelector(
    (state: RootState) => state.mediapermissions
  );
  const { triggerCameraAndMicCheck } = useMediaHardwareChecker();
  const { callId, otpToken } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { setIsMicActive } = useMicrophone();

  const onModalClose = useCallback(() => {
    dispatch({
      type: 'SET_VR_MODAL_VISIBLE',
      payload: false
    });
  }, [dispatch]);

  const onMediaAccessModalClose = useCallback(() => {
    dispatch(setAppMediaAccessModalVisible(false));
  }, [dispatch]);

  const onSetMediaAccessModalVisible = useCallback(() => {
    const modalTitle = 'Allow cam and mic use';
    const modalDescription =
      'We need access to your camera and microphone so that other participants can see and hear you.' +
      'Click the camera blocked icon in your browsers address bar to adjust the settings and continue.';
    const primaryBtnName = 'Retry';
    const secondaryBtnName = 'Cancel your call';

    dispatch(setRedirectDisabled(false));
    dispatch(
      setAppMediaAccessModalData({
        title: modalTitle,
        MuiSvgIcon: ErrorOutlineIcon,
        MuiSvgIconStyle: { color: '#D93B3D' },
        description: modalDescription,
        primaryBtnName,
        secondaryBtnName,
        onPrimaryBtnClick: () => {
          triggerCameraAndMicCheck();
        },
        onSecondaryBtnClick: () => {
          navigate(EmbedRouteNames.CallEnded);
        },
        hideCloseBtn: false,
        disablePrimaryBtnAutoClose: true,

        onClose: () => {
          dispatch(setRedirectDisabled(true));
          onMediaAccessModalClose();
        }
      })
    );
    dispatch(setAppMediaAccessModalVisible(true));
  }, [dispatch, navigate, triggerCameraAndMicCheck, onMediaAccessModalClose]);

  const getVideoStream = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({
        video: { facingMode: 'user', width: 558.22, height: 314 }
      });
      if (mediaStream) {
        if (videoRef.current !== null) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();
        } else {
          setIsParticipantCamActive(false);
        }
      }
    } catch (error) {
      onSetMediaAccessModalVisible();
      setIsParticipantCamActive(false);
    }
  }, [onSetMediaAccessModalVisible]);

  const getAudioStream = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({ audio: true });
      if (!mediaStream) {
        setIsParticipantMicActive(false);
      }
    } catch (error) {
      onSetMediaAccessModalVisible();
      setIsParticipantMicActive(false);
    }
  }, [onSetMediaAccessModalVisible]);

  const getVideoAndAudioStream = useCallback(async () => {
    try {
      const mediaStream = await navigator.mediaDevices?.getUserMedia({
        video: { facingMode: 'user', width: 558.22, height: 314 },
        audio: true
      });
      if (mediaStream) {
        if (videoRef.current !== null) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play();
        } else {
          setIsParticipantCamActive(false);
        }
      } else {
        setIsParticipantMicActive(false);
      }
    } catch (error) {
      onSetMediaAccessModalVisible();
      setIsParticipantCamActive(false);
      setIsParticipantMicActive(false);
    }
  }, [onSetMediaAccessModalVisible]);

  const toggleCamera = () => {
    if (isParticipantCamActive) {
      if (videoRef.current !== null) {
        const stream = videoRef.current.srcObject as MediaStream;
        if (stream) {
          stream.getTracks().forEach(track => {
            track.stop();
          });
        }
      }
    } else {
      getVideoStream();
    }
    setIsParticipantCamActive(state => !state);
  };

  const toggleMic = () => {
    if (!isParticipantMicActive) {
      getAudioStream();
    }
    setIsParticipantMicActive(state => !state);
  };

  let validationError: string | JSX.Element | null = null;
  let joinButtonEnabled = false;
  let showValidationError = false;

  //only allows some symbols .,-"' to be entered
  const inputRegex = new RegExp('[^\\p{L}\\p{N}\\p{Pi}\\p{Pf}\\p{Pd}\\p{Pc}\\p{Ps}\\p{Pe},"\'. -]+', 'u');

  if (!name || name.trim().length < 3) {
    if (hasAttemptedJoin) {
      showValidationError = true;
    }
    validationError = 'Please enter a name with at least 3 characters.';
    joinButtonEnabled = false;
  } else if (name.trim().length > 32 || inputRegex.test(name)) {
    showValidationError = true;
    validationError = (
      <div>
        Please enter a name using less than 32 characters. Only use the following special characters:{' '}
        <span style={{ color: '#ffffff' }}>{'. - , \' "'}</span>
      </div>
    );
    joinButtonEnabled = false;
  } else if (requiredMediaIsReady) {
    joinButtonEnabled = true;
  }

  // Display media access modal on initial call load, over other data
  useEffect(() => {
    if (!initialCamCheckComplete) {
      triggerCameraAndMicCheck();
      return;
    }
    const displayMediaErrorModal = !isMicrophoneOk || !isCameraOk;

    if (displayMediaErrorModal) {
      onSetMediaAccessModalVisible();
    } else {
      onMediaAccessModalClose();
      setRequiredMediaIsReady(true);
    }
  }, [
    initialCamCheckComplete,
    isMicrophoneOk,
    isCameraOk,
    triggerCameraAndMicCheck,
    onMediaAccessModalClose,
    setRequiredMediaIsReady,
    onSetMediaAccessModalVisible
  ]);

  // Will be executed prior after the participant tries to join, before navigating to the call
  useEffect(() => {
    if (!callId || !loggedIn || !wsReady) {
      return;
    }

    navigate(VRCallRouteNames.VRCall, {
      state: {
        call: { callUuid: callId, deviceRooms: initialDeviceRooms },
        userUuid: myUserUuid,
        isParticipant: true
      }
    });
  }, [dispatch, callId, initialDeviceRooms, myUserUuid, navigate, loggedIn, wsReady]);

  const onJoinClick = useCallback(async () => {
    if (isJoinButtonClicked) {
      return;
    }
    setIsJoinButtonClicked(true);
    if (!requiredMediaIsReady) {
      onSetMediaAccessModalVisible();
      return;
    }
    if (!validationError && requiredMediaIsReady) {
      if (callId && otpToken) {
        const response = await addParticipant(callId, {
          // trim leading/trailing whitespace before submitting name
          // Only allow remaining name to contain letters, numbers, spaces and allowed symbols
          displayName: name.trim(),
          otpToken: otpToken,
          browserType: browserType
        });

        if (!isBackendError(response)) {
          const { body: participant, headers } = response;
          dispatch({
            type: 'SET_ALL_PARTICIPANT_STATUS',
            payload: participant.participantStatus
          });

          // Dispatch to the app-wide self mic/camera state that will be used once we join the call
          setIsMicActive(isParticipantMicActive);
          dispatch(setIsCamActive(isParticipantCamActive));
          dispatch(setCmsVersion(headers.get('x-cms-version') as string));

          setInitialDeviceRooms(participant.deviceRooms);
          setMyUserUuid(participant.userUuid);

          // Will go to a separate useEffect after login to set up websocket before
          // navigating to the call
          setLoggedIn(true);
          initiateWsConnection();
        } else if (isBackendError(response) && response.status === 400) {
          //VR_P_16.1n (Desktop)
          const body = response.payload;

          if (body[0]?.keyword === 'inUse') {
            console.log('Link already in use');
            //Set Popup info and display
            dispatch({
              type: 'SET_VR_MODAL_DATA',
              payload: {
                title: 'Error',
                MuiSvgIcon: ErrorOutlineOutlinedIcon,
                MuiSvgIconStyle: { color: '#D93B3D' },
                description:
                  'The invitation link you used to join this call is already in use.' +
                  'Please ask to be sent a new invite or leave the call from another device before joining.',
                primaryBtnName: 'Okay',
                onPrimaryBtnClick: onModalClose
              }
            });
            dispatch({
              type: 'SET_VR_MODAL_VISIBLE',
              payload: true
            });
          } else if (body[0]?.keyword === 'notExist' && body[0]?.dataPath === 'callId') {
            //VR_P_16.1m (Desktop)
            console.log('Call has already ended.');
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: 'This meeting you are trying to join <br /> has been ended by the host.'
            });
            navigate(EmbedRouteNames.CallEnded);
          } else {
            // This will only happen in case of malicious attack (e.g. changing authtoken)
            console.log('Error on joining.');
            //Set end Screen message and Icon
            dispatch({
              type: 'SET_END_CALL_ICON',
              payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
            });
            dispatch({
              type: 'SET_END_CALL_MESSAGE',
              payload: 'There was an error with your invitation link.'
            });
            navigate(EmbedRouteNames.CallEnded);
          }
        }
      }
    }
    setHasAttemptedJoin(true);
    setTimeout(() => {
      setIsJoinButtonClicked(false);
    }, 500);
  }, [
    browserType,
    isParticipantCamActive,
    isParticipantMicActive,
    requiredMediaIsReady,
    name,
    callId,
    otpToken,
    initiateWsConnection,
    navigate,
    dispatch,
    validationError,
    setInitialDeviceRooms,
    setHasAttemptedJoin,
    setLoggedIn,
    onModalClose,
    onSetMediaAccessModalVisible,
    setIsMicActive,
    isJoinButtonClicked,
    setIsJoinButtonClicked
  ]);

  const getCallInfo = useCallback(async () => {
    const invitationInfo = await getInvitationInfo(callId, otpToken);

    if (isOk<IInvitedParticipantInfo>(invitationInfo)) {
      setProviderName(invitationInfo.invitingUser.metadata.displayName);
      setHospitalName(invitationInfo.hospital.name);
      setHospitalLogoURL(invitationInfo.hospital.logo);
      setCallInfoRequestComplete(true);
    } else if (isBackendError(invitationInfo) && invitationInfo.status === 400) {
      const body = invitationInfo.payload;

      if (body[0]?.keyword === 'notExist' && body[0]?.dataPath === 'callId') {
        //VR_P_16.1m (Desktop)
        console.log('Call has already ended.');
        //Set end Screen message and Icon
        dispatch({
          type: 'SET_END_CALL_ICON',
          payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
        });
        dispatch({
          type: 'SET_END_CALL_MESSAGE',
          payload: 'This meeting you are trying to join <br /> has been ended by the host.'
        });
      } else {
        // This will only happen in case of malicious attack (e.g. changing authtoken)
        console.log('Error on joining.');
        //Set end Screen message and Icon
        dispatch({
          type: 'SET_END_CALL_ICON',
          payload: <CallEndIcon sx={{ color: '#BEC3CC' }} />
        });
        dispatch({
          type: 'SET_END_CALL_MESSAGE',
          payload: 'There was an error with your invitation link.'
        });
      }

      setTimeout(() => {
        navigate(EmbedRouteNames.CallEnded);
      }, 1000);
    }
  }, [callId, otpToken, setProviderName, setHospitalName, setHospitalLogoURL, dispatch, navigate]);

  // UseEffect to ensure we release the camera track before navigating to another screen
  useEffect(() => {
    const ref = videoRef.current;
    return () => {
      const stream = ref?.srcObject as MediaStream;
      if (stream) {
        stream.getTracks().forEach(t => t.stop());
      }
    };
    // need to update ref every time the camera state changes
  }, [isParticipantCamActive]);

  useEffect(() => {
    getVideoAndAudioStream();
    getCallInfo();
  }, [getCallInfo, getVideoAndAudioStream]);

  return (
    <AppThemeProvider>
      <div className={styles.container}>
        {mediaAccessModalVisible ? (
          <InfoModal
            visible={mediaAccessModalVisible}
            title={mediaAccessModalData.title}
            MuiSvgIcon={mediaAccessModalData.MuiSvgIcon}
            MuiSvgIconStyle={mediaAccessModalData.MuiSvgIconStyle}
            description={mediaAccessModalData.description}
            secondaryBtnName={mediaAccessModalData.secondaryBtnName}
            primaryBtnName={mediaAccessModalData.primaryBtnName}
            onSecondaryBtnClick={mediaAccessModalData.onSecondaryBtnClick}
            onPrimaryBtnClick={mediaAccessModalData.onPrimaryBtnClick}
            hideCloseBtn={mediaAccessModalData.hideCloseBtn}
            onClose={mediaAccessModalData.onClose || onMediaAccessModalClose}
            disablePrimaryBtnAutoClose={mediaAccessModalData.disablePrimaryBtnAutoClose}
          />
        ) : (
          <InfoModal
            visible={modalVisible}
            title={modalData.title}
            MuiSvgIcon={modalData.MuiSvgIcon}
            MuiSvgIconStyle={modalData.MuiSvgIconStyle}
            description={modalData.description}
            secondaryBtnName={modalData.secondaryBtnName}
            primaryBtnName={modalData.primaryBtnName}
            onSecondaryBtnClick={modalData.onSecondaryBtnClick}
            onPrimaryBtnClick={modalData.onPrimaryBtnClick}
            onClose={modalData.onClose || onModalClose}
          />
        )}
        <div className={styles.box}>
          {hospitalLogoURL && hospitalLogoURL.length > 0 ? (
            <div className={styles.hospitalLogoWrapper}>
              <img
                className={styles.hospitalLogo}
                alt='hospital logo'
                src={hospitalLogoURL}
                onError={e => {
                  e.currentTarget.style.display = 'none';
                }}
              />
            </div>
          ) : (
            <div className={styles.hospitalLogo} style={{ display: 'none' }} />
          )}
          <div className={styles.content}>
            <div className={styles.camera}>
              {isParticipantCamActive ? (
                <video ref={videoRef} autoPlay playsInline muted data-testid='video' />
              ) : (
                <p className={styles.cameraText}>Your Video is Off</p>
              )}
            </div>
            <div className={styles.nameAndControls}>
              {providerName && hospitalName ? (
                <p className={styles.title}>
                  {providerName} from {hospitalName} is inviting you to join a call
                </p>
              ) : (
                callInfoRequestComplete && <p className={styles.title}>You have been invited to join a call</p>
              )}

              <div className={styles.inputName}>
                <Input
                  value={name}
                  placeholder={'Enter your name'}
                  chainedBtnId='join_btn'
                  StartIcon={<PersonOutlineIcon style={{ color: '#BEC3CC' }} />}
                  hasClearIcon={true}
                  setValue={setName}
                  isValid={!showValidationError}
                  errorMessage={validationError}
                  label='Name'
                />
              </div>
              <div className={styles.controls}>
                <div className={styles.controlContainer}>
                  <ButtonControl
                    size={EButtonControlSize.LARGE}
                    backgroundColor={isParticipantMicActive ? '#1774CC' : '#1A1F24'}
                    color={isParticipantMicActive ? EButtonControlColor.BLUE : EButtonControlColor.GRAY}
                    onClick={toggleMic}
                  >
                    {isParticipantMicActive ? (
                      <MicIcon sx={{ color: '#ffffff' }} />
                    ) : (
                      <MicOffOutlinedIcon sx={{ color: '#ffffff' }} />
                    )}
                  </ButtonControl>
                  <p className={styles.controlText}>{isParticipantMicActive ? 'Mute' : 'Unmute'} My Audio</p>
                </div>
                <div className={styles.controlContainer}>
                  <ButtonControl
                    size={EButtonControlSize.LARGE}
                    backgroundColor={isParticipantCamActive ? '#1774CC' : '#1A1F24'}
                    color={isParticipantCamActive ? EButtonControlColor.BLUE : EButtonControlColor.GRAY}
                    onClick={toggleCamera}
                  >
                    {isParticipantCamActive ? (
                      <VideocamIcon sx={{ color: '#ffffff' }} />
                    ) : (
                      <VideocamOffOutlinedIcon sx={{ color: '#ffffff' }} />
                    )}
                  </ButtonControl>
                  <p className={styles.controlText}>Turn {isParticipantCamActive ? 'Off' : 'On'} My Video</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className={`${styles.joinMeeting} ${joinButtonEnabled ? styles.joinMeetingFilled : ''}`}
          onClick={onJoinClick}
          id='join_btn'
        >
          Join Meeting
        </button>
      </div>
    </AppThemeProvider>
  );
};

export default Participant;
