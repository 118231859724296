import { Action, VRCallInitialState } from '@provider-types/reducer';

export const initialState: VRCallInitialState = {
  mode: 'observation_av',
  initialRequestedDeviceMode: null,
  breakingTheGlass: false,
  changingMode: false,
  callId: '',
  myUuid: '',
  panLimit: { min: true, max: true },
  tiltLimit: { min: true, max: true },
  zoomLimit: { min: true, max: true },
  patientVolumeLimit: { min: true, max: true },
  micGainLimit: { min: true, max: true },
  zoom: 0,
  patientVolume: 0,
  videoRotationAngle: 0,
  privacyMode: false,
  camera: '',
  cameraList: [],
  selectedCameraUuid: '',
  hasReceivedWSMessage: false,
  screenshareParticipantId: null,
  micGain: 0,
  modalVisible: false,
  modalData: {
    title: '',
    MuiSvgIcon: undefined,
    MuiSvgIconStyle: undefined,
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    onSecondaryBtnClick: undefined,
    onPrimaryBtnClick: undefined,
    onClose: undefined
  },
  reconnectModal: false,
  invitationsData: {
    maxInvitations: 0,
    invitations: []
  },
  testCall: false,
  amIStartingInteraction: false,
  endCallScreenIcon: null,
  endCallScreenParticipant: false,
  endCallScreenMessage: 'Call Ended.',
  isKeyDownHandlerActive: true,
  deviceHasVideoTrack: false,
  endCallPatientRejection: false,
  initiateGetInvites: false,
  blurModeStatus: null,
  isMyMicActive: false
};

// TODO: refactor to RTL approach, remove "any"
const vrCallReducer = (state = initialState, action: Action<any>): VRCallInitialState => {
  switch (action.type) {
    case 'SET_VR_MODE': {
      return { ...state, mode: action.payload };
    }
    case 'END_CALL_PATIENT_REJECTION': {
      return { ...state, endCallPatientRejection: action.payload };
    }
    case 'SET_VR_INITIAL_REQUESTED_DEVICE_MODE': {
      return { ...state, initialRequestedDeviceMode: action.payload };
    }
    case 'SET_VR_MODE_CHANGING': {
      return { ...state, changingMode: action.payload };
    }
    case 'SET_BREAKING_THE_GLASS': {
      return { ...state, breakingTheGlass: action.payload };
    }
    case 'SET_VR_MODE_AND_MODE_CHANGING': {
      return { ...state, mode: action.payload.mode, changingMode: action.payload.ringing };
    }
    case 'SET_VR_LIMIT': {
      return { ...state, [action.payload.limit]: { ...action.payload.limitData } };
    }
    case 'SET_VR_FECC_VALUES': {
      const newState = { ...state };
      for (const key of Object.keys(action.payload)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newState[key] = action.payload[key];
      }
      return newState;
    }
    case 'SET_HAS_RECEIVED_WS_MESSAGE': {
      return { ...state, hasReceivedWSMessage: action.payload };
    }
    case 'SET_VR_CALLID': {
      return { ...state, callId: action.payload };
    }
    case 'SET_MY_UUID': {
      return { ...state, myUuid: action.payload };
    }
    case 'SET_VR_MODAL_VISIBLE': {
      if (action.payload) {
        return { ...state, modalVisible: action.payload };
      }
      return { ...state, modalVisible: action.payload, modalData: { ...initialState.modalData } };
    }
    case 'SET_VR_MODAL_DATA': {
      return {
        ...state,
        modalData: {
          title: action.payload?.title || '',
          MuiSvgIcon: action.payload?.MuiSvgIcon,
          MuiSvgIconStyle: action.payload?.MuiSvgIconStyle,
          description: action.payload?.description || '',
          primaryBtnName: action.payload?.primaryBtnName || '',
          secondaryBtnName: action.payload?.secondaryBtnName || '',
          onSecondaryBtnClick: action.payload?.onSecondaryBtnClick,
          onPrimaryBtnClick: action.payload?.onPrimaryBtnClick,
          onClose: action.payload?.onClose,
          hideCloseBtn: action.payload?.hideCloseBtn
        }
      };
    }
    case 'SET_VR_MODAL_RECONNECT': {
      return { ...state, reconnectModal: action.payload };
    }
    case 'SET_VR_INVITATIONS_DATA': {
      return {
        ...state,
        invitationsData: { ...action.payload }
      };
    }
    case 'SET_VR_TEST_CALL': {
      return {
        ...state,
        testCall: action.payload
      };
    }
    case 'SET_STATE_BACK_TO_DEFAULT': {
      return {
        ...initialState
      };
    }
    case 'SET_SCREENSHARE_PARTICIPANT_ID': {
      return {
        ...state,
        screenshareParticipantId: action.payload
      };
    }
    case 'SET_AM_I_STARTING_INTERACTION': {
      return {
        ...state,
        amIStartingInteraction: action.payload
      };
    }
    case 'SET_END_CALL_ICON': {
      return {
        ...state,
        endCallScreenIcon: action.payload
      };
    }
    case 'SET_END_CALL_PARTICIPANT': {
      return {
        ...state,
        endCallScreenParticipant: action.payload
      };
    }
    case 'SET_END_CALL_MESSAGE': {
      return {
        ...state,
        endCallScreenMessage: action.payload
      };
    }
    case 'SET_IS_KEY_DOWN_HANDLER_ACTIVE': {
      return {
        ...state,
        isKeyDownHandlerActive: action.payload
      };
    }
    case 'SET_DEVICE_HAS_VIDEO_TRACK': {
      return {
        ...state,
        deviceHasVideoTrack: action.payload
      };
    }
    case 'SET_INITIATE_GET_INVITES': {
      return {
        ...state,
        initiateGetInvites: action.payload
      };
    }
    case 'SET_BLUR_MODE_STATUS': {
      return {
        ...state,
        blurModeStatus: action.payload
      };
    }

    case 'SET_IS_MY_MIC_ACTIVE': {
      return {
        ...state,
        isMyMicActive: action.payload
      };
    }

    default:
      return { ...state };
  }
};

export default vrCallReducer;
