import { useCallback } from 'react';
import {
  setPendingRemoval,
  setRefererRemovalTab,
  setSelectedDevice,
  setSidePanelContent
} from '@provider-reducers/esitterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsPendingRemoval, selectRefererRemovalTab } from '@provider-selectors/esitter';
import { SidePanelContent } from '../../components/SidePanel/SidePanel';

interface UseRemoveDevice {
  onCancelRemoveClick: () => void;
  isPendingRemoval: boolean;
}

export const useRemoveDevice = (): UseRemoveDevice => {
  const dispatch = useDispatch();
  const isPendingRemoval = useSelector(selectIsPendingRemoval);
  const refererRemovalTab = useSelector(selectRefererRemovalTab);

  const onCancelRemoveClick = useCallback(() => {
    dispatch(setPendingRemoval(false));

    if (refererRemovalTab === SidePanelContent.MY_PATIENTS) {
      dispatch(setSelectedDevice(undefined));
      dispatch(setSidePanelContent(refererRemovalTab));
    }

    dispatch(setRefererRemovalTab(undefined));
  }, [dispatch, refererRemovalTab]);

  return {
    onCancelRemoveClick,
    isPendingRemoval
  };
};
