import { IconWithText } from '../IconWithText/IconWithText';
import React from 'react';
import { SvgIcon } from '@mui/material';
import { makeThemedStyles } from '@provider-hooks';
import { OverflowTip } from '@provider-components';

interface PatientInfoLabelProps {
  icon: typeof SvgIcon;
  text: string | React.ReactNode;
}
export const PatientInfoLabel = (props: PatientInfoLabelProps): React.ReactElement => {
  const { icon, text, ...rest } = props;
  const { styles } = useStyles();
  return (
    <OverflowTip title={text}>
      <IconWithText
        text={text}
        icon={icon}
        sx={styles.container}
        iconSx={styles.icon}
        labelSx={styles.label}
        {...rest}
      />
    </OverflowTip>
  );
};

const useStyles = makeThemedStyles()(() => ({
  container: {
    alignItems: 'start'
  },
  icon: {
    fontSize: '16px',
    mt: '2px',
    color: '#BEC3CC'
  },
  label: {
    textWrap: 'wrap',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all'
  }
}));
