import React, { CSSProperties } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from './Header.module.css';

export interface HeaderProps {
  style?: CSSProperties;
  title: string;
  backgroundColor?: string;
  borderColor?: string;
  hasArrowBack?: boolean;
  onCloseClick: () => void;
  onArrowBackClick: () => void;
}

export const Header = React.memo((props: HeaderProps): React.ReactElement => {
  const {
    style,
    title,
    backgroundColor = '#141414',
    borderColor = '#5B6B7B',
    hasArrowBack = false,
    onCloseClick,
    onArrowBackClick
  } = props;

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: backgroundColor, borderBottom: `1px solid ${borderColor}`, ...style }}
      data-testid='headerBlock'
    >
      <div className={styles.titleContainer}>
        {hasArrowBack && (
          <IconButton onClick={onArrowBackClick} sx={{ paddingRight: '24px' }} data-testid='backBtn'>
            <ArrowBackIosIcon sx={{ color: '#ffffff' }} />
          </IconButton>
        )}
        <p role='heading' aria-level={2} className={styles.title} style={{ fontSize: style?.fontSize }}>
          {title}
        </p>
      </div>
      <IconButton
        onClick={onCloseClick}
        sx={{
          width: '32px',
          height: '32px',
          '&:hover': {
            backgroundColor: '#232A30'
          },
          '&:active': {
            backgroundColor: '#1774CC'
          }
        }}
        data-testid='closeSidePanelBtn'
      >
        <CloseIcon sx={{ color: '#ffffff' }} />
      </IconButton>
    </div>
  );
});
