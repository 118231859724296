import { Box, Grow } from '@mui/material';
import React from 'react';
import { LogEntry } from '@provider-components';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HearingIcon from '@mui/icons-material/Hearing';
import NotificationIcon from '@mui/icons-material/NotificationImportant';
import MessageIcon from '@mui/icons-material/Message';
import PermScanWifiOutlinedIcon from '@mui/icons-material/PermScanWifiOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
/** @TODO uncomment when NETWORK event is supported */
// import WifiIcon from '@mui/icons-material/Wifi';
// import WifiOffIcon from '@mui/icons-material/WifiOff';

import { ELogEvent, ELogEventPhase } from '@provider-types/enums';
import { StetoscopeIcon } from '@provider-components/Icons';
import { ELogEntry } from '@provider-types/esitter.types';
import { useTranslation } from 'react-i18next';

const iconMap = new Map([
  [ELogEvent.LISTEN_IN, { icon: HearingIcon, color: '#1774CC' }],
  [ELogEvent.ROOM_ALARM, { icon: NotificationIcon, color: '#E82628' }],
  [ELogEvent.INTERVENTION, { icon: LocalPhoneIcon, color: '#39D627' }],
  [
    ELogEvent.VR,
    {
      icon: StetoscopeIcon,
      color: 'transparent',
      outlined: true
    }
  ],
  [ELogEvent.MESSAGES, { icon: MessageIcon, color: '#1774CC' }],
  [ELogEvent.PRIVACY_MODE, { icon: VisibilityOffIcon, color: 'transparent', outlined: true }],
  [ELogEvent.BLUR_PRIVACY_MODE, { icon: VisibilityOffIcon, color: 'transparent', outlined: true }],
  /** @TODO uncomment when NETWORK event is supported */
  // [`${ELogEvent.NETWORK}_${ELogEventPhase.STARTED}`, { icon: WifiIcon, color: 'transparent', outlined: true }],
  // [`${ELogEvent.NETWORK}_${ELogEventPhase.ENDED}`, { icon: WifiOffIcon, color: 'transparent', outlined: true }],
  ['default', { icon: PermScanWifiOutlinedIcon, color: 'transparent', outlined: true }]
]);

type Props = Omit<ELogEntry, 'phase' | 'type'> &
  (
    | {
        type: ELogEvent.MESSAGES;
        phase?: ELogEventPhase.NONE;
      }
    | {
        type: Exclude<ELogEvent, ELogEvent.MESSAGES>;
        phase: Exclude<ELogEventPhase, ELogEventPhase.NONE>;
      }
  );

export default function LogItem({
  type,
  phase = ELogEventPhase.NONE,
  id,
  timestamp,
  description
}: Props): React.ReactElement {
  const { t } = useTranslation();

  /** @TODO uncomment when NETWORK event is supported */
  // const eventType = type === ELogEvent.NETWORK ? `${type}_${phase}` : type;
  const eventType = type;
  const iconData = iconMap.has(eventType) ? iconMap.get(eventType) : iconMap.get('default');
  const Icon = iconData?.icon;
  const IconComponent = Icon && (
    <Box
      sx={{
        borderRadius: 1000,
        background: iconData?.color || '#777777',
        border: iconData?.outlined ? '1px solid #fff' : '',
        p: 1,
        display: 'inline-flex',
        alignSelf: 'start',
        flexGrow: 0,
        position: 'relative'
      }}
    >
      <Icon
        sx={{
          fontSize: '16px'
        }}
      />
    </Box>
  );

  return (
    <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={350}>
      <LogEntry
        id={id}
        data-testid='logItem'
        title={t(`esitter.logEvent.${type}.${phase !== ELogEventPhase.NONE ? phase : 'name'}`)}
        timestamp={timestamp}
        icon={IconComponent}
        description={description}
      />
    </Grow>
  );
}
