import { useWebsocketHelper } from 'src/wsHelper';
import { useCallback, useMemo } from 'react';
import { pack as msgpack } from 'msgpackr';

interface UseCommonWSMessages {
  sendPcmAudioToTranscribe: (audio: ArrayBuffer) => void;
}

export const useCommonWSMessages = (callId: string): UseCommonWSMessages => {
  const { sendBinaryWsMessage } = useWebsocketHelper();

  const pcmAudioHeaderBlob = useMemo(() => {
    const header = {
      event: 'captionAudio',
      // Not currently configurable
      sampleRateHz: 16000,
      format: 'pcm',
      callUuid: callId
    };
    const packedHeader = msgpack(header);
    const packedHeaderLength = packedHeader.length;
    const headerBuffer = new ArrayBuffer(2 + packedHeaderLength);
    const headerView = new DataView(headerBuffer);
    // write msgpacked header length followed by msgpacked header
    headerView.setUint16(0, packedHeaderLength, false);
    for (let i = 0; i < packedHeaderLength; i++) {
      headerView.setUint8(i + 2, packedHeader[i]);
    }
    // Convert to blob to send (`Buffer` not natively supported in browser)
    return new Blob([headerBuffer]);
  }, [callId]);

  // Send 16-bit, little endian 16KHz PCM audio chunks to the CMS
  const sendPcmAudioToTranscribe = useCallback(
    (pcmAudio: ArrayBuffer): void => {
      // TODO: Could memoize header as long as callId doesn't change

      const pcmAudioBlob = new Blob([pcmAudio]);
      const bufToSend = new Blob([pcmAudioHeaderBlob, pcmAudioBlob]);

      return sendBinaryWsMessage(bufToSend);
    },
    [pcmAudioHeaderBlob, sendBinaryWsMessage]
  );

  return {
    sendPcmAudioToTranscribe
  };
};
