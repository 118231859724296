import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface SpinnerProps {
  size?: number;
  trackColor?: string;
  progressStrokeColor?: string;
}

export const Spinner = ({ size = 24, trackColor = '#606060', progressStrokeColor = '#5892FE' }: SpinnerProps): any => {
  return (
    <Box sx={{ position: 'relative', width: size, height: size }} data-testid={'spinner'} data-role={'spinner'}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: trackColor
        }}
        size={size}
        value={100}
      />
      <CircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          color: progressStrokeColor,
          position: 'absolute',
          left: 0
        }}
        size={size}
      />
    </Box>
  );
};
